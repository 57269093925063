// TODO: This is not a real "hook"
export const useBanProps = (
  props: Record<string, any> = {},
  bannedProps: Record<string, any> = [],
): Record<string, any> => {
  const safeProps: Record<string, any> = {};

  Object.keys(props).forEach((prop) => {
    if (!bannedProps.includes(prop)) safeProps[prop] = props[prop];
  });

  return safeProps;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react';
import { StyleSheet } from 'react-native';

import { ButtonIcon, InputField } from '@atoms';
import { Aurora, ScrollView, Text, View } from '@primitives';
import { DefaultTheme } from '@theming';

import type { ThemeDefinition } from '../../src/theming/Theme.definition';
import type { FC } from 'react';

const styles = StyleSheet.create({
  container: {
    paddingVertical: 24,
    display: 'flex',
    flexDirection: 'column',
    paddingHorizontal: 8,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    paddingVertical: 4,
  },
  evenRow: {
    backgroundColor: '#e7e7e7',
  },
  tokenValue: {
    flex: 1,
    textAlign: 'right',
    textWrap: 'nowrap',
  },
  clearButton: {
    paddingVertical: 17,
  },
});
export const TokensChapter: FC = () => {
  const [filter, setFilter] = useState<Partial<ThemeDefinition>>(DefaultTheme);
  const [filterValue, setFilterValue] = useState<string>('');
  const handleChange = (value: string) => {
    setFilter(
      Object.fromEntries(
        Object.entries(DefaultTheme).filter(([k, v]) => k.indexOf(value) !== -1 || String(v).indexOf(value) !== -1),
      ),
    );
    setFilterValue(value);
  };

  return (
    <Aurora theme={DefaultTheme}>
      <View style={styles.container}>
        <View style={{ flexDirection: 'row', paddingBottom: 24 }}>
          <InputField
            label={'Search key or value'}
            name={'search'}
            onChange={handleChange}
            style={{ flex: 1 }}
            value={filterValue}
          />
          <ButtonIcon
            name={'trash'}
            onPress={() => {
              setFilter(DefaultTheme);
              setFilterValue('');
            }}
            style={styles.clearButton}
          />
        </View>

        <ScrollView scrollIndicator>
          {Object.keys(filter).map((k, i) => (
            <View key={k} style={[styles.row, i % 2 === 0 && styles.evenRow]}>
              <Text detail level={1} selectable style={{ flex: 1 }}>
                {k}
              </Text>

              <Text detail level={1} selectable style={styles.tokenValue}>
                {typeof DefaultTheme[k as keyof ThemeDefinition] !== 'object'
                  ? String(DefaultTheme[k as keyof ThemeDefinition])
                  : JSON.stringify(DefaultTheme[k as keyof ThemeDefinition], undefined, 0)}
              </Text>
            </View>
          ))}
        </ScrollView>
      </View>
    </Aurora>
  );
};

TokensChapter.displayName = '🪝 Theming/Tokens';

import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { Rating } from '@package';

export const RatingChapter = () => {
  const { event, number, object, text } = useStoryteller();
  const [selected, setSelected] = React.useState(3);

  const handleChange = (value: number) => {
    event('onChange', [{ value: value.toString() }]);
    setSelected(value);
  };

  return (
    <Rating
      max={number('max', 5)}
      maxCaption={text('maxCaption', 'agree')}
      min={number('min', 1)}
      minCaption={text('minCaption', 'disagree')}
      style={object('style', {})}
      title={text('title', 'Rating')}
      value={selected}
      onChange={handleChange}
    />
  );
};

RatingChapter.displayName = '⚛️ Atoms/Rating';

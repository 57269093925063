import React, { useLayoutEffect, useState } from 'react';

import { ALIGN, COLOR, POINTER, POSITION, SIZE, styles } from '@hooks';
import { Icon, Image, Motion, View } from '@primitives';

import { style } from './Thumbnail.style';
import { Skeleton } from '../Skeleton';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { IconName } from '../../primitives/Icon/Icon';
import type { ViewProperties } from '../../primitives/View/View';
import type { FC } from 'react';
import type { ImageResizeMode } from 'react-native';

export interface ThumbnailProps {
  alt?: string;
  height?: number;
  icon?: IconName;
  resizeMode?: ImageResizeMode;
  src?: string;
  srcSet?: string;
  width?: number;
}

export type ThumbnailProperties = ThumbnailProps & StylerProperties & ViewProperties;

const Thumbnail: FC<ThumbnailProperties> = ({
  alt,
  children,
  height,
  icon,
  resizeMode = 'cover',
  src,
  srcSet,
  width,
  ...others
}) => {
  const [loaded, setLoaded] = useState(false);

  useLayoutEffect(() => {
    setLoaded(false);
  }, [src]);

  const handleLoad = () => {
    setLoaded(true);
  };

  const common = {
    style: styles({ height, width }, others.style),
  };

  return (
    <View position={POSITION.RELATIVE} {...others} {...common}>
      {src && <Image {...common} alt={alt} onLoad={handleLoad} resizeMode={resizeMode} src={src} srcSet={srcSet} />}
      <Motion
        {...common}
        alignItems={ALIGN.CENTER}
        backgroundColor={COLOR.BG_INTERACTIVE_ACTIVE}
        delay={loaded}
        justifyContent={ALIGN.CENTER}
        layer={SIZE.XS}
        pointer={POINTER.NONE}
        position={POSITION.ABSOLUTE}
        value={{ opacity: loaded ? 0 : 1 }}
      >
        {!loaded && !icon && <Skeleton position={POSITION.ABSOLUTE} style={style.skeleton} />}
        {!loaded && icon && <Icon name={icon} color={COLOR.ICON} />}
        {children}
      </Motion>
    </View>
  );
};

Thumbnail.displayName = 'Thumbnail';

export { Thumbnail };

import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { ALIGN, Aurora, Box, COLOR, Layout, Text } from '@package';

import type { ScreenSize } from '../../../src/components/atoms/Box/Box';

const BOX = { paddingVertical: 'XS', marginBottom: 'XS' };
const TEXT = { paddingHorizontal: 'XS', detail: true };

export const LayoutChapter = () => {
  const { boolean, object, select } = useStoryteller();

  return (
    <Aurora>
      <Layout
        alignItems={select('alignItems', ALIGN.START, { options: [ALIGN.START, ALIGN.CENTER, ALIGN.END] }) as ALIGN}
        fullWidth={boolean('fullWidth', false)}
        inline={boolean('inline', false)}
        justifyContent={
          select('justifyContent', ALIGN.BETWEEN, {
            options: [ALIGN.BETWEEN, ALIGN.AROUND, ALIGN.END, ALIGN.CENTER, ALIGN.START],
          }) as ALIGN
        }
        reverse={boolean('reverse', false)}
        style={object('style', {})}
      >
        <Box {...BOX} backgroundColor={COLOR.PRIMARY} size={'1/3' as ScreenSize}>
          <Text {...TEXT}>1 : 1/3 </Text>
        </Box>
        <Box {...BOX} backgroundColor={COLOR.INFO} size={'1/3' as ScreenSize}>
          <Text {...TEXT}>2: 1/3</Text>
        </Box>
        <Box {...BOX} backgroundColor={COLOR.ACCENT} size={'1/3' as ScreenSize}>
          <Text {...TEXT}>3 : 1/3</Text>
        </Box>

        <Box {...BOX} backgroundColor={COLOR.PRIMARY} size={'1/3' as ScreenSize}>
          <Text {...TEXT}>4 : 1/3 </Text>
        </Box>
        <Box {...BOX} backgroundColor={COLOR.INFO} size={'2/3' as ScreenSize}>
          <Text {...TEXT}>5: 2/3</Text>
        </Box>

        <Box {...BOX} backgroundColor={COLOR.PRIMARY} size={'1/2' as ScreenSize}>
          <Text {...TEXT}>6 : 1/2 </Text>
        </Box>
        <Box {...BOX} backgroundColor={COLOR.INFO} size={'1/2' as ScreenSize}>
          <Text {...TEXT}>7: 1/2</Text>
        </Box>

        <Box {...BOX} backgroundColor={COLOR.PRIMARY} size={'1/3' as ScreenSize}>
          <Text {...TEXT}>8 : 1/3 </Text>
        </Box>
        <Box {...BOX} backgroundColor={COLOR.INFO} size={'1/3' as ScreenSize}>
          <Text {...TEXT}>9: 1/3</Text>
        </Box>
      </Layout>
    </Aurora>
  );
};

LayoutChapter.displayName = '⚛️ Atoms/Layout';

import React, { useState } from 'react';

import { uncapitalize } from '@helpers';
import { ALIGN, POINTER, SPACE, styles } from '@hooks';
import { Icon, MOTION_TYPES, Motion, PRESSABLE_OPACITY, Text, Touchable, View } from '@primitives';

import { BUTTON_VARIANT } from './Button.definition';
import { buttonVariantStyle, contentVariantStyle, contentVariantStyleRecord, style } from './Button.style';
import { COLOR } from '../../../hooks/useStyler/styler.definition';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { IconName } from '../../primitives/Icon/Icon';
import type { TouchableProperties } from '../../primitives/Touchable/Touchable';
import type { FC } from 'react';
import type { GestureResponderEvent, LayoutChangeEvent, LayoutRectangle } from 'react-native';

export interface ButtonProps {
  busy?: boolean;
  disabled?: boolean;
  icon?: IconName;
  small?: boolean;
  variant?: BUTTON_VARIANT;
  wide?: boolean;

  onPress?: (event: GestureResponderEvent) => void;
  type?: 'submit' | 'reset' | 'button';
}

export type ButtonProperties = ButtonProps & StylerProperties & TouchableProperties;

const Button: FC<ButtonProperties> = ({
  busy,
  children,
  disabled,
  icon,
  level = 3,
  small,
  variant = BUTTON_VARIANT.PRIMARY,
  wide = true,
  onPress,
  ...others
}) => {
  const [layout, setLayout] = useState<LayoutRectangle>();

  const handleLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    if (nativeEvent) setLayout(nativeEvent.layout);
  };

  const iconColor = uncapitalize(
    contentVariantStyleRecord[disabled ? 'disabled' : variant].color.replace(/\$color/g, ''),
  ) as COLOR;

  return (
    <Touchable
      {...others}
      style={[
        style.base,
        buttonVariantStyle[variant],
        disabled && buttonVariantStyle.disabled,
        small && style.small,
        !wide && style.fit,
        others.style,
      ]}
      wide={wide}
      onLayout={handleLayout}
      onPress={!busy && !disabled ? onPress : undefined}
      pressableOpacity={disabled ? 1 : PRESSABLE_OPACITY.DEFAULT}
    >
      <Motion
        backgroundColor={
          variant === BUTTON_VARIANT.PRIMARY ? COLOR.BG_ACTION_PRIMARY_PRESSED : COLOR.BG_ACTION_SECONDARY_PRESSED
        }
        disabled={!busy}
        layout={layout}
        pointerEvents={POINTER.NONE}
        style={styles(style.busy, small && style.small)}
        type={MOTION_TYPES.SLOW}
        value={{ width: busy ? '100%' : '0%' }}
      />
      <View style={style.content}>
        {icon && <Icon marginRight={SPACE.SPACE_4} color={iconColor} name={icon} />}
        {typeof children === 'string' ? (
          <Text
            action
            align={ALIGN.CENTER}
            level={level}
            selectable={false}
            style={[contentVariantStyle[variant], disabled && contentVariantStyle.disabled]}
          >
            {children}
          </Text>
        ) : (
          children
        )}
      </View>
    </Touchable>
  );
};

Button.displayName = 'Button';

export { Button };

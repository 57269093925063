import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { COLOR, SPACE, Skeleton, Text, View } from '@package';

import type { Level } from '@package';

const TEXT_TYPES = ['body', 'heading', 'action', 'detail'];

export const SkeletonChapter = () => {
  const { number, select, object, text } = useStoryteller();

  const commonProps = {
    // @ts-expect-error Just for better UX in Storyteller
    backgroundColor: COLOR[select('backgroundColor', 'BG_PRIMARY_LIGHT', { options: Object.keys(COLOR) })],
    // @ts-expect-error Just for better UX in Storyteller
    color: COLOR[select('color', 'TEXT_DISABLED', { options: Object.keys(COLOR) })],
  };

  const textProps = {
    ...commonProps,
    level: number('text.level', 3) as Level,
    children: text('text.children', 'Lorem ipsum...', { multiLine: true }),
  };

  return (
    <>
      <Text detail>View</Text>
      <Skeleton
        {...commonProps}
        height={number('height', 100)}
        style={object('style', {})}
        width={number('width', 100)}
      />

      {TEXT_TYPES.map((type) => (
        <View key={type}>
          <Text detail marginTop={SPACE.SPACE_6}>
            {`Text (${type})`}
          </Text>
          <Skeleton {...textProps} {...{ [type]: true }} />
        </View>
      ))}
    </>
  );
};

SkeletonChapter.displayName = '⚛️ Atoms/Skeleton';

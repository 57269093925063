import { Platform, useWindowDimensions } from 'react-native';

import { IS_JEST } from '@helpers';

import { getBrowser, getScreen } from './helpers';

import type { Screen } from './helpers/getScreen';

const DEFAULT_RULERS = {
  S: [undefined, 599],
  M: [600, 839],
  L: [840, undefined],
};

const android = Platform.OS === 'android';
const ios = Platform.OS === 'ios';
const web = Platform.OS === 'web';

const platform = {
  android,
  ios,
  native: ios || android,
  web,
};

export interface Browser {
  chrome: boolean;
  firefox: boolean;
  inApp: boolean;
  mobile: boolean;
  safari: boolean;
}

export interface Platform {
  android: boolean;
  ios: boolean;
  native: boolean;
  web: boolean;
}

export interface Device {
  browser: Browser;
  platform: Platform;
  screen: Screen;
}

const useDevice = (): Device => {
  const { height, width } = useWindowDimensions();

  return {
    browser: getBrowser(),
    platform,
    screen: getScreen(IS_JEST ? 667 : height, IS_JEST ? 375 : width, DEFAULT_RULERS),
  };
};

export { useDevice, DEFAULT_RULERS };

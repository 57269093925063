// TODO: This is not a real "hook"
export const useSupportedProps = (props: Record<string, any> = {}, supported: string[] = []): Record<string, any> => {
  const safeProps: Record<string, any> = {};

  for (const prop in props) {
    if (supported.includes(prop)) safeProps[prop] = props[prop];
  }

  return safeProps;
};

import { LinearGradient } from 'expo-linear-gradient';
import React, { useLayoutEffect, useState } from 'react';

import { IS_JEST, capitalize } from '@helpers';
import { COLOR, styles, useDevice } from '@hooks';
import { Motion, Text, View } from '@primitives';
import { Theme } from '@theming';

import { TEXT_GAP } from './Skeleton.definition';
import { style } from './Skeleton.style';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { TextProperties } from '../../primitives/Text/Text';
import type { ViewProperties } from '../../primitives/View/View';
import type { AuroraTheme } from '@theming';
import type { FC } from 'react';
import type { LayoutChangeEvent } from 'react-native';

export interface SkeletonProps extends StylerProperties {
  backgroundColor?: COLOR;
  color?: COLOR;
  text?: boolean;
  height?: number;
  width?: number;
}

export type SkeletonProperties = SkeletonProps & StylerProperties & ViewProperties & TextProperties;

const Skeleton: FC<SkeletonProperties> = ({
  backgroundColor = COLOR.BG_PRIMARY_LIGHT,
  children,
  color = COLOR.TEXT_DISABLED,
  height,
  width,
  ...others
}) => {
  const { screen } = useDevice();

  const [layout, setLayout] = useState<{ height: number; width: number } | undefined>();

  useLayoutEffect(() => {
    if (height && width) setLayout({ height, width });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    if (height && width) {
      setLayout({ height, width });
    } else if (nativeEvent && nativeEvent.layout) {
      setLayout(nativeEvent.layout);
    }
  };

  const {
    colorBgBaseTransparent,
    motionSkeleton = 1200,
    [`color${capitalize(color)}` as keyof AuroraTheme]: hexColor,
  } = Theme.get();

  return (
    <View {...others}>
      <View
        backgroundColor={backgroundColor}
        style={styles(
          style.container,
          layout ? { height: layout.height, width: layout.width + (children ? TEXT_GAP : 0) } : { height, width },
          others.style,
        )}
        onLayout={!children && !layout ? handleLayout : undefined}
      >
        <Motion
          disabled={!layout || IS_JEST}
          duration={motionSkeleton}
          loops={layout ? -1 : undefined}
          style={style.content}
          value={{
            left: 0,
            translateX: screen.width * (layout ? 1 : -1),
          }}
        >
          <LinearGradient
            colors={[colorBgBaseTransparent, hexColor, hexColor, colorBgBaseTransparent] as string[]}
            end={{ x: 1, y: 0 }}
            locations={[0, 0.25, 0.75, 1]}
            start={{ x: 0, y: 0 }}
            style={style.content}
          />
        </Motion>

        {children && (
          <Text {...others} accessibilityHidden style={style.text} onLayout={handleLayout}>
            {children}
          </Text>
        )}
      </View>
    </View>
  );
};

Skeleton.displayName = 'Skeleton';

export { Skeleton };

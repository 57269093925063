import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';
import { KeyboardAvoidingView, Platform } from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';

import {
  // Components
  Aurora,
  Modal,
  ScrollView,
  Text,
  View,
} from '@package';

export const ModalFullScreenChapter = () => {
  const { boolean, event } = useStoryteller();

  const style = StyleSheet.create({
    modalContentStyle: {
      flex: 1,
      width: '100%',
      paddingBottom: 0,
    },
    content: {
      flex: 1,
      borderWidth: '$borderWidth2',
      borderColor: '#AB00FB',
      borderRadius: '$space1',
      backgroundColor: '#F5E8FB',
    },
    contentScrollView: {
      padding: '$space5',
      marginVertical: '$space6',
      flex: 1,
      height: '100%',
    },
    title: {
      color: '#AB00FB',
      textAlign: 'center',
      paddingBottom: '$space5',
    },
    textDetail: {
      paddingBottom: '$space5',
      textAlign: 'left',
    },
    textCode: {
      paddingBottom: '$space2',
      textAlign: 'left',
    },
    containerCode: {
      backgroundColor: 'rgba(171, 0, 251, 0.1)',
      marginBottom: '$space5',
      paddingVertical: '$space2',
    },
  });

  return (
    <Aurora>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}
        enabled
        keyboardVerticalOffset={0}
        style={{ flex: 1 }}
      >
        <Modal
          fullHeight={boolean('fullHeight', true)}
          fullWidth={boolean('fullWidth', true)}
          scrollable={boolean('scrollable', false)}
          contentStyle={style.modalContentStyle}
          isVisible={boolean('isVisible', true)}
          // @ts-expect-error Just for better UX in Storyteller
          onClose={() => event('onClose')}
        >
          <View style={style.content}>
            <ScrollView style={style.contentScrollView}>
              <Text style={style.title}>Fullscreen Modal</Text>
              <Text detail level={1} style={style.textDetail}>
                This is an example of a modal full screen and with content handled 100% by the consumer.
              </Text>
              <Text detail level={1} style={style.textDetail}>
                Main example of this would be the payments modal when stripe returns a challange on SCA.
              </Text>

              <Text detail level={1} style={style.textDetail}>
                {`FullScreen Modal's props:`}
              </Text>
              <View style={style.containerCode}>
                <Text detail level={2}>
                  {`fullHeight={true} scrollable={false} \n
                 contentStyle={{ flex: 1, width: '100%', paddingHorizontal: 0, paddingBottom: 0 }}`}
                </Text>
              </View>

              <Text detail level={1} style={style.textDetail}>
                Also Modal content children should have a View with a ScrollView as follows:
              </Text>

              <View style={style.containerCode}>
                <Text detail level={2}>
                  {`<View style={{ flex: 1 }}>
                    <ScrollView style={{ height: '100%' }}>
                      content here....
                    </ScrollView>
                  </View>`}
                </Text>
              </View>

              <Text detail level={1} style={style.textDetail}>
                This screen does not look correct in Storyteller (Web Platform) but it will in a real application, this
                is because StoryTeller is mocking the viewport whereas in a real application you have a native viewport.
              </Text>
              <Text detail level={1}>
                If you play with the knobs fullscreen and scrollable you will see that the modal will not work
                correctly, so configuration in this case is very strict.
              </Text>
            </ScrollView>
          </View>
        </Modal>
      </KeyboardAvoidingView>
    </Aurora>
  );
};

ModalFullScreenChapter.displayName = '✳️ Molecules/Modal FullScreen';

import StyleSheet from 'react-native-extended-stylesheet';

import { ITEM_HEIGHT } from './WheelPicker.definition';

export const style = StyleSheet.create({
  item: {
    alignItems: 'center',
    borderRadius: '$borderRadiusFull',
    height: ITEM_HEIGHT,
    justifyContent: 'center',
  },
});

import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { Table } from '@package';

export const TableChapter = () => {
  const { object } = useStoryteller();

  return (
    <Table
      // @ts-expect-error Just for better UX in Storyteller
      schema={object('schema', {
        size: 'Size',
        bustSize: 'Bust (in)',
        waistSize: 'Waist (in)',
        hipSize: 'Hip (in)',
      })}
      // @ts-expect-error Just for better UX in Storyteller
      dataSource={object('dataSource', [
        { size: '6', bustSize: '32-33', waistSize: '24.5-25.5', hipSize: '34.5-35-5' },
        { size: '8', bustSize: '32-33', waistSize: '24.5-25.5', hipSize: '34.5-35-5' },
        { size: '10', bustSize: '32-33', waistSize: '24.5-25.5', hipSize: '34.5-35-5' },
        { size: '12', bustSize: '32-33', waistSize: '24.5-25.5', hipSize: '34.5-35-5' },
        { size: '14', bustSize: '32-33', waistSize: '24.5-25.5', hipSize: '34.5-35-5' },
        { size: '16', bustSize: '32-33', waistSize: '24.5-25.5', hipSize: '34.5-35-5' },
        { size: '18', bustSize: '32-33', waistSize: '24.5-25.5', hipSize: '34.5-35-5' },
        { size: '20', bustSize: '32-33', waistSize: '24.5-25.5', hipSize: '34.5-35-5' },
      ])}
      style={object('style', {})}
    />
  );
};

TableChapter.displayName = '⚛️ Atoms/Table';

import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { BUTTON_VARIANT, Button, COLOR, FLEX_DIRECTION, SIZE, TEXT_ROLES, Text, Theme, ToolTip, View } from '@package';

import type { Glyphs } from '@lookiero/aurora-iconfont';

const dummyFill = (
  <>
    <Text role={TEXT_ROLES.p} marginBottom={SIZE.XS}>
      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas nihil, eum ducimus minima pariatur sunt iure
      repellendus alias mollitia in assumenda at dolorem omnis minus dolorum numquam amet unde esse?
    </Text>
    <Text role={TEXT_ROLES.p}>
      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas nihil, eum ducimus minima pariatur sunt iure
      repellendus alias mollitia in assumenda at dolorem omnis minus dolorum numquam amet unde esse?
    </Text>
  </>
);

export const ToolTipChapter = () => {
  const { boolean, select, object, text } = useStoryteller();

  const { iconGlyphs } = Theme.get();

  return (
    <View flexDirection={FLEX_DIRECTION.COLUMN}>
      {dummyFill}
      <ToolTip
        // @ts-expect-error Just for better UX in Storyteller
        align={select('align', 'top', { options: ['top', 'bottom'] })}
        // @ts-expect-error Just for better UX in Storyteller
        alignArrow={select('alignArrow', 'center', { options: ['left', 'center', 'right'] })}
        color={select('color', COLOR.BG_SURFACE, { options: Object.values(COLOR) }) as COLOR}
        colorText={select('colorText', COLOR.TEXT, { options: Object.values(COLOR) }) as COLOR}
        icon={select('name', 'tree', { options: Object.keys(iconGlyphs) }) as keyof Glyphs}
        shadow={boolean('shadow', true)}
        text={text(
          'text',
          'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea optio nemo repudiandae eaque. Soluta eius suscipit, nobis illum fugiat saepe odio dolor, exercitationem laudantium quo animi modi dolore quam illo!',
        )}
        title={text('title', 'lookiero-title')}
        marginVertical={SIZE.M}
        style={object('style', {})}
      >
        <Button small icon="comment" wide variant={BUTTON_VARIANT.PRIMARY}>
          Tooltip
        </Button>
      </ToolTip>
      {dummyFill}
    </View>
  );
};

ToolTipChapter.displayName = '✳️ Molecules/ToolTip';

import React, { Fragment } from 'react';
import { Platform, StatusBar } from 'react-native';

import { ButtonIcon } from '@atoms';
import { ALIGN, COLOR, FLEX_DIRECTION, POSITION, styles } from '@hooks';
import { SafeAreaView, Text, VIEW_ROLES, View } from '@primitives';

import { style } from './Header.style';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { ViewProperties } from '../../primitives/View/View';
import type { Component, FC, ReactNode } from 'react';

export interface HeaderProps {
  color?: string;
  container?: typeof Component;
  fullWidth?: boolean;
  logo?: ReactNode;
  notification?: boolean;
  onBack?: () => void;
  onClose?: () => void;
  onMenu?: () => void;
  title?: string;
}

export type HeaderProperties = HeaderProps & StylerProperties & ViewProperties;

export const Header: FC<HeaderProperties> = ({
  children,
  color = COLOR.BG_BASE,
  container: Container = Fragment,
  fullWidth = true,
  logo,
  notification,
  onBack,
  onClose,
  onMenu,
  title,
  ...others
}) => {
  return (
    <View {...others} backgroundColor={color} role={VIEW_ROLES.header} wide>
      <Container>
        <SafeAreaView style={Platform.OS === 'android' ? { paddingTop: StatusBar.currentHeight } : undefined}>
          <View
            alignItems={ALIGN.CENTER}
            alignSelf={ALIGN.CENTER}
            flexDirection={FLEX_DIRECTION.ROW}
            style={styles(style.header, fullWidth && style.fullWidth)}
          >
            {onBack && (
              <ButtonIcon alignSelf={ALIGN.CENTER} color={COLOR.ICON} name="arrow_left" onPress={onBack} small />
            )}

            {logo && <>{logo}</>}

            {title || onClose || onMenu ? (
              <>
                {title && (
                  <Text
                    action
                    align={ALIGN.CENTER}
                    position={POSITION.ABSOLUTE}
                    color={COLOR.TEXT_ACTION_SECONDARY}
                    style={style.title}
                  >
                    {title}
                  </Text>
                )}
                <View alignItems={ALIGN.CENTER} flexDirection={FLEX_DIRECTION.ROW} style={style.actions}>
                  {children}

                  {onMenu && (
                    <ButtonIcon
                      alignSelf={ALIGN.CENTER}
                      color={COLOR.ICON}
                      name="menu"
                      nativeID="menu-button"
                      notification={notification}
                      onPress={onMenu}
                      testID="menu-button"
                      small
                    />
                  )}
                  {onClose && <ButtonIcon color={COLOR.ICON} name="close" onPress={onClose} small />}
                </View>
              </>
            ) : (
              children
            )}
          </View>
        </SafeAreaView>
      </Container>
    </View>
  );
};

Header.displayName = 'Header';

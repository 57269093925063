export const calcMotion = ({ motion = 'top', isVisible = false } = {}) => ({
  opacity: isVisible ? '1' : '0',
  ...(motion === 'pop'
    ? {
        scale: isVisible ? 1 : 0.9,
      }
    : {
        translateY: isVisible ? '0%' : motion === 'top' ? '-100%' : '100%',
      }),
});

const ENV_JEST = process.env.JEST_WORKER_ID !== undefined;

const FONTS = {
  // Fonts
  'AreaInktrap-Semibold': require('@lookiero/aurora-fonts/AreaInktrap-Semibold.otf'),
  'AreaNormal-Semibold': require('@lookiero/aurora-fonts/AreaNormal-Semibold.otf'),
  'AreaNormal-Extrabold': require('@lookiero/aurora-fonts/AreaNormal-Extrabold.otf'),
  // Icons
  auroraicons: require('@lookiero/aurora-iconfont/dist/auroraicons.ttf'),
} as const;

export { ENV_JEST, FONTS };

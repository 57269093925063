import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';
import { KeyboardAvoidingView, Platform } from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';

import { Aurora, Button, COLOR, Modal, Text, View } from '@package';

import type { ScreenSize } from '../../../src/components/atoms/Box/Box';

export const ModalChapter = () => {
  const { boolean, event, select, text } = useStoryteller();

  const style = StyleSheet.create({
    content: {
      backgroundColor: '#F5E8FB',
      borderWidth: '$borderWidth1',
      borderStyle: 'dashed',
      borderColor: '#AB00FB',
      borderRadius: '$borderRadius2',
      alignItems: 'center',
      padding: '$space5',
      marginVertical: '$space6',
    },
    title: {
      color: '#AB00FB',
    },
  });

  return (
    <Aurora>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}
        enabled
        keyboardVerticalOffset={0}
        // eslint-disable-next-line react-native/no-inline-styles
        style={{ flex: 1 }}
      >
        <Modal
          color={select('color', COLOR.BASE, { options: Object.values(COLOR) }) as COLOR}
          description={text('description', 'this is the description')}
          isVisible={boolean('isVisible', true)}
          overlayClose={boolean('overlayClose', true)}
          nativeID={text('nativeID', 'my-modal')}
          size={
            // @ts-expect-error Just for better UX in Storyteller
            select('size', undefined, {
              options: ['1/3', '1/2', '2/3'],
              hint: 'By default uses M:2/3 &  L:1/3',
            }) as ScreenSize
          }
          swipeable={boolean('swipeable', false)}
          title={text('title', 'Modal title')}
          // @ts-expect-error Just for better UX in Storyteller
          onClose={() => event('onClose')}
        >
          <View style={style.content}>
            <Text style={style.title}>Content</Text>
            <Text detail style={style.text}>
              Due to a bug if you have a button as last element you should set a flex: 0
            </Text>
          </View>

          <Button
            // @ts-expect-error Just for better UX in Storyteller
            onPress={() => event('clicked')}
          >
            Call to Action
          </Button>
        </Modal>
      </KeyboardAvoidingView>
    </Aurora>
  );
};

ModalChapter.displayName = '✳️ Molecules/Modal';

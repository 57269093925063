const BANNED_PROPS = ['id', 'style'];

enum IMAGE_RESIZE_MODES {
  'contain' = 'contain',
  'cover' = 'cover',
  'stretch' = 'stretch',
  'repeat' = 'repeat',
  'center' = 'center',
}

export { BANNED_PROPS, IMAGE_RESIZE_MODES };

import React from 'react';

import { ALIGN, SPACE, styles, useDevice } from '@hooks';
import { View } from '@primitives';

import { style } from './Layout.style';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { ViewProperties } from '../../primitives/View/View';
import type { FC } from 'react';

export interface LayoutProps {
  fullWidth?: boolean;
  inline?: boolean;
  reverse?: boolean;
}
export type LayoutProperties = LayoutProps & StylerProperties & ViewProperties;

const Layout: FC<LayoutProperties> = ({
  children,
  alignItems = ALIGN.START,
  fullWidth,
  inline = true,
  justifyContent = ALIGN.BETWEEN,
  reverse,
  ...others
}) => {
  const { screen } = useDevice();

  return (
    <View
      {...others}
      alignItems={alignItems}
      justifyContent={justifyContent}
      paddingHorizontal={!fullWidth ? (screen.S ? SPACE.SPACE_6 : SPACE.SPACE_10) : undefined}
      style={styles(
        style.layout,
        inline ? style.row : style.column,
        reverse && (inline && !screen.S ? style.reverse : style.reverseColumn),
        fullWidth && style.fullWidth,
        others.style,
      )}
      wide
    >
      {children}
    </View>
  );
};

Layout.displayName = 'Layout';

export { Layout };

import { useStoryteller } from '@lookiero/storyteller';
import React, { useRef } from 'react';

import { SWITCH_VARIANT as INPUT_OPTION_TYPES, InputOption, SPACE, TEXT_ROLES, Text } from '@package';

import type { SWITCH_VARIANT } from '@package';

export const InputOptionChapter = () => {
  const { boolean, event, select, object, text } = useStoryteller();
  const disabled = useRef(false);

  return (
    <InputOption
      checked={boolean('checked', disabled.current)}
      disabled={boolean('disabled', disabled.current)}
      id={text('id', '1')}
      name={text('name', 'example')}
      style={object('style', {})}
      type={select('type', INPUT_OPTION_TYPES.RADIO, { options: Object.values(INPUT_OPTION_TYPES) }) as SWITCH_VARIANT}
      value={text('value', 'example')}
      onChange={(value) => {
        // @ts-expect-error Just for better UX in Storyteller
        event('onChange', value.toString());
        disabled.current = !disabled.current;
      }}
    >
      <Text marginLeft={SPACE.SPACE_2} role={TEXT_ROLES.p}>
        {text('children', 'Lorem ipsum, dolor sit amet consectetur adipisicing elit.')}
      </Text>
    </InputOption>
  );
};

InputOptionChapter.displayName = '⚛️ Atoms/InputOption';

import { useEffect, useState } from 'react';
import { Keyboard, Platform } from 'react-native';

export const useKeyboard = (): { isKeyboardOpen: boolean; closeKeyboard: () => void } => {
  const [isKeyboardOpen, setKeyboardOpen] = useState(false);

  useEffect(() => {
    const IOS = Platform.OS === 'ios';

    const showEvent = IOS ? 'keyboardWillShow' : 'keyboardDidShow';
    const hideEvent = IOS ? 'keyboardWillHide' : 'keyboardDidHide';

    const handleShowSubscriber = Keyboard.addListener(showEvent, handleShow);
    const handleHideSubscriber = Keyboard.addListener(hideEvent, handleHide);

    return () => {
      handleShowSubscriber.remove();
      handleHideSubscriber.remove();
    };
  }, []);

  const handleShow = () => setKeyboardOpen(true);

  const handleHide = () => setKeyboardOpen(false);

  return { isKeyboardOpen, closeKeyboard: Keyboard.dismiss };
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { forwardRef } from 'react';

import { ALIGN, FLEX_DIRECTION } from '@hooks';
import { Input, Text, View } from '@primitives';

import { style } from './Phone.style';

import type { COLOR, StylerProperties } from '../../../../../hooks/useStyler/styler.definition';
import type { FC, ForwardedRef } from 'react';
import type { TextInput } from 'react-native';

export interface PhoneProps {
  color?: COLOR;
  prefix?: string;
}

export type PhoneProperties = PhoneProps & StylerProperties;

const Phone: FC<PhoneProperties> = forwardRef(({ color, prefix, ...others }, ref: ForwardedRef<TextInput>) => (
  <View alignItems={ALIGN.CENTER} flexDirection={FLEX_DIRECTION.ROW}>
    {prefix && (
      <Text color={color} detail level={1} style={style.prefix}>
        {prefix}
      </Text>
    )}
    <Input {...others} ref={ref} style={style.input} />
  </View>
));

Phone.displayName = 'InputFieldPhone';

export { Phone };

import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { IMAGE_2x_URL, IMAGE_URL } from '@mocks';
import { IMAGE_RESIZE_MODES, Image } from '@package';

export const ImageChapter = () => {
  const { boolean, event, object, select, text } = useStoryteller();

  return (
    <Image
      alt={text('alt', 'lookiero-alt')}
      autoSize={boolean('autoSize', false)}
      // @ts-expect-error Just for better UX in Storyteller
      resizeMode={select('resizeMode', IMAGE_RESIZE_MODES.contain, { options: Object.keys(IMAGE_RESIZE_MODES) })}
      src={text('src', IMAGE_URL)}
      srcSet={text('srcSet', `${IMAGE_URL} 1x, ${IMAGE_2x_URL} 2x`, { multiLine: true })}
      style={object('style', { height: 128, width: 128 })}
      // @ts-expect-error Just for better UX in Storyteller
      onError={(error) => error && event('onError', error)}
    />
  );
};

ImageChapter.displayName = '🐵 Primitives/Image';

import { useStoryteller } from '@lookiero/storyteller';
import React, { useState } from 'react';

import { INPUT_TYPES, Input } from '@package';

export const InputChapter = () => {
  const { boolean, event, number, object, select, text } = useStoryteller();

  const [value, setValue] = useState<string>();

  const handleChange = (nextValue: string) => {
    // @ts-expect-error Just for better UX in Storyteller
    event('onChange', nextValue);
    setValue(nextValue);
  };

  return (
    <Input
      disabled={boolean('disabled', false)}
      id={text('id', 'lookiero-id')}
      multiline={boolean('multiline', false)}
      minLength={number('minLength', 8)}
      placeholder={text('placeholder', 'lookiero-placeholder')}
      required={boolean('required', false)}
      style={object('style', {})}
      trimSpaces={boolean('trimSpaces', false)}
      // @ts-expect-error Just for better UX in Storyteller
      type={select('type', INPUT_TYPES.text, { options: Object.keys(INPUT_TYPES) })}
      value={text('value', value as string)}
      // @ts-expect-error Just for better UX in Storyteller
      onBlur={() => event('onBlur')}
      // @ts-expect-error Just for better UX in Storyteller
      onChange={handleChange}
      // @ts-expect-error Just for better UX in Storyteller
      onFocus={() => event('onFocus')}
      // @ts-expect-error Just for better UX in Storyteller
      onError={(error) => error && event('onError', error)}
    />
  );
};

InputChapter.displayName = '🐵 Primitives/Input';

import React, { Fragment, useMemo } from 'react';
import { View } from 'react-native';

import { PortalProvider, StackProvider } from '@contexts';
import { styles, useBanProps, useBanStylerProps, useStyler } from '@hooks';
import { DefaultTheme, Theme } from '@theming';

import { BANNED_PROPS } from './Aurora.definition';
import { style } from './Aurora.style';

import type { ThemeDefinition } from '../../../theming/Theme.definition';
import type { FC, ReactNode } from 'react';
import type { ViewProps } from 'react-native';

export interface AuroraProps {
  children?: ReactNode;
  theme?: Partial<ThemeDefinition>;
  usePortal?: boolean;
  useStack?: boolean;
}

export type AuroraProperties = AuroraProps & ViewProps;

const Aurora: FC<AuroraProperties> = ({
  children,
  theme = DefaultTheme,
  useStack = true,
  usePortal = true,
  ...others
}) => {
  useMemo(() => Theme.set(theme), [theme]);

  const Stack = useStack ? StackProvider : Fragment;
  const Portal = usePortal ? PortalProvider : Fragment;

  return (
    <View
      {...useBanProps(useBanStylerProps(others), BANNED_PROPS)}
      {...useStyler({ ...others, style: styles(style.container, others.style) })}
    >
      <Stack>
        <Portal>{children}</Portal>
      </Stack>
    </View>
  );
};

Aurora.displayName = 'Aurora';

export { Aurora };

import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  container: {
    overflow: 'visible',
    padding: '$space2',
  },

  small: {
    padding: 0,
  },

  notification: {
    // TODO: Check border color on iOS
    top: 0,
    right: 0,
    borderWidth: '$borderWidth2',
    borderRadius: '$borderRadiusFull',
    width: () => StyleSheet.value('$space2 + $space05'),
    height: () => StyleSheet.value('$space2 + $space05'),
  },
});

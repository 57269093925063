import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  onequarter: {
    maxWidth: '25%',
  },

  onethird: {
    maxWidth: '33.3333%',
  },

  onehalf: {
    maxWidth: '50%',
  },

  twothird: {
    maxWidth: '66.6666%',
  },

  center: {
    marginRight: 'auto',
    marginLeft: 'auto',
  },

  right: {
    marginLeft: 'auto',
  },
});

import { useStoryteller } from '@lookiero/storyteller';
import React, { useState } from 'react';

import { IMAGE_URL } from '@mocks';
import { BUTTON_VARIANT, Button, FLEX_DIRECTION, SPACE, Slide, Slider, Theme, View } from '@package';

const BUTTON = { margin: SPACE.SPACE_2, small: true, variant: BUTTON_VARIANT.SECONDARY, wide: false };
const SLIDES = Array.from(Array(16).keys());

const { space4: GAP } = Theme.get();

export const SliderChapter = () => {
  const { event, number, object, viewport } = useStoryteller();

  const [index, setIndex] = useState(number('selected', 1));

  const size = viewport.width < 600 ? viewport.width : 300;

  const handleChange = (next: number) => {
    setIndex(next);
    event('onChange', [{ index: next }]);
  };

  return (
    <View>
      <Slider selected={index} snapInterval={size + GAP} style={object('style', {})} onChange={handleChange}>
        {SLIDES.map((item) => (
          <Slide key={item} height={size * 1.3} marginRight={SPACE.SPACE_4} src={`${IMAGE_URL}-${item}`} width={size} />
        ))}
      </Slider>

      <View flexDirection={FLEX_DIRECTION.ROW} margin={SPACE.SPACE_2}>
        <Button {...BUTTON} disabled={index === 0} onPress={() => setIndex(index - 1)}>
          Previous
        </Button>
        <Button {...BUTTON} disabled={index >= SLIDES.length - 2} onPress={() => setIndex(index + 1)}>
          Next
        </Button>
      </View>
    </View>
  );
};

SliderChapter.displayName = '⚛️ Atoms/Slider';

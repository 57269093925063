import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { SWITCH_VARIANT as INPUT_OPTION_TYPES, OptionItem, SPACE, Text, View } from '@package';

import type { SWITCH_VARIANT } from '@package';

export const OptionItemChapter = () => {
  const { boolean, event, object, select, text } = useStoryteller();

  const DEFAULTS_PROPS = {
    name: 'name lookiero',
    value: 'value lookiero',
  };

  return (
    <View padding={SPACE.SPACE_5}>
      <Text>
        This is an example of a real life case of this component. You can only control properties on the first item
        (German), the rest are just to see how they look.
      </Text>
      <OptionItem
        checked={boolean('checked', false)}
        detail={text('detail', 'am beliebtesten')}
        detailStyle={object('detailStyle', { marginLeft: 0, maxWidth: 96 })}
        disabled={boolean('disabled', false)}
        name={text('name', 'name lookiero')}
        outlined={boolean('outlined', true)}
        style={object('style', {})}
        // @ts-expect-error Just for better UX in Storyteller
        text={text('text', undefined)}
        title={text('title', 'Alle 2 Monate')}
        type={
          select('type', INPUT_OPTION_TYPES.RADIO, { options: Object.values(INPUT_OPTION_TYPES) }) as SWITCH_VARIANT
        }
        unselected={boolean('unselected', false)}
        value={text('value', 'value-lookiero')}
        onChange={(e) => event('onChange', [{ e }])}
      />
      <OptionItem {...DEFAULTS_PROPS} detail={'Más popular'} title={'Cada 2 meses'} />
      <OptionItem {...DEFAULTS_PROPS} detail={'Plus populaire'} title={'Tous les 2 mois'} />
      <OptionItem {...DEFAULTS_PROPS} detail={'Most popular'} title={'Every 2 months'} />
      <OptionItem {...DEFAULTS_PROPS} detail={'Più popolare'} title={'Ogni 2 mesi'} />
      <OptionItem {...DEFAULTS_PROPS} detail={'Mais popular'} title={'Cada 2 meses'} />
    </View>
  );
};

OptionItemChapter.displayName = '⚛️ Atoms/OptionItem';

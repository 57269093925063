// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { forwardRef, useState } from 'react';
import { Platform } from 'react-native';

import { testID as getTestID } from '@helpers';
import { COLOR, DISPLAY, POINTER, POSITION, SIZE, styles, useBanProps, useBanStylerProps, useId } from '@hooks';
import { Icon, Motion, Text, View } from '@primitives';
import { Theme } from '@theming';

import { useField } from './helpers';
import { BANNED_PROPS } from './InputField.definition';
import { HOC } from './InputField.hoc';
import { style } from './InputField.style';
import { SPACE } from '../../../hooks/useStyler/styler.definition';

import type { HOCProperties } from './InputField.hoc';
import type { Fields } from './variants/Fieldset/Fieldset';
import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { IconName } from '../../primitives/Icon/Icon';
import type { InputValue } from '../../primitives/Input/Input.definition';
import type { INPUT_TYPES } from '@primitives';
import type { NativeSyntheticEvent, TextInput, TextInputFocusEventData } from 'react-native';

const MOTION_M_DELAY = 300;
const isAndroid = Platform.OS === 'android';

export interface InputFieldProps {
  animatedLabel?: boolean;
  defaultValue?: InputValue;
  error?: boolean;
  emptyOptionText?: string;
  fieldset?: Fields;
  format?: string;
  formatValue?: string;
  hint?: string;
  icon?: IconName;
  id?: string;
  label?: string;
  multiLine?: boolean;
  name: string;
  type?: INPUT_TYPES;
}

export type InputFieldProperties = InputFieldProps & StylerProperties & HOCProperties;

const InputField = forwardRef<TextInput, InputFieldProperties>(
  (
    {
      animatedLabel = true,
      disabled = false,
      error = false,
      fieldset,
      hint,
      icon,
      id,
      label,
      multiLine = false,
      name,
      style: inheritStyle,
      type,
      onBlur,
      onChange,
      onFocus,
      options,
      placeholder,
      testID,
      ...others
    },
    ref,
  ) => {
    const idField = useId(id);
    const { handleChange, filled } = useField({ onChange, ...others });

    const [focus, setFocus] = useState(false);
    const [timeoutID, setTimeoutID] = useState<NodeJS.Timeout | undefined>(undefined);

    const handleBlur = (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
      if (!focus) return;
      if (event && event.persist) event.persist();
      setFocus(false);
      setTimeoutID(onBlur ? setTimeout(() => onBlur(event), MOTION_M_DELAY) : undefined);
    };

    const handleFocus = (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
      if (timeoutID) clearTimeout(timeoutID);
      if (!focus) {
        setFocus(true);
        if (onFocus) onFocus(event);
      }
    };

    const { space05, space1, space3, space4 } = Theme.get();

    const empty = !filled && !focus;

    let color: COLOR = COLOR.TEXT_MEDIUM;
    if (disabled) color = COLOR.TEXT_DISABLED;
    else if (error) color = COLOR.TEXT_ERROR;
    else if (focus) color = COLOR.TEXT;

    const labelTextFont = empty && !error ? { body: true, level: 3 } : { detail: true, level: 2 };

    return (
      <View
        {...useBanProps(others, [...BANNED_PROPS, 'accessibilityLabel'])}
        style={styles(
          style.inputField,
          focus && style.focus,
          disabled && style.disabled,
          empty && !error && style.empty,
          error && style.error,
          (filled || error) && style.filled,
          inheritStyle,
        )}
      >
        <View
          backgroundColor={COLOR.BG_BASE}
          borderColor={
            error
              ? COLOR.BORDER_INPUT_ERROR
              : disabled
                ? COLOR.BORDER_INPUT
                : focus
                  ? COLOR.BORDER_INPUT_FOCUS
                  : COLOR.BORDER_INPUT
          }
          position={POSITION.RELATIVE}
          style={style.border}
          {...getTestID(testID)}
        >
          <HOC
            {...useBanStylerProps(others)}
            {...getTestID(testID)}
            color={color}
            disabled={disabled}
            error={error}
            focus={focus}
            fieldset={fieldset}
            id={idField}
            label={label}
            name={name}
            multiLine={multiLine}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            options={options}
            position={POSITION.RELATIVE}
            placeholder={placeholder}
            ref={ref}
            style={styles(
              style.content,
              multiLine && style.multiline,
              empty && !error && !placeholder && style.contentEmpty,
            )}
            type={type}
          />

          {label && animatedLabel ? (
            <Motion
              backgroundColor={COLOR.BG_BASE}
              color={color}
              display={DISPLAY.BLOCK}
              layer={SIZE.XS}
              marginLeft={SPACE.SPACE_4}
              pointer={POINTER.NONE}
              position={POSITION.ABSOLUTE}
              style={styles(style.containerLabel, empty && !error && style.labelEmpty)}
              value={{
                translateY: empty && !error ? Number(isAndroid ? space3 + space05 : space4) : Number(space3) * -1,
                translateX: empty && !error ? 0 : -Number(space1),
              }}
            >
              <Text {...labelTextFont} color={color} ellipsizeMode htmlFor={idField} style={style.label}>
                {label}
              </Text>
            </Motion>
          ) : (
            label &&
            empty &&
            !error && (
              <View
                marginLeft={SPACE.SPACE_4}
                pointer={POINTER.NONE}
                position={POSITION.ABSOLUTE}
                style={[
                  style.containerLabel,
                  style.labelEmpty,
                  {
                    transform: [{ translateY: Number(isAndroid ? space3 + space05 : space4) }, { translateX: 0 }],
                  },
                ]}
              >
                <Text {...labelTextFont} color={color} ellipsizeMode htmlFor={idField} style={style.label}>
                  {label}
                </Text>
              </View>
            )
          )}

          {icon && (
            <Icon
              color={color}
              name={icon}
              pointerEvents={POINTER.NONE}
              position={POSITION.ABSOLUTE}
              style={style.icon}
            />
          )}
        </View>
        {!!hint && (
          <Text color={color} detail display={DISPLAY.INLINE_BLOCK} level={2} marginTop={SPACE.SPACE_1}>
            {hint}
          </Text>
        )}
      </View>
    );
  },
);

InputField.displayName = 'InputField';

export { InputField };

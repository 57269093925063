type Rule = 'S' | 'M' | 'L';
type Ruler = Record<Rule, (number | undefined)[]>;

export interface Screen {
  height: number;
  width: number;
  S: boolean;
  M: boolean;
  L: boolean;
}

const getScreen = (height: number, width: number, rulers: Ruler): Screen => {
  const screen: Screen = { height, width, S: false, M: false, L: false };
  const rulerKeys = Object.keys(rulers || {}) as Rule[];

  rulerKeys.forEach((rulerKey) => (screen[rulerKey] = false));
  rulerKeys.some((rulerKey) => {
    const [min = 0, max = 3840] = rulers[rulerKey];

    screen[rulerKey] = width >= min && width <= max;

    return screen[rulerKey];
  });

  return screen;
};

export { getScreen };

enum ROLES {
  button = 'button',
  adjustable = 'adjustable',
  alert = 'alert',
  checkbox = 'checkbox',
  combobox = 'combobox',
  header = 'header',
  image = 'image',
  imagebutton = 'imagebutton',
  keyboardkey = 'keyboardkey',
  link = 'link',
  menu = 'menu',
  menubar = 'menubar',
  menuitem = 'menuitem',
  none = 'none',
  progressbar = 'progressbar',
  radio = 'radio',
  radiogroup = 'radiogroup',
  scrollbar = 'scrollbar',
  search = 'search',
  spinbutton = 'spinbutton',
  summary = 'summary',
  switch = 'switch',
  tab = 'tab',
  tablist = 'tablist',
  text = 'text',
  timer = 'timer',
  toolbar = 'toolbar',
}

enum PRESSABLE_OPACITY {
  DEFAULT = 0.7,
  OPTION_IMAGE = 0.9,
}
export { PRESSABLE_OPACITY, ROLES };

import { PickerIOS } from '@react-native-picker/picker';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal, Pressable, Text, View } from 'react-native';

import { testID } from '@helpers';

import { style } from './Select.style';
import { Icon } from '../Icon';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { FC, ReactElement } from 'react';
import type { StyleProp, ViewStyle } from 'react-native';

export interface SelectIOSProps {
  children: ReactElement[];
  enabled?: boolean;
  id?: string;
  itemStyle?: StyleProp<ViewStyle>;
  selectedValue?: string | number;

  onValueChange?: (value: string | number | undefined) => void;
}

export type SelectIOSProperties = SelectIOSProps & StylerProperties;

const SelectIOS: FC<SelectIOSProperties> = forwardRef(
  ({ children, enabled, id, itemStyle, selectedValue, onValueChange, ...others }, ref) => {
    const [modal, setModal] = useState(false);

    const option = selectedValue
      ? children.find(({ props: { value = '' } }) => value.toString() === selectedValue.toString())
      : undefined;
    const { props: { label = '' } = {} } = option ?? (children[0] || {});

    useImperativeHandle(ref, () => ({
      focus: () => setModal(true),
      blur: () => setModal(false),
    }));

    return (
      <>
        <Pressable {...others} disabled={!enabled} onPress={() => setModal(true)}>
          <Text style={[itemStyle, style.itemIOS]}>{label}</Text>
        </Pressable>
        <Modal animationType="slide" transparent visible={modal} onRequestClose={() => setModal(false)}>
          <View style={style.containerIOS}>
            <Pressable
              style={style.pressableIconIOS}
              onPress={() => setModal(false)}
              {...testID('select-button-close')}
            >
              <Icon name="close" />
            </Pressable>
            <PickerIOS {...{ id, enabled, itemStyle, selectedValue, onValueChange }}>{children}</PickerIOS>
          </View>
        </Modal>
      </>
    );
  },
);

SelectIOS.displayName = 'SelectIOS';

export { SelectIOS };

import React from 'react';
import { Linking, Platform } from 'react-native';

import { COLOR, POINTER, useKeyboard } from '@hooks';
import { Text, Touchable } from '@primitives';

import { ROLES } from '../../primitives/Touchable/Touchable.definition';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { TextProperties } from '../../primitives/Text/Text';
import type { Target } from '../../primitives/Touchable/Touchable';
import type { ALIGN } from '@hooks';
import type { FC } from 'react';

export interface LinkProps {
  align?: ALIGN;
  color?: COLOR;
  href?: string;
  target?: Target;
  underlined?: boolean;

  onPress?: ({ href }: { href?: string }) => void;
}

export type LinkProperties = LinkProps & StylerProperties & Omit<TextProperties, 'onPress'>;

const Link: FC<LinkProperties> = ({
  align,
  children,
  color = COLOR.TEXT,
  href,
  target,
  testID,
  underlined,
  onPress,
  ...others
}) => {
  const { isKeyboardOpen, closeKeyboard } = useKeyboard();

  const hasHref = href && href.length > 0;

  const handlePress = () => {
    if (isKeyboardOpen) closeKeyboard();
    if (onPress) onPress({ href });
    if (Platform.OS !== 'web' && hasHref) Linking.openURL(href);
  };

  const hrefAttrs = hasHref && target ? { target } : undefined;

  return (
    <Touchable
      alignSelf={align}
      href={hasHref ? href : undefined}
      hrefAttrs={hrefAttrs}
      role={ROLES.link}
      testID={testID}
      onPress={handlePress}
    >
      <Text
        {...others}
        align={align}
        borderColor={underlined ? color : undefined}
        color={color}
        pointerEvents={POINTER.AUTO}
        selectable={false}
        underlined={underlined}
      >
        {children}
      </Text>
    </Touchable>
  );
};

Link.displayName = 'Link';

export { Link };

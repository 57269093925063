import React from 'react';
import StyleSheet from 'react-native-extended-stylesheet';

import { Aurora, Button, Notification, SPACE, View, useStack } from '@package';

import { getTime } from '../../src/helpers';
import { ChildrenPromo } from '../components/molecules/Notification.chapter';

const StackChildren = () => {
  const Stack = useStack();

  const commomProps = {
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    timeoutClose: 5000,
    marginBottom: SPACE.SPACE_3,
  };

  const handleStackSuccess = () => {
    Stack.success(getTime().toString(), Notification, {
      ...commomProps,
    });
  };
  const handleStackCritical = () => {
    Stack.critical(getTime().toString(), Notification, {
      ...commomProps,
    });
  };
  const handleStackWarning = () => {
    Stack.warning(getTime().toString(), Notification, {
      ...commomProps,
    });
  };
  const handleStackNeutral = () => {
    Stack.neutral(getTime().toString(), Notification, {
      ...commomProps,
    });
  };

  const handleStackPromo = () => {
    Stack.promo(getTime().toString(), Notification, {
      ...commomProps,
      children: <ChildrenPromo />,
    });
  };

  return (
    <View style={styles.container}>
      <Button style={styles.button} onPress={handleStackSuccess}>
        Notification Stack Success
      </Button>
      <Button style={styles.button} onPress={handleStackCritical}>
        Notification Stack Critical
      </Button>
      <Button style={styles.button} onPress={handleStackWarning}>
        Notification Stack Warning
      </Button>
      <Button style={styles.button} onPress={handleStackNeutral}>
        Notification Stack Neutral
      </Button>
      <Button onPress={handleStackPromo}>Notification Stack Promo</Button>
    </View>
  );
};

export const StackChapter = () => {
  return (
    <Aurora>
      <StackChildren />
    </Aurora>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    marginBottom: '$space5',
  },
});

StackChapter.displayName = '📚 Contexts/Stack';

export const LAYER_STYLESHEETS = {
  layerXS: {
    zIndex: '$elevationLayerXS',
  },

  layerS: {
    zIndex: '$elevationLayerS',
  },

  layerM: {
    zIndex: '$elevationLayerM',
  },

  layerL: {
    zIndex: '$elevationLayerL',
  },

  layerXL: {
    zIndex: '$elevationLayerXL',
  },
};

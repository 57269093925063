import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { Children } from '@mocks';
import { Overlay } from '@package';

export const OverlayChapter = () => {
  const { boolean, event, object } = useStoryteller();

  return (
    <Overlay
      isVisible={boolean('isVisible', true)}
      style={object('style', {})}
      onPress={() => event('onPress', [{ event: 'overlay pressed' }])}
    >
      <Children />
    </Overlay>
  );
};

OverlayChapter.displayName = '⚛️ Atoms/Overlay';

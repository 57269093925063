import { Platform } from 'react-native';

export const POSITION_STYLESHEETS = {
  positionAbsolute: {
    position: 'absolute',
  },

  positionFixed: {
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
  },

  positionRelative: {
    position: 'relative',
  },

  positionSticky: {
    position: 'absolute',
  },
};

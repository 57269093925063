import { ALIGN, COLOR } from '@hooks';

import { OPTION_VARIANT } from '../Option.definition';
import { style } from '../Option.style';

export interface VariantProperties {
  backgroundColor?: COLOR;
  borderColor?: COLOR;
  label?: {
    action?: boolean;
    align?: ALIGN;
    color?: COLOR;
    detail?: boolean;
    level?: 1 | 2 | 3;
    upperCase?: boolean;
  };
  style?: object[];
  styleInput?: object[];
  visibleSwitch?: boolean;
}

interface GetVariantParameters {
  checked?: boolean;
  disabled?: boolean;
  small?: boolean;
  unselected?: boolean;
}

export const getVariant = (variant: OPTION_VARIANT, others: GetVariantParameters = {}): VariantProperties => {
  const { checked, disabled, small } = others;

  const color = disabled ? COLOR.TEXT_DISABLED : COLOR.TEXT;

  const commonStyleCardAndPill: Partial<VariantProperties> = {
    backgroundColor: checked ? COLOR.BG_INTERACTIVE_SELECTED : COLOR.BG_INTERACTIVE_ACTIVE,
    label: {
      action: !small,
      align: ALIGN.CENTER,
      color: color,
      detail: small,
      level: small ? 1 : 3,
    },
    visibleSwitch: false,
  };

  switch (variant) {
    case OPTION_VARIANT.BUTTON:
      return {
        backgroundColor: COLOR.BG_BASE,
        borderColor: checked ? COLOR.BORDER_INTERACTIVE_SELECTED : COLOR.BORDER_INTERACTIVE,
        label: {
          color: color,
          detail: true,
          level: 1,
        },
        style: [style.button, checked && style.buttonChecked],
        styleInput: [style.buttonInput],
        visibleSwitch: true,
      };

    case OPTION_VARIANT.CARD:
      return {
        ...commonStyleCardAndPill,
        style: [style.card, small && style.cardSmall],
      };

    case OPTION_VARIANT.PILL:
      return {
        ...commonStyleCardAndPill,
        style: [style.pill, small && style.pillSmall],
      };

    default: {
      return {};
    }
  }
};

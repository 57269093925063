// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { createContext, memo, useContext, useLayoutEffect, useMemo, useReducer } from 'react';

import { DEFAULT_PORTAL } from './Portal.definition';
import { PORTAL_REDUCER_TYPE, PortalReducer } from './Portal.reducer';

import type { PortalAction, PortalState } from './Portal.reducer';
import type { Component, FC, ReactNode, Reducer } from 'react';

const PortalContext = createContext(DEFAULT_PORTAL);

export interface PortalContextType {
  busy: boolean;
  instance: boolean;

  mount: (id: string, component: ReactNode) => void;

  unmount: (id: string) => void;
}
const usePortal = (): PortalContextType => useContext<PortalContextType>(PortalContext);

interface PortalProviderProperties {
  children: ReactNode;
}

const PortalProviderNotMemoized: FC<PortalProviderProperties> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<PortalState[], PortalAction>>(PortalReducer, []);

  const busy = state.filter(({ component: { props: { isVisible = false } = {} } = {} }) => isVisible).length > 0;

  const value = useMemo(
    () => ({
      busy,
      instance: true,
      mount: (id: string, component: Component<{ visible: boolean }>) => {
        dispatch({ type: PORTAL_REDUCER_TYPE.MOUNT, component, id });
      },
      unmount: (id: string) => {
        dispatch({ type: PORTAL_REDUCER_TYPE.UNMOUNT, id });
      },
    }),
    [busy],
  );

  return (
    <PortalContext.Provider value={value}>
      {state.map(({ component }) => component)}
      {children}
    </PortalContext.Provider>
  );
};

const PortalProvider = memo(PortalProviderNotMemoized);

PortalProvider.displayName = 'AuroraPortalProvider';

export interface PortalProperties {
  children: ReactNode;
  id: string;
}

const PortalNotMemoized: FC<PortalProperties> = ({ children, id }) => {
  const portal = usePortal();

  useLayoutEffect(() => {
    portal.mount(id, children as ReactNode);

    return () => portal.unmount(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, id]);

  return !portal.instance ? children : <></>;
};

const Portal = memo(PortalNotMemoized);

Portal.displayName = 'AuroraPortal';

export { Portal, PortalContext, PortalProvider, usePortal };

import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { Children } from '@mocks';
import { Accordion } from '@package';

export const AccordionChapter = () => {
  const { boolean, event, object, text } = useStoryteller();

  return (
    <Accordion
      active={boolean('active', false)}
      disabled={boolean('disabled', false)}
      style={object('style', {})}
      text={text('text', 'Value field: Lorem Ipsum is simply dummy text of the printing and typesetting industry.')}
      title={text('title', 'title')}
      onPress={(active) => event('onPress', [{ active }])}
    >
      <Children />
    </Accordion>
  );
};

AccordionChapter.displayName = '⚛️ Atoms/Accordion';

import { useState } from 'react';
import { PanResponder } from 'react-native';

import { calcGesture } from './helpers';

import type { Gesture } from './helpers/calcGesture';
import type { GestureResponderEvent, GestureResponderHandlers, PanResponderGestureState } from 'react-native';

const GAP = 2;

type UseSwipeParams = {
  onSwiping: (gesture: Gesture, event: GestureResponderEvent) => void;
  onSwiped: (gesture: Gesture | undefined, event: GestureResponderEvent) => void;
};

const DEFAULT_GESTURE = {
  up: false,
  down: false,
  left: false,
  right: false,
  deltaX: 0,
  deltaY: 0,
  endX: 0,
  endY: 0,
};

export const useSwipe = ({ onSwiping, onSwiped }: UseSwipeParams = {} as UseSwipeParams): GestureResponderHandlers => {
  const [gesture, setGesture] = useState<Gesture | undefined>(undefined);

  const handleStart = (event: GestureResponderEvent, state: PanResponderGestureState) => {
    setGesture({ ...DEFAULT_GESTURE, startX: state.x0, startY: state.y0 });
  };

  const handleMove = (event: GestureResponderEvent, state: PanResponderGestureState) => {
    if (!gesture) return;

    const nextGesture = calcGesture(gesture, state);
    setGesture(() => {
      onSwiping(nextGesture, event);
      return nextGesture;
    });
  };

  const handleEnd = (event: GestureResponderEvent) => {
    setGesture(() => {
      onSwiped(gesture, event);
      return undefined;
    });
  };

  const panResponder = PanResponder.create({
    onMoveShouldSetPanResponder: (event, { dx: x, dy: y }) => !(x < GAP && x > -GAP && y < GAP && y > -GAP),
    onStartShouldSetPanResponder: () => true,
    onPanResponderGrant: handleStart,
    onPanResponderMove: handleMove,
    // onPanResponderEnd: handleEnd, // * Seems is not mandatory if we use onPanResponderRelease
    // onPanResponderTerminate: handleEnd,  // * Another component has become the responder, so this gesture should be cancelled
    onPanResponderRelease: handleEnd,
  });

  return panResponder.panHandlers;
};

import React from 'react';
import { SafeAreaView as NativeSafeAreaView } from 'react-native';

import { testID } from '@helpers';
import { useBanStylerProps, useStyler } from '@hooks';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { FC } from 'react';
import type { ViewProps } from 'react-native';

export type SafeAreaViewProperties = StylerProperties & ViewProps;

const SafeAreaView: FC<SafeAreaViewProperties> = ({ children, ...others }) => (
  <NativeSafeAreaView {...useBanStylerProps(others)} {...useStyler(others)} {...testID(others.testID)}>
    {children}
  </NativeSafeAreaView>
);

SafeAreaView.displayName = 'SafeAreaView';

export { SafeAreaView };

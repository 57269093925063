import React, { useState } from 'react';

import { Aurora, BUTTON_VARIANT, Button, COLOR, Modal, Portal, SPACE, Text, View } from '@package';

const PortalChildren = () => {
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);

  const closeModal1 = () => setModal1(false);
  const closeModal2 = () => setModal2(false);
  const openModal1 = () => setModal1(true);
  const openModal2 = () => setModal2(true);

  return (
    <View backgroundColor={COLOR.BG_ACTION_PRIMARY_DISABLED}>
      <Text margin={SPACE.SPACE_5}>
        El baile de los portales... si abres primero modal 2 y cierras, y luego abres modal 1 y desde modal 1 abres
        modal 2 (sorry por la confusión) este segundo modal se debería ver por encima de modal 1. El orden de apertura
        de los modales se debería mantener entre en la jerarquía
      </Text>

      <Portal id="portal-text">
        <View backgroundColor={COLOR.BG_SUCCESS} padding={SPACE.SPACE_5}>
          <Text>This is a Text using portal</Text>
        </View>
      </Portal>

      {modal1 && (
        <Portal id="modal-1">
          <Modal isVisible={modal1} onClose={closeModal1}>
            <View>
              <Button variant={BUTTON_VARIANT.PRIMARY} onPress={openModal2}>
                Open modal 2
              </Button>
            </View>
          </Modal>
        </Portal>
      )}
      {modal2 && (
        <Portal id="modal-2">
          <Modal isVisible={modal2} onClose={closeModal2}>
            <Button variant={BUTTON_VARIANT.PRIMARY} onPress={closeModal2}>
              Close Modal 2
            </Button>
          </Modal>
        </Portal>
      )}

      <Button variant={BUTTON_VARIANT.PRIMARY} marginBottom={SPACE.SPACE_5} onPress={openModal1}>
        open modal 1
      </Button>

      <Button variant={BUTTON_VARIANT.PRIMARY} onPress={openModal2}>
        open modal 2
      </Button>

      <Text margin={SPACE.SPACE_5}>
        lorem Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias laborum repellat nihil blanditiis
        nostrum quibusdam error esse sit iure eius voluptatibus, at autem sunt accusamus. Doloribus, iste illo?
        Distinctio, earum.
      </Text>

      <Text margin={SPACE.SPACE_5}>
        lorem Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias laborum repellat nihil blanditiis
        nostrum quibusdam error esse sit iure eius voluptatibus, at autem sunt accusamus. Doloribus, iste illo?
        Distinctio, earum.
      </Text>
    </View>
  );
};

export const PortalChapter = () => {
  return (
    <Aurora>
      <PortalChildren />
    </Aurora>
  );
};

PortalChapter.displayName = '📚 Contexts/Portal';

import React from 'react';

import { COLOR, DISPLAY, POSITION, styles } from '@hooks';
import { Icon, MOTION_TIMING, Motion, Touchable, View } from '@primitives';

import { style } from './ButtonIcon.style';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { IconName } from '../../primitives/Icon/Icon';
import type { TouchableProperties } from '../../primitives/Touchable/Touchable';
import type { FC } from 'react';

export interface ButtonIconProps {
  colorNotification?: COLOR;
  name: IconName;
  notification?: boolean;
  small?: boolean;
}

export type ButtonIconProperties = ButtonIconProps & StylerProperties & TouchableProperties;

const ButtonIcon: FC<ButtonIconProperties> = ({
  color,
  colorNotification = COLOR.ICON_ERROR,
  name,
  notification,
  small,
  ...others
}) => (
  <Touchable {...others} style={styles(style.container, small && style.small, others.style)}>
    <View position={POSITION.RELATIVE}>
      <Icon color={color} display={DISPLAY.BLOCK} name={name} />
      <Motion
        backgroundColor={colorNotification}
        borderColor={COLOR.ICON_ON_PRIMARY}
        position={POSITION.ABSOLUTE}
        style={style.notification}
        timing={MOTION_TIMING.SPRING}
        value={{ scale: notification ? 1 : 0 }}
      />
    </View>
  </Touchable>
);

ButtonIcon.displayName = 'ButtonIcon';

export { ButtonIcon };

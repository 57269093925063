// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Children } from 'react';

import { getField } from './getField';
import { BOOLEAN_TYPES } from '../Form.definition';

export const getChildrenValues = (children) => {
  const values = {};

  Children.forEach(
    children,
    ({
      props: {
        checked = false,
        defaultChecked = false,
        defaultValue = undefined,
        type = undefined,
        value = undefined,
        ...props
      } = {},
    } = {}) => {
      const field = getField(props);
      if (field) values[field] = BOOLEAN_TYPES.includes(type) ? checked || defaultChecked : value || defaultValue;
    },
  );

  return values;
};

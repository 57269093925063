import StyleSheet from 'react-native-extended-stylesheet';

import { ITEM_HEIGHT } from './WheelPicker.definition';

export const style = StyleSheet.create({
  dartBoard: {
    top: 0,
    height: ITEM_HEIGHT * 5,
  },

  selector: {
    borderWidth: 0,
    borderRadius: '$borderRadiusFull',
    borderStyle: '$borderStyle',
    height: ITEM_HEIGHT,
  },

  selectorDisabled: {
    borderWidth: '$borderRadius1',
  },

  gradient: {
    height: ITEM_HEIGHT * 2,
  },
});

import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';
import { KeyboardAvoidingView, Platform, useWindowDimensions } from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';

import {
  // Components
  Aurora,
  Button,
  Modal,
  Text,
  View,
} from '@package';

export const ModalScrollableChapter = () => {
  const { boolean, event, text } = useStoryteller();
  const { height } = useWindowDimensions();

  const style = StyleSheet.create({
    content: {
      backgroundColor: '#F5E8FB',
      borderWidth: '$borderWidth1',
      borderStyle: 'dashed',
      borderColor: '#AB00FB',
      borderRadius: '$space1',
      alignItems: 'center',
      padding: '$space5',
      marginVertical: '$space6',
    },
    title: {
      color: '#AB00FB',
    },
    textDetail: {
      paddingBottom: '$space5',
      textAlign: 'left',
    },
    textCode: {
      paddingBottom: '$space2',
    },
    containerCode: {
      backgroundColor: 'rgba(171, 0, 251, 0.1)',
      marginBottom: '$space5',
      paddingVertical: '$space2',
    },
    button: {
      flex: 0,
    },
  });

  return (
    <Aurora>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}
        enabled
        keyboardVerticalOffset={0}
        style={{ flex: 1 }}
      >
        <Modal
          description={text('description', 'Please read explanation')}
          fullHeight={boolean('fullHeight', false)}
          scrollable={boolean('scrollable', true)}
          isVisible={boolean('isVisible', true)}
          contentStyle={{ height: height * 0.75 }}
          title={text('title', 'Scrollable modal')}
          // @ts-expect-error Just for better UX in Storyteller
          onClose={() => event('onClose')}
        >
          <View style={style.content}>
            <Text style={style.title}>Content</Text>
            <Text detail level={1} style={style.textDetail}>
              Due to a bug if you have a button as last element you should set a flex: 0
            </Text>
            <Text detail level={1} style={style.textDetail}>
              This is an example of a scrollable modal with a height based on windowDimensions.
            </Text>
            <Text detail level={1} style={style.textCode}>
              When you want to have scrollable content you will have to give the modal a height in pixels, and you can
              provide it via contentStyle property. You can do it by passing a variable height based on the
              windowDimensions of the current device screen or a fixed height. Also you need to set the prop scrollable:
            </Text>

            <View style={style.containerCode}>
              <Text detail level={1}>
                {`const { height } = useWindowDimensions(); \n
              contentStyle={{ height: height * 0.75 }} \n
              scrollable={true}`}
              </Text>
            </View>

            <Text detail level={3} style={style.textDetail}>
              * Your Designer should dictate the exact percentage of the Modal content.
            </Text>
            <Text detail level={1} style={style.textDetail}>
              This screen does not look correct in Storyteller (Web Platform) but it will in a real application. This is
              because StoryTeller is mocking the viewport whereas in a real application you have a native viewport.
            </Text>
            <Text detail level={1} style={style.textDetail}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia debitis veniam rem soluta, eos ad quae
              mollitia quod id maiores assumenda. Dolorum quia illo consequuntur nostrum commodi magnam natus facere.
            </Text>
            <Button
              // @ts-expect-error Just for better UX in Storyteller
              onPress={() => event('clicked')}
              style={style.button}
            >
              Call to Action
            </Button>
          </View>
        </Modal>
      </KeyboardAvoidingView>
    </Aurora>
  );
};

ModalScrollableChapter.displayName = '✳️ Molecules/Modal Scrollable';

import { DOMAINS } from './styler.definition';

const STYLER_PROPS = [...Object.keys(DOMAINS), 'style'];

export const useBanStylerProps = (props: Record<string, any> = {}): Record<string, any> => {
  const safeProps: Record<string, any> = {};

  for (const prop in props) {
    if (!STYLER_PROPS.includes(prop)) safeProps[prop] = props[prop];
  }

  return safeProps;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { forwardRef } from 'react';
import { View as NativeView, Platform } from 'react-native';

import { testID } from '@helpers';
import { useBanProps, useBanStylerProps, useDevice, useStyler } from '@hooks';

import { BANNED_PROPS } from './View.definition';

import type { VIEW_ROLES } from './View.definition';
import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { AccessibilityRole, ViewProps as NativeViewProps, ViewComponent } from 'react-native';

export interface ViewProps {
  as?: string;
  displayName?: string;
  role?: AccessibilityRole | VIEW_ROLES;
}

export type ViewProperties = ViewProps & StylerProperties & Omit<NativeViewProps, 'role'>;

const View = forwardRef<ViewComponent, ViewProperties>(({ children, displayName = 'View', role, ...others }, ref) => (
  <NativeView
    {...useBanProps(useBanStylerProps(others), BANNED_PROPS)}
    {...useStyler(others, displayName, useDevice())}
    {...testID(others.testID)}
    accessibilityRole={Platform.OS === 'web' ? (role as AccessibilityRole) : undefined}
    pointerEvents={others.pointerEvents || others.pointer}
    ref={ref}
  >
    {children}
  </NativeView>
));

View.displayName = 'View';

export { View };

import React, { Children, cloneElement, isValidElement } from 'react';

import { FLEX_DIRECTION, POSITION, SIZE } from '@hooks';
import { VIEW_ROLES, View } from '@primitives';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { ViewProperties } from '../../primitives/View/View';
import type { FC, ReactElement } from 'react';

export interface StepContainerProps {
  active?: number;
  forceActive?: boolean;
  horizontal?: boolean;
}

export type StepContainerProperties = StepContainerProps & StylerProperties & ViewProperties;

const StepContainer: FC<StepContainerProperties> = ({
  active = 0,
  children,
  forceActive = false,
  horizontal = true,
  ...others
}) => (
  <View
    {...others}
    role={VIEW_ROLES.ul}
    position={POSITION.RELATIVE}
    flex={SIZE.XS}
    flexDirection={horizontal ? FLEX_DIRECTION.ROW : FLEX_DIRECTION.COLUMN}
  >
    {Children.map(children, (child, index) => {
      if (!isValidElement(child)) return null;

      return cloneElement(child as ReactElement, {
        ...child.props,
        index,
        active: forceActive || index <= active,
        forceActive: forceActive,
        checked: index < active,
        first: index === 0,
        horizontal: horizontal,
        last: index === Children.count(children) - 1,
      });
    })}
  </View>
);

StepContainer.displayName = 'StepContainer';

export { StepContainer };

/* eslint-disable react-hooks/rules-of-hooks */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Children, cloneElement, isValidElement } from 'react';
import { Text as BaseText, Platform } from 'react-native';

import { capitalize, stylesheetColor, stylesheetFont, testID } from '@helpers';
import {
  POINTER,
  // Methods
  styles,
  // Hooks
  useBanProps,
  useBanStylerProps,
  useDevice,
  useStyler,
} from '@hooks';

import { BANNED_PROPS } from './Text.definition';
import { style } from './Text.style';

import type { TEXT_ROLES } from './Text.definition';
import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type {
  // Cont
  ALIGN,
  COLOR,
} from '@hooks';
import type { FC } from 'react';
import type { AccessibilityRole, TextProps as BaseTextProps, ViewProps } from 'react-native';

export interface TextAccessibility {
  accessibilityHidden?: boolean;
}

export interface TextProps {
  action?: boolean;
  align?: ALIGN;
  as?: string;
  color?: COLOR;
  detail?: boolean;
  detailBold?: boolean;
  displayName?: string;
  ellipsizeMode?: boolean;
  heading?: boolean;
  hidden?: boolean;
  lineThrough?: boolean;
  pointerEvents?: POINTER;
  role?: AccessibilityRole | TEXT_ROLES;
  selectable?: boolean;
  underlined?: boolean;
}

type OmittedBaseTextProps = 'ellipsizeMode';

export type TextProperties = TextProps &
  Omit<StylerProperties, keyof ViewProps> &
  Omit<BaseTextProps, OmittedBaseTextProps> &
  TextAccessibility;

const Text: FC<TextProperties> = (props) => {
  if (isValidElement(props.children)) {
    const { children, ...clonedProps } = props;

    return cloneElement(children, clonedProps);
  }

  const {
    action,
    align,
    color,
    children = '',
    detail,
    detailBold,
    displayName = undefined,
    heading,
    hidden,
    level = 3,
    ellipsizeMode,
    lineThrough,
    role,
    selectable = false,
    underlined,
    ...others
  } = props;

  const pointerEvents =
    Platform.OS === 'ios'
      ? undefined
      : others.pointerEvents || (!selectable || Platform.OS !== 'web' ? POINTER.NONE : undefined);

  return (
    <BaseText
      {...useBanStylerProps(useBanProps(others, BANNED_PROPS))}
      {...useStyler(
        {
          ...others,
          style: styles(
            stylesheetFont({
              nameSpace: `$font${capitalize(
                heading ? 'heading' : action ? 'action' : detail ? 'detail' : detailBold ? 'detailBold' : 'body',
              )}`,
              level: level.toString(),
            }).font,
            stylesheetColor(color).color,
            hidden && style.hidden,
            lineThrough && style.lineThrough,
            underlined && style.underlined,
            align && style[align],
            others.style,
          ),
        },
        displayName,
        useDevice(),
      )}
      {...testID(others.testID)}
      {...(ellipsizeMode ? { numberOfLines: 1 } : undefined)}
      accessibilityRole={Platform.OS === 'web' ? (role as AccessibilityRole) : undefined}
      allowFontScaling={false}
      pointerEvents={pointerEvents}
      selectable={selectable}
    >
      {Array.isArray(children)
        ? Children.map(children, (child) => {
            const { ...parentProps } = props;

            return isValidElement(child) ? cloneElement(child, { ...parentProps, ...child.props }) : child;
          })
        : children}
    </BaseText>
  );
};

Text.displayName = 'Text';

export { Text };

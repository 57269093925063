import React from 'react';

import { Aurora, Box, COLOR, Text } from '@package';

import type { ScreenSize } from '../../../src/components/atoms/Box/Box';

const boxProps = { paddingVertical: 'XS', marginBottom: 'XS' };
const textProps = { paddingHorizontal: 'XS', detail: true };

export const AuroraChapter = () => {
  return (
    <Aurora>
      <Box {...boxProps} backgroundColor={COLOR.BG_PRIMARY_MEDIUM} size={'1/3' as ScreenSize}>
        <Text {...textProps}>box:1/3</Text>
      </Box>
      <Box {...boxProps} backgroundColor={COLOR.BG_INTERACTIVE_SELECTED} size={'1/2' as ScreenSize}>
        <Text {...textProps}>box:1/2</Text>
      </Box>
      <Box {...boxProps} backgroundColor={COLOR.BG_SUCCESS} size={'2/3' as ScreenSize}>
        <Text {...textProps}>box:2/3</Text>
      </Box>
    </Aurora>
  );
};

AuroraChapter.displayName = '🐵 Primitives/Aurora';

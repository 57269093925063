import { IS_JEST } from '@helpers';

import type { PortalContextType } from './Portal';

const emptyFn = () => {
  if (!IS_JEST) console.warn("WARN: Can't use the hook usePortal without an instance of <PortalProvider>");
};

const DEFAULT_PORTAL: PortalContextType = {
  busy: false,
  instance: false,
  mount: emptyFn,
  unmount: emptyFn,
};

export { DEFAULT_PORTAL };

import { Chapter, Storyteller, serializeName } from '@lookiero/storyteller';
import * as Font from 'expo-font';
import React, { useState } from 'react';
import { StatusBar } from 'react-native';

import * as chapters from '@storyteller';

import { ENV_JEST, FONTS } from './App.definition';
import PKG from './package.json';

const { repository: { url } = {}, version } = PKG;

const App = () => {
  const [ready, setReady] = useState(false);

  React.useEffect(() => {
    (async () => {
      if (!ENV_JEST) {
        await Font.loadAsync(FONTS);
      }
      setReady(true);
    })();
  }, []);

  return !ready ? (
    <></>
  ) : (
    <>
      <StatusBar />

      <Storyteller title="Aurora" version={version}>
        {Object.values(chapters).map((chapter, index) => {
          // @ts-expect-error Just for better UX in Storyteller
          const { domain = '', name: component = '' } = serializeName(chapter.displayName);
          const base = domain.split(' ')[1] || '';

          return React.createElement(
            Chapter,
            {
              doc: `${url}/blob/master/__storyteller__/${base.toLowerCase()}/${component}.chapter.jsx`,
              jsx: false,
              key: `chapter:${index}`,
              // @ts-expect-error Just for better UX in Storyteller
              title: chapter.displayName,
            },
            React.createElement(chapter),
          );
        })}
      </Storyteller>
    </>
  );
};

export default App;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Platform, TextInput } from 'react-native';

import { getInputErrors, stylesheetFont, testID } from '@helpers';
import { styles, useBanProps, useBanStylerProps, useDevice, useStyler, useSupportedProps } from '@hooks';
import { Theme } from '@theming';

import { parseValue } from './helpers';
import { BANNED_PROPS, INPUT_TYPES, KEYBOARD_TYPES, TEXT_CONTENT_TYPES, TYPE_NUMBER } from './Input.definition';
import { style } from './Input.style';
import { TEXT_STYLE } from '../Text';

import type { InputValue } from './Input.definition';
import type { InputError } from '../../../helpers/getInputErrors';
import type { StylerProperties } from '@hooks';
import type { AccessibilityRole, TextInputProps } from 'react-native';

export interface InputProps {
  defaultValue?: string | number;
  disabled?: boolean;
  id?: string;
  label?: string;
  name?: string;
  placeholderTextColor?: string;
  required?: boolean;
  role?: AccessibilityRole;
  trimSpaces?: boolean;
  type?: INPUT_TYPES;
  value?: InputValue;

  onError?: (error: InputError | undefined) => void;
  onChange?: (value: InputValue) => void;
}
export type InputProperties = InputProps & StylerProperties & Omit<TextInputProps, 'onChange'>;
const Input = forwardRef<TextInput, InputProperties>(
  (
    {
      disabled,
      id,
      keyboardType,
      multiline,
      placeholder,
      placeholderTextColor,
      role,
      type = INPUT_TYPES.text,
      onChange,
      onError,
      ...others
    },
    ref,
  ) => {
    const { colorTextMedium, space24 } = Theme.get();

    const MULTILINE_MIN_HEIGHT = space24;
    const [contentHeight, setContentHeight] = useState(MULTILINE_MIN_HEIGHT);

    const inputRef = useRef(ref);

    const isNumber = type === TYPE_NUMBER;

    useImperativeHandle(ref, () => inputRef.current);

    const handleChangeText = (next = '') => {
      if (isNumber && isNaN(next)) return;
      const value = parseValue(next, type, others);

      onError && onError(getInputErrors({ ...others, type, value }));
      onChange && onChange(isNumber ? value : value || '');
    };

    const handleContentSizeChange = ({ nativeEvent }) => {
      if (nativeEvent?.contentSize?.height > MULTILINE_MIN_HEIGHT) {
        setContentHeight(nativeEvent.contentSize.height);
      }
    };

    return (
      <TextInput
        {...useBanProps(useBanStylerProps(others), [...BANNED_PROPS])}
        {...testID(others.testID, 'input')}
        style={styles(
          stylesheetFont({ nameSpace: '$fontInput' }).font,
          style.input,
          disabled && style.disabled,
          multiline && style.multiline,
          multiline && Platform.OS === 'web' && { minHeight: contentHeight },
          ...useStyler(useSupportedProps(others, Object.values(TEXT_STYLE)), Input.displayName, useDevice()).style,
          others.style,
        )}
        accessibilityRole={Platform.OS === 'web' ? role : undefined}
        allowFontScaling={false}
        autoCapitalize="none"
        autoCorrect={true}
        editable={!disabled}
        keyboardType={keyboardType || KEYBOARD_TYPES[type] || KEYBOARD_TYPES.text}
        multiline={multiline}
        nativeID={id}
        placeholder={placeholder}
        placeholderTextColor={placeholderTextColor || colorTextMedium}
        ref={inputRef}
        secureTextEntry={type === 'password'}
        textContentType={TEXT_CONTENT_TYPES[type]}
        onChangeText={handleChangeText}
        onContentSizeChange={multiline && Platform.OS === 'web' ? handleContentSizeChange : undefined}
      />
    );
  },
);

Input.displayName = 'Input';

export { Input };

import React, { forwardRef } from 'react';

import { ALIGN, FLEX_DIRECTION, SIZE } from '@hooks';
import { Input, TEXT_ROLES, Text, View } from '@primitives';

import { style } from './Field.style';

import type { InputProperties } from '../../../../primitives/Input/Input';
import type { FC, ForwardedRef } from 'react';
import type { TextInput } from 'react-native';

export interface FieldProps {
  color?: string;
  label?: string;
}
export type FieldProperties = FieldProps & InputProperties;

const Field: FC<FieldProperties> = forwardRef(({ color, label, ...others }, ref: ForwardedRef<TextInput>) => (
  <View alignItems={ALIGN.CENTER} flex={SIZE.XS} flexDirection={FLEX_DIRECTION.ROW}>
    <Input {...others} ref={ref} />
    <Text color={color} detail level={1} role={TEXT_ROLES.small} style={style.label}>
      {label}
    </Text>
  </View>
));

Field.displayName = 'InputFieldFieldSetField';

export { Field };

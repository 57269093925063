import React from 'react';

import { UTC, getToday, toLocale } from '@helpers';
import { ALIGN, COLOR, SIZE, styles } from '@hooks';
import { Text, Touchable, View } from '@primitives';

import { DAYS } from './Calendar.definition';
import { getFirstDateOfWeek } from './helpers';
import { style } from './Week.style';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { FC } from 'react';

export interface WeekProps {
  disabledDates?: string[];
  disabledPast?: boolean;
  disabledToday?: boolean;
  from?: string;
  month: number;
  number: number;

  onPress?: (date: Date) => void;
  selected?: Date;
  to?: string;
  year: number;
}

export type WeekProperties = WeekProps & StylerProperties;

export const Week: FC<WeekProperties> = ({
  disabledDates = [],
  disabledPast = false,
  disabledToday = false,
  from,
  to,
  month,
  number,
  year,
  onPress,
  selected,
}) => {
  const disabledDatesTS = disabledDates.map((date) => UTC(new Date(date)).getTime()).filter((date) => !isNaN(date));
  const firstDate = getFirstDateOfWeek(number, year);
  const todayTS = getToday().getTime();
  const fromTS = from ? UTC(new Date(from)).getTime() : undefined;
  const toTS = to ? UTC(new Date(to)).getTime() : undefined;

  return (
    <View style={style.container}>
      {DAYS.map((day) => {
        const date = UTC(new Date(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate() + day));
        const dateTS = date.getTime();

        const isPast = disabledPast && date.getTime() < todayTS;
        const isWeekend = date.getDay() === 0 || date.getDay() === 6;
        const isDisabled =
          isPast ||
          isWeekend ||
          disabledDatesTS.includes(dateTS) ||
          (disabledToday && dateTS === todayTS) ||
          (typeof fromTS !== 'undefined' ? dateTS < fromTS : false) ||
          (typeof toTS !== 'undefined' ? dateTS > toTS : false);
        const isSelected = selected && dateTS === selected.getTime();
        const isVisible = date.getMonth() === month;
        const isTouchable = !isSelected && !isDisabled;
        const isToday = dateTS === todayTS;

        const events =
          isVisible && !isDisabled && !isSelected
            ? {
                onPress: () => {
                  if (onPress) onPress(date);
                },
              }
            : undefined;

        return (
          <Touchable
            {...events}
            alignItems={ALIGN.CENTER}
            testID={events ? `day-${toLocale(date, { day: 'numeric' })}` : undefined}
            flex={SIZE.XS}
            justifyContent={ALIGN.CENTER}
            key={day}
            tabIndex={!isDisabled ? date.getDate() : undefined}
          >
            {isVisible && (
              <View
                alignItems={ALIGN.CENTER}
                backgroundColor={isSelected ? COLOR.BG_INTERACTIVE_SELECTED : undefined}
                justifyContent={ALIGN.CENTER}
                style={styles(
                  style.day,
                  isTouchable && style.focus,
                  isToday && (isDisabled ? style.todayDisabled : style.today),
                )}
              >
                <Text
                  color={isDisabled ? COLOR.TEXT_DISABLED : COLOR.TEXT}
                  level={2}
                  selectable={false}
                  style={style.dayText}
                >
                  {toLocale(date, { day: 'numeric' })}
                </Text>
              </View>
            )}
          </Touchable>
        );
      })}
    </View>
  );
};

Week.displayName = 'Week';

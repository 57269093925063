import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { Children } from '@mocks';
import { Accordion, AccordionContainer } from '@package';

export const AccordionContainerChapter = () => {
  const { number, event, object } = useStoryteller();

  return (
    <AccordionContainer
      active={number('active')}
      style={object('style', {})}
      onPress={(index) => event('onPress', [{ index }])}
    >
      <Accordion
        title="Tu caja"
        text="Calle Miguel Servet 45. 13500. Puertollano. Calle Miguel Servet 45. 13500. Puertollano."
      >
        <Children />
      </Accordion>
      <Accordion title="Información de cuenta" text="email@ejemplo.com">
        <Children />
      </Accordion>
    </AccordionContainer>
  );
};

AccordionContainerChapter.displayName = '⚛️ Atoms/AccordionContainer';

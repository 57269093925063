export const DISPLAY_STYLESHEETS = {
  displayBlock: {},

  displayFlex: {
    display: 'flex',
  },

  displayInline: {},

  displayInlineBlock: {},

  displayNone: {
    display: 'none',
  },
};

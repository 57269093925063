import { useStoryteller } from '@lookiero/storyteller';
import React, { useState } from 'react';

import { IMAGE_URL } from '@mocks';
import { OPTION_VARIANT, Option, OptionContainer, OptionImage, OptionItem } from '@package';
import { SWITCH_VARIANT, ScrollView } from '@primitives';

import type { SPACE } from '@package';

const OPTION_IMAGE = {
  height: 92,
  src: IMAGE_URL,
  style: { flex: 1 },
  width: 92,
};

export const OptionContainerChapter = () => {
  const { boolean, event, number, select, text } = useStoryteller();

  const [option, setOption] = useState<string | string[]>('home');

  const handleChange = (value: string | string[]) => {
    event('onChange', [{ value }]);
    setOption(value);
  };

  const containerProps = {
    horizontal: boolean('horizontal', false),
    maxSelected: number('maxSelected', 0),
    multiSelection: boolean('multiSelection', false),
    gutter: select('gutter', 'space2', { options: ['space2', 'space3', 'space6'] }) as SPACE,
    marginBottom: 'space4',
    name: text('name', 'name-lookiero'),
    option: option,
    onChange: handleChange,
  };

  return (
    <ScrollView>
      <OptionContainer {...containerProps}>
        <Option variant={OPTION_VARIANT.BUTTON} name="home" value="home" title={text('Option 1', 'Home / Office')} />
        <Option variant={OPTION_VARIANT.BUTTON} name="pickup" value="pickup" title={text('Option 2', 'Pickup Point')} />
        <Option variant={OPTION_VARIANT.BUTTON} name="style" value="style" title={text('Option 3', 'Your Style')} />
      </OptionContainer>

      <OptionContainer {...containerProps}>
        <Option variant={OPTION_VARIANT.CARD} name="home" value="home" title={text('Option 1', 'Home / Office')} />
        <Option variant={OPTION_VARIANT.CARD} name="pickup" value="pickup" title={text('Option 2', 'Pickup Point')} />
        <Option variant={OPTION_VARIANT.CARD} name="style" value="style" title={text('Option 3', 'Your Style')} />
      </OptionContainer>

      <OptionContainer {...containerProps}>
        <Option variant={OPTION_VARIANT.PILL} name="home" value="home" title={text('Option 1', 'Home / Office')} />
        <Option variant={OPTION_VARIANT.PILL} name="pickup" value="pickup" title={text('Option 2', 'Pickup Point')} />
        <Option variant={OPTION_VARIANT.PILL} name="style" value="style" title={text('Option 3', 'Your Style')} />
      </OptionContainer>

      <OptionContainer {...containerProps}>
        <OptionImage {...OPTION_IMAGE} value="home" title={text('Option 1', 'Home / Office')} />
        <OptionImage {...OPTION_IMAGE} value="pickup" title={text('Option 2', 'Pickup Point')} />
        <OptionImage {...OPTION_IMAGE} value="style" title={text('Option 3', 'Your Style')} />
      </OptionContainer>

      <OptionContainer {...containerProps}>
        <OptionItem name="home" value="home" title={text('Option 1', 'Home / Office')} type={SWITCH_VARIANT.RADIO} />
        <OptionItem name="pickup" value="pickup" title={text('Option 2', 'Pickup Point')} type={SWITCH_VARIANT.RADIO} />
      </OptionContainer>
    </ScrollView>
  );
};

OptionContainerChapter.displayName = '⚛️ Atoms/OptionContainer';

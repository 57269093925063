import StyleSheet from 'react-native-extended-stylesheet';

import { DefaultTheme } from './theme.default';

import type { ThemeDefinition } from './Theme.definition';

const THEME_NAME = '$theme';
const SHORTCUT = '$';

const getStyleSheet = (key: keyof ThemeDefinition): ThemeDefinition[keyof ThemeDefinition] | undefined => {
  try {
    return StyleSheet.value(key);
  } catch (error) {
    return undefined;
  }
};

type Remove$PrefixFromKeys<T> = {
  [K in keyof T as K extends `$${infer F}` ? F : K]: T[K];
};
export type AuroraTheme = Remove$PrefixFromKeys<typeof DefaultTheme>;
type ValueOf<T> = T[keyof T];

type ThemeType = {
  get: () => AuroraTheme;
  set: (theme: Partial<ThemeDefinition>) => void;
};

export const Theme: ThemeType = {
  get: () => {
    const value: Partial<AuroraTheme> | Record<keyof AuroraTheme, ValueOf<AuroraTheme>> = {};
    Object.keys(DefaultTheme).forEach((k) => {
      const transformedKey = k as keyof ThemeDefinition;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      value[transformedKey.replace(SHORTCUT, '')] = getStyleSheet(
        transformedKey,
      ) as ThemeDefinition[keyof ThemeDefinition];
    });

    return value as AuroraTheme;
  },

  set: (theme: Partial<ThemeDefinition> = {}): void => {
    StyleSheet.build({
      ...DefaultTheme,
      ...theme,
      $theme: theme[THEME_NAME] || DefaultTheme[THEME_NAME],
    });
  },
};

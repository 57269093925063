import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { INPUT_TYPES, InputField, LAYOUT, Theme } from '@package';

import type { Glyphs } from '@lookiero/aurora-iconfont';

export const InputFieldDateChapter = () => {
  const { boolean, event, object, text, select } = useStoryteller();

  const { iconGlyphs } = Theme.get();

  return (
    <InputField
      defaultValue={text('defaultValue', '1980/04/10', { type: 'text' })}
      disabled={boolean('disabled', false)}
      error={boolean('error', false)}
      format={text('format', 'YYYY/MM/DD')}
      formatValue={text('formatValue', 'YYYY/MM/DD')}
      hint={text('hint', 'hint-lookiero')}
      icon={select('name', 'calendar', { options: Object.keys(iconGlyphs) }) as keyof Glyphs}
      id={text('id', 'id-lookiero')}
      label={text('label', 'label-lookiero')}
      margin={LAYOUT.XXS}
      // @ts-expect-error Just for better UX in Storyteller
      max={text('max', '2020/05/06')}
      min={text('min', '1970/01/01')}
      name={text('name', 'name-lookiero')}
      style={object('style', {})}
      type={INPUT_TYPES.date}
      // @ts-expect-error Just for better UX in Storyteller
      onBlur={() => event('onBlur')}
      onChange={(value) => event('onChange', [value])}
      onError={(error) => error && event('onError', [error])}
      // @ts-expect-error Just for better UX in Storyteller
      onFocus={() => event('onFocus')}
    />
  );
};

InputFieldDateChapter.displayName = '⚛️ Atoms/InputField (Date)';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useStoryteller } from '@lookiero/storyteller';
import React, { useState } from 'react';

import {
  Aurora,
  BUTTON_VARIANT,
  Button,
  COLOR,
  MOTION_TIMING,
  MOTION_TYPES,
  Motion,
  SIZE,
  Text,
  VIEW_ROLES,
} from '@package';

import type { Level } from '@package';

const typeKeys = Object.keys(MOTION_TYPES);
const timingKeys = Object.keys(MOTION_TIMING);

const BOX = 64;

const buttonProps = { margin: SIZE.M, small: true, variant: BUTTON_VARIANT.SECONDARY, wide: false };

const textProps = { margin: SIZE.S, detail: true, level: 1 as Level };

export const MotionChapter = () => {
  const { boolean, event, number, object, select, viewport } = useStoryteller();

  const [animate, setAnimate] = useState(false);

  const commonProps = {
    backgroundColor: COLOR.PRIMARY,
    delay: boolean('delay', false),
    disabled: boolean('disabled', false),
    duration: number('duration', undefined, { hint: 'Use this if you want overwrite property type.' }),
    forceFinish: boolean('forceFinish', false, {
      hint: 'Will not accept new values until the current transition is complete.',
    }),
    loops: number('loops', 1, {
      hint: 'Times the animation should loop. (use -1 for infinite).',
    }),
    role: select('role', VIEW_ROLES.button, {
      options: Object.values(VIEW_ROLES),
      hint: 'Html tag that represents the component.',
    }) as VIEW_ROLES,
    style: object('style', { height: BOX, width: BOX }),

    type: MOTION_TYPES[
      select('type', typeKeys[0], { options: typeKeys, hint: 'Sets the duration of the transition.' })
    ],
  };

  const animation = animate
    ? { translateX: viewport.width - BOX, rotate: '360deg' }
    : { translateX: 0, rotate: '0deg' };

  return (
    <Aurora>
      <Text {...textProps}>Playground</Text>
      <Motion
        {...commonProps}
        timing={
          MOTION_TIMING[
            select('timing', undefined, { options: timingKeys, hint: 'Sets the easing of the transition.' })
          ]
        }
        value={object(
          'values',
          {
            translateX: BOX / 2,
            scale: 0.5,
            rotate: '45deg',
            opacity: 0.5,
            top: 8,
            left: 8,
          },
          { hint: "Object with contains transition's next values." },
        )}
        onCancel={() => event('onCancel')}
        onFinish={() => event('onFinish')}
      />

      {timingKeys.map((key) => (
        <>
          <Text {...textProps}>timing:{key}</Text>
          <Motion timing={MOTION_TIMING[key]} {...commonProps} value={animation} />
        </>
      ))}

      <Button {...buttonProps} onPress={() => setAnimate(!animate)}>
        Animate
      </Button>
    </Aurora>
  );
};

MotionChapter.displayName = '🐵 Primitives/Motion';

import React, { forwardRef } from 'react';

import { styles, useDevice } from '@hooks';
import { View } from '@primitives';

import { style } from './Box.style';
import { determineSize } from './helpers';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { ViewProperties } from '../../primitives/View/View';
import type { ALIGN } from '@hooks';
import type { ViewComponent } from 'react-native';

export interface ScreenSize {
  L?: string;
  M?: string;
  S?: string;
  default?: string;
}

export interface BoxProps {
  align?: ALIGN.LEFT | ALIGN.CENTER | ALIGN.RIGHT;
  size?: ScreenSize;
}

export type BoxProperties = BoxProps & StylerProperties & ViewProperties;

const Box = forwardRef<ViewComponent, BoxProperties>(({ align, children, size, ...others }, ref) => {
  const { screen } = useDevice();

  return (
    <View
      {...others}
      ref={ref}
      style={styles(
        !screen.S || (size && Object.keys(size).includes('S'))
          ? // @ts-expect-error - We need better logic
            styles(style[determineSize(size, screen)], style[align])
          : undefined,
        others.style,
      )}
      wide
    >
      {children}
    </View>
  );
});

Box.displayName = 'Box';

export { Box };

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';

import { ALIGN, Text, View, useDevice } from '@package';

import type { Level } from '@package';

const property = { as: 'p', align: ALIGN.CENTER, detail: true, level: 1 as Level };
const value = { as: 'h1', heading: true, align: ALIGN.CENTER, marginBottom: 'M' };

export const useDeviceChapter = () => {
  const {
    browser,
    platform,
    screen: { height, width, ...screen },
  } = useDevice();

  return (
    <View textAlign={ALIGN.CENTER}>
      <Text {...property}>Resolution</Text>
      <Text {...value}>{`${width}x${height}`}</Text>

      <Text {...property}>Screen</Text>

      <Text {...value}>{Object.keys(screen).filter((key) => screen[key])}</Text>

      <Text {...property}>platform</Text>
      <Text {...value}>{Object.keys(platform).filter((key) => platform[key])}</Text>

      <Text {...property}>browser</Text>
      <Text {...value}>
        {Object.keys(browser)
          .filter((key) => browser[key])
          .join(' - ')}
      </Text>
    </View>
  );
};

useDeviceChapter.displayName = '🪝 Hooks/useDevice';

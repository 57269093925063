// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { Logo } from '@mocks';
import { ALIGN, ButtonIcon, COLOR, Header, Link, SIZE, SPACE, View } from '@package';

export const HeaderChapter = () => {
  const { boolean, event, object, select, text } = useStoryteller();

  const commons = {
    color: select('color', COLOR.BG_BASE, { options: Object.values(COLOR) }) as COLOR,
    marginBottom: SPACE.SPACE_4,
  };

  return (
    <View backgroundColor={COLOR.BG_PRIMARY_LIGHT} flex={SIZE.XS}>
      <Header {...commons} logo={<Logo />} />

      <Header
        {...commons}
        logo={<Logo />}
        fullWidth={boolean('fullWidth', true)}
        notification={boolean('notification', false)}
        style={object('style', {})}
        onMenu={() => event('onMenu')}
      />
      <Header {...commons} onClose={() => event('onClose')} />

      <Header {...commons} onBack={() => event('onBack')} title={text('title', 'Section Title')} />

      <Header
        {...commons}
        onBack={() => event('onBack')}
        title={text('title', 'Section Title')}
        onClose={() => event('onClose')}
      />

      <Header {...commons} logo={<Logo />} notification={boolean('notification', true)} onMenu={() => event('onMenu')}>
        <Link
          action
          align={ALIGN.CENTER}
          href={'https://lookiero.es'}
          underlined
          onPress={({ href }) => event('onPress', [href])}
          marginRight={SPACE.SPACE_4}
        >
          {'Log in'}
        </Link>
        <ButtonIcon
          alignSelf={ALIGN.CENTER}
          color={COLOR.ICON}
          name="heart"
          onPress={() => event('onHeart')}
          marginRight={SPACE.SPACE_4}
          small
        />
      </Header>
    </View>
  );
};

HeaderChapter.displayName = '✳️ Molecules/Header';

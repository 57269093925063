import { useStoryteller } from '@lookiero/storyteller';
import React, { useState } from 'react';

import { IMAGE_URL } from '@mocks';
import { BANNER_TYPE, Banner, Select } from '@package';

import type { BannerButtonProperties, BannerLinkProperties } from '@package';

type BannerKey = 'withSingleButton' | 'withDoubleButton' | 'withLink';
const BANNER_TYPES: Record<
  BannerKey,
  | { primaryButton: BannerButtonProperties }
  | { primaryButton: BannerButtonProperties; secondaryButton: BannerButtonProperties }
  | { link: BannerLinkProperties }
> = {
  withSingleButton: {
    primaryButton: {
      text: 'I am a primary button',
      onPress: () => console.log('Primary button clicked'),
    },
  },
  withDoubleButton: {
    primaryButton: {
      text: 'I am a primary button',
      icon: 'eye_close',
      onPress: () => {
        console.log('Primary button clicked');
      },
    },
    secondaryButton: {
      text: 'I am a secondary button',
      icon: 'eye_close',
      onPress: () => {
        console.log('Secondary button clicked');
      },
    },
  },
  withLink: {
    link: {
      text: 'I am a link',
      href: 'aurora-href',
      onPress: ({ href }: { href?: string }) => {
        console.log('Link clicked', href);
      },
    },
  },
};

const bannerTypeOptions = Object.keys(BANNER_TYPES);

export const BannerChapter = () => {
  const { text, boolean, select, number } = useStoryteller();

  const [bannerType, setBannerType] = useState<BannerKey>('withSingleButton');

  return (
    <>
      <Select
        options={bannerTypeOptions}
        onChange={(type) => {
          setBannerType(type as keyof typeof BANNER_TYPES);
        }}
        value={bannerType}
      />

      <Banner
        title={text('title', 'title')}
        pretitle={text('pretitle', 'pre-title')}
        description={text('description', 'Banner description. Use short and simple sentences for key messages.')}
        image={{
          src: text('src', IMAGE_URL),
          srcSet: text('srcSet', '', { multiLine: true }),
        }}
        type={select('type', BANNER_TYPE.ROUNDED, { options: Object.values(BANNER_TYPE) }) as BANNER_TYPE}
        scaled={boolean('scaled', true)}
        opacity={number('opacity', 0.5)}
        {...BANNER_TYPES[bannerType]}
      />
    </>
  );
};

BannerChapter.displayName = '✳️ Molecules/Banner';

import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  inputField: undefined,

  // components
  border: {
    borderWidth: '$borderWidth1',
    borderStyle: '$borderStyle',
    borderRadius: '$borderRadius3',
  },

  content: {
    justifyContent: 'center',
  },

  multiline: {
    height: undefined,
  },

  icon: {
    top: '$space4',
    right: '$space4',
  },

  label: {
    paddingTop: '$space1',
  },

  containerLabel: {
    left: 0,
    borderColor: 'transparent',
    borderStyle: '$borderStyle',
    borderRightWidth: '$space1',
    borderLeftWidth: '$space1',
  },

  // states
  empty: undefined,
  focus: undefined,
  error: undefined,
  filled: undefined,
  disabled: undefined,

  // NATIVE environment
  contentEmpty: {
    opacity: 0,
  },

  labelEmpty: {
    borderRightWidth: 0,
    borderLeftWidth: 0,
    height: 'auto',
    paddingRight: '$space5',
  },
});

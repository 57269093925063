import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  containerBase: {
    borderRadius: '$borderRadius3',
  },

  overlay: {
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    backgroundColor: '$colorBgOpacityLight',
  },

  inputOption: {
    right: 0,
    top: 0,
    flex: 1,
    position: 'absolute',
    padding: '$space2',
  },
});

const BANNED_PROPS = [
  'aria-controls',
  'aria-describedby',
  'aria-disabled',
  'aria-expanded',
  'aria-labelledby',
  'aria-modal',
  'aria-valuemax',
  'aria-valuemin',
  'aria-valuenow',
  'as',
  'disabled',
  'format',
  'formatValue',
  'htmlFor',
  'id',
  'onKeyUp',
  'style',
  'tabIndex',
];

enum VIEW_ROLES {
  article = 'article',
  aside = 'aside',
  button = 'button',
  div = 'div',
  figure = 'figure',
  footer = 'footer',
  form = 'form',
  header = 'header',
  li = 'li',
  nav = 'nav',
  ol = 'ol',
  section = 'section',
  table = 'table',
  tbody = 'tbody',
  td = 'td',
  th = 'th',
  thead = 'thead',
  tr = 'tr',
  ul = 'ul',
}

export { BANNED_PROPS, VIEW_ROLES };

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useRef } from 'react';

import { Thumbnail } from '@atoms';
import { KEYBOARD } from '@helpers';
import { styles, useDevice } from '@hooks';
import { TEXT_ROLES, Text, Touchable } from '@primitives';

import { InputFile } from '../InputFile';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { IconName } from '../../primitives/Icon/Icon';
import type { TouchableProperties } from '../../primitives/Touchable/Touchable';
import type { RefProperties } from '../InputFile/InputFile';
import type { FC, KeyboardEvent } from 'react';

export interface InputImageProps {
  height?: number;
  icon?: IconName;
  id: string;
  label: string;

  onChange?: (file: File) => void;
  src?: string;
  sourcesMessages?: {
    chooseFromLibrary: string;
    takePhoto: string;
    title: string;
  };
  width?: number;
}

export type InputImageProperties = InputImageProps & StylerProperties & TouchableProperties;

const InputImage: FC<InputImageProperties> = ({
  height,
  icon = 'expand',
  id,
  label,
  src,
  width,
  onChange,
  sourcesMessages,
  ...others
}) => {
  const refInputFile = useRef<RefProperties>(null);
  const { platform } = useDevice();

  useEffect(() => {
    if (refInputFile.current) refInputFile.current.value = '';
  }, [src]);

  const handleKeyUp = (event: KeyboardEvent) => {
    if (refInputFile.current && (event.keyCode === KEYBOARD.ESPACE || event.keyCode === KEYBOARD.RETURN)) {
      refInputFile.current.press();
    } else {
      event.preventDefault();
    }
  };

  const handlePress = () => {
    if (refInputFile.current) refInputFile.current.press();
  };

  return (
    <Touchable {...others} aria-controls={id} onKeyUp={handleKeyUp} onPress={handlePress}>
      <Thumbnail {...{ height, icon, width, style: styles(others.style) }} src={src} />
      {label && (
        <Text role={TEXT_ROLES.label} hidden>
          {label}
        </Text>
      )}
      <InputFile
        accept="image/*"
        nativeID={id}
        forwardedRef={refInputFile}
        hidden
        onChange={onChange}
        {...(platform.native && { sourcesMessages })}
      />
    </Touchable>
  );
};

InputImage.displayName = 'InputImage';

export { InputImage };

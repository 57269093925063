import React from 'react';

import { ALIGN, COLOR, FLEX_DIRECTION, SIZE, SPACE, styles, useId } from '@hooks';
import { SWITCH_VARIANT, TEXT_ROLES, Text, Touchable, View } from '@primitives';

import { style } from './OptionItem.style';
import { InputOption } from '../InputOption';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { TouchableProperties } from '../../primitives/Touchable/Touchable';
import type { FC } from 'react';
import type { StyleProp, ViewStyle } from 'react-native';

export interface OptionItemProps {
  checked?: boolean;
  detail?: string;
  detailStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
  name: string;
  outlined?: boolean;
  text?: string;
  title: string;
  type?: SWITCH_VARIANT;
  unselected?: boolean;
  value: string;

  onChange?: (value: string) => void;
}
export type OptionItemProperties = OptionItemProps & StylerProperties & TouchableProperties;
const OptionItem: FC<OptionItemProperties> = ({
  checked,
  detail,
  detailStyle,
  disabled,
  name,
  outlined = true,
  text,
  title,
  type = SWITCH_VARIANT.RADIO,
  unselected,
  value,
  onChange,
  ...others
}) => {
  const uid = useId();

  const handleChange = () => {
    if (onChange) onChange(value);
  };

  const colorText = disabled ? COLOR.TEXT_DISABLED : COLOR.TEXT;
  const hasBorder = outlined && !unselected && checked;

  return (
    <Touchable
      {...others}
      borderColor={hasBorder ? (disabled ? COLOR.BORDER_INTERACTIVE : COLOR.BORDER_INPUT_FOCUS) : undefined}
      backgroundColor={COLOR.BG_BASE}
      onPress={!disabled ? handleChange : undefined}
      style={styles(style.item, !hasBorder && style.itemWithoutBorder, others.style)}
      wide
    >
      <InputOption
        aria-describedby={`${uid}_text ${uid}_detail`}
        checked={!unselected ? checked : false}
        disabled={disabled}
        id={uid}
        marginRight={SPACE.SPACE_4}
        name={name}
        type={type}
        value={value}
      />

      <View flexDirection={FLEX_DIRECTION.COLUMN} flex={SIZE.XS} style={style.content}>
        <Text action role={TEXT_ROLES.label} color={colorText} ellipsizeMode accessibilityLabelledBy={uid}>
          {title}
        </Text>

        {!!text && (
          <Text detail level={1} color={COLOR.TEXT_MEDIUM} ellipsizeMode nativeID={`${uid}_text`}>
            {text}
          </Text>
        )}
      </View>

      {!!detail && (
        <Text
          align={ALIGN.CENTER}
          color={colorText}
          detail
          nativeID={`${uid}_detail`}
          style={[style.detail, detailStyle]}
        >
          {detail}
        </Text>
      )}
    </Touchable>
  );
};

OptionItem.displayName = 'OptionItem';

export { OptionItem };

enum INFOBOX_VARIANT {
  DEFAULT = 'default',
  PROMO = 'promo',
  WARNING = 'warning',
}

enum INFOBOX_TYPE {
  ROUNDED = 'rounded',
  SQUARED = 'squared',
}

export { INFOBOX_VARIANT, INFOBOX_TYPE };

import React, { Children, cloneElement, useState } from 'react';

import { ButtonIcon } from '@atoms';
import { capitalize } from '@helpers';
import { ALIGN, COLOR, FLEX_DIRECTION, POINTER, POSITION, SIZE, SPACE, styles, useId } from '@hooks';
import { Icon, MOTION_TIMING, Motion, TEXT_ROLES, Text, VIEW_ROLES, View } from '@primitives';

import { style } from './ToolTip.style';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { IconName } from '../../primitives/Icon/Icon';
import type { ViewProperties } from '../../primitives/View/View';
import type { FC, ReactElement } from 'react';

export interface ToolTipProps {
  align?: ALIGN.TOP | ALIGN.BOTTOM;
  alignArrow?: ALIGN.LEFT | ALIGN.CENTER | ALIGN.RIGHT;
  color?: COLOR;
  colorText?: COLOR;
  icon?: IconName;
  isVisible?: boolean;
  shadow?: boolean;
  text: string;
  title?: string;
}

export type ToolTipProperties = ToolTipProps & StylerProperties & ViewProperties;

export const ToolTip: FC<ToolTipProperties> = ({
  align = ALIGN.TOP,
  alignArrow = ALIGN.CENTER,
  color = COLOR.BG_BASE,
  colorText = COLOR.TEXT,
  shadow,
  text,
  title,
  children,
  icon,
  isVisible = true,
  ...others
}) => {
  const id = useId();

  const [visible, setVisible] = useState(isVisible);

  const toggleVisible = () => {
    setVisible(!visible);
  };

  return (
    <View {...others} layer={SIZE.M} style={styles(others.style)}>
      <Motion
        pointer={!visible ? POINTER.NONE : undefined}
        position={POSITION.ABSOLUTE}
        role={VIEW_ROLES.section}
        style={styles(style.toolTip, style[align], others.style)}
        // @ts-expect-error We sgould review this
        tabIndex={0}
        timing={MOTION_TIMING.SPRING}
        value={{
          opacity: visible ? 1 : 0,
          translateY: visible ? `${align === 'top' ? '-' : ''}1rem` : 0,
        }}
        wide
      >
        <View backgroundColor={color} style={[style.container, shadow && style.containerShadow]} wide>
          <View flexDirection={FLEX_DIRECTION.ROW}>
            {icon && <Icon name={icon} color={colorText} marginRight={SPACE.SPACE_2} />}
            <View flex={SIZE.XS} flexDirection={FLEX_DIRECTION.COLUMN}>
              {title && (
                <Text role={TEXT_ROLES.strong} color={colorText} detailBold level={1} marginBottom={SPACE.SPACE_05}>
                  {title}
                </Text>
              )}
              <Text color={colorText} detail level={2} marginRight={SPACE.SPACE_2}>
                {text}
              </Text>
            </View>
            <ButtonIcon
              color={colorText}
              layer={SIZE.L}
              name="close_small"
              style={style.buttonIcon}
              onPress={toggleVisible}
              small
            />
          </View>
        </View>
        <View
          backgroundColor={color}
          pointer={POINTER.NONE}
          position={POSITION.ABSOLUTE}
          style={styles(style.arrow, style[`arrow${capitalize(align)}`], style[`arrow${capitalize(alignArrow)}`])}
        />
      </Motion>
      {Children.map(children, (child) =>
        cloneElement(child as ReactElement, {
          onPress: toggleVisible,
          'aria-describedby': id,
        }),
      )}
    </View>
  );
};

ToolTip.displayName = 'ToolTip';

import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { ALIGN, INFOBOX_TYPE, INFOBOX_VARIANT, InfoBox, SPACE, Text, Theme, View } from '@package';

import type { Glyphs } from '@lookiero/aurora-iconfont';

export const InfoBoxChapter = () => {
  const { select, object, text } = useStoryteller();

  const { iconGlyphs } = Theme.get();

  const props = {
    contentAlign: select('contentAlign', ALIGN.LEFT, {
      options: [ALIGN.LEFT, ALIGN.CENTER],
    }) as ALIGN.CENTER | ALIGN.LEFT | undefined,
    icon: select('name', 'info', { options: Object.keys(iconGlyphs) }) as keyof Glyphs,
    text: text(
      'text',
      'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea optio nemo repudiandae eaque. Soluta eius suscipit!',
    ),
  };

  return (
    <View marginTop={SPACE.SPACE_12}>
      <InfoBox {...props} title={'Default InfoBox'} style={object('style', {})} />
      <InfoBox
        {...props}
        text={'Type Squared, Variant Default'}
        type={INFOBOX_TYPE.SQUARED}
        marginTop={SPACE.SPACE_8}
      />
      <InfoBox
        {...props}
        text={'Type Squared, Variant Default'}
        type={INFOBOX_TYPE.SQUARED}
        marginTop={SPACE.SPACE_8}
      />
      <InfoBox
        {...props}
        title={'Type Squared, Variant Default'}
        text={'Type Squared, Variant Default'}
        type={INFOBOX_TYPE.SQUARED}
        marginTop={SPACE.SPACE_8}
      >
        <Text>Children text. Space1 marginTop</Text>
      </InfoBox>
      <InfoBox
        {...props}
        text={'Variant Promo, Type Rounded'}
        variant={INFOBOX_VARIANT.PROMO}
        style={object('style', {})}
        marginTop={SPACE.SPACE_8}
      />
      <InfoBox
        {...props}
        title={'Variant Promo, Type Squared'}
        type={INFOBOX_TYPE.SQUARED}
        variant={INFOBOX_VARIANT.PROMO}
        style={object('style', {})}
        marginTop={SPACE.SPACE_8}
      />
      <InfoBox
        {...props}
        title={'Type Squared, Variant Warning'}
        text={'Variant Warning, Type Squared'}
        type={INFOBOX_TYPE.SQUARED}
        variant={INFOBOX_VARIANT.WARNING}
        style={object('style', {})}
        marginTop={SPACE.SPACE_8}
      />
    </View>
  );
};

InfoBoxChapter.displayName = '⚛️ Atoms/InfoBox';

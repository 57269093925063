import { StyleSheet } from 'react-native';

import { styler } from './styler';
import styles from './useStyler.style';
import { useBanProps } from '../useBanProps';

import type { StylerProperties } from './styler.definition';
import type { Device } from '../useDevice/useDevice';
import type { ImageStyle, TextStyle, ViewStyle } from 'react-native';

type StyleParameter = { style?: unknown } & StylerProperties;

type Styler = (
  style: StyleParameter,
  displayName?: string,
  device?: Device,
) => { style: (ViewStyle | TextStyle | ImageStyle)[] };

export const useStyler: Styler = ({ style, ...props } = { style: undefined }, _displayName = '', device) => {
  const values = styler(useBanProps(props, ['pointer']), device?.screen).map((key) => styles[key]);

  if (style) values.push(...(Array.isArray(style) ? style : [style]));

  const stylesheets: (ViewStyle | TextStyle | ImageStyle)[] = values.filter((value) => typeof value !== 'object');

  const objectStyle = StyleSheet.flatten(values.filter((value) => typeof value === 'object'));
  if (Object.keys(objectStyle).length > 0) stylesheets.push(objectStyle);

  return {
    style: stylesheets,
  };
};

import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  tableRow: {
    flexDirection: 'row',
  },

  row: {
    borderBottomWidth: '$borderWidth1',
    borderStyle: '$borderStyle',
    flex: 1,
    paddingVertical: '$space4',
  },

  th: {
    borderBottomColor: '$colorBorderInput',
  },

  td: {
    borderBottomColor: '$colorBorderInputFocus',
  },
});

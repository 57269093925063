import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  hidden: {
    display: 'none',
  },

  lineThrough: {
    textDecorationLine: 'line-through',
  },

  underlined: {
    textDecorationLine: 'underline',
  },

  left: {
    textAlign: 'left',
  },

  center: {
    textAlign: 'center',
  },

  right: {
    textAlign: 'right',
  },
});

import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { SWITCH_VARIANT, Switch } from '@package';

export const SwitchChapter = () => {
  const { boolean, event, select, text } = useStoryteller();

  return (
    <Switch
      checked={boolean('checked', false)}
      disabled={boolean('disabled', false)}
      id={text('id', '1')}
      name={text('name', 'example')}
      value={text('value', 'example')}
      // @ts-expect-error Just for better UX in Storyteller
      variant={SWITCH_VARIANT[select('variant', SWITCH_VARIANT.CHECKBOX, { options: Object.keys(SWITCH_VARIANT) })]}
      // @ts-expect-error Just for better UX in Storyteller
      onChange={(value) => event('onChange', value)}
    />
  );
};

SwitchChapter.displayName = '🐵 Primitives/Switch';

export type GestureEvent = {
  moveX?: number;
  moveY?: number;
};

export type Coordinates = {
  X: number;
  Y: number;
};

export const getCoords = ({ moveX = 0, moveY = 0 }: GestureEvent = {}): Coordinates => ({
  X: parseInt(moveX.toString(), 10),
  Y: parseInt(moveY.toString(), 10),
});

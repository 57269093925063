import type { PhotoResult } from './helpers/pickImage';

const BANNED_PROPS = ['id', 'style', 'label', 'capture'];

export interface InputFileModalProps {
  onFileSelect: (photo: PhotoResult) => void;
  sourcesMessages?: {
    chooseFromLibrary: string;
    takePhoto: string;
    title: string;
  };
  onClose: () => void;
}

export { BANNED_PROPS };

import StyleSheet from 'react-native-extended-stylesheet';

import { DefaultTheme } from '@theming';

StyleSheet.build(DefaultTheme);

export const style = StyleSheet.create({
  container: {
    backgroundColor: '$colorBgBase',
    flex: 1,
  },
});

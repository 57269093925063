import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { InputField, LAYOUT } from '@package';

import type { Fields } from '../../../src/components/atoms/InputField/variants/Fieldset/Fieldset';

export const InputFieldFieldsetChapter = () => {
  const { boolean, event, object, text } = useStoryteller();

  return (
    <InputField
      // @ts-expect-error Just for better UX in Storyteller
      defaultValue={object(
        'defaultValue',
        { ft: 5.91, in: 12.23 },
        {
          hint: 'You can pass { } to test an empty value (and refresh)',
        },
      )}
      disabled={boolean('disabled', false)}
      error={boolean('error', false)}
      fieldset={object('fieldset', { ft: 'number', in: 'number' }) as Fields}
      hint={text('hint', 'hint-lookiero')}
      id={text('id', 'id-lookiero')}
      label={text('label', 'label-lookiero')}
      margin={LAYOUT.XXS}
      name={text('name', 'name-lookiero')}
      style={object('style', {})}
      test={({ ft }: { ft: number }) => ft >= 5}
      // @ts-expect-error Just for better UX in Storyteller
      onBlur={() => event('onBlur')}
      onChange={(value) => event('onChange', [value])}
      onError={(error) => error && event('onError', [{ error }])}
      // @ts-expect-error Just for better UX in Storyteller
      onFocus={() => event('onFocus')}
    />
  );
};

InputFieldFieldsetChapter.displayName = '⚛️ Atoms/InputField (Fieldset)';

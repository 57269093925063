import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { ALIGN, Aurora, Box, COLOR, Text } from '@package';

import type { ScreenSize } from '../../../src/components/atoms/Box/Box';

const TEXT = { paddingHorizontal: 'XS', detail: true };

export const BoxChapter = () => {
  const { object } = useStoryteller();

  const BOX = { paddingVertical: 'XS', marginBottom: 'XS', style: object('style', {}) };

  return (
    <Aurora>
      <Text detail marginVertical="S">
        Size
      </Text>
      <Box {...BOX} backgroundColor={COLOR.BG_SUCCESS} size={'1/4' as ScreenSize}>
        <Text {...TEXT}>1/4</Text>
      </Box>
      <Box {...BOX} backgroundColor={COLOR.BG_SURFACE} size={'1/3' as ScreenSize}>
        <Text {...TEXT}>1/3</Text>
      </Box>
      <Box {...BOX} backgroundColor={COLOR.BG_INTERACTIVE_SELECTED} size={'1/2' as ScreenSize}>
        <Text {...TEXT}>1/2</Text>
      </Box>
      <Box {...BOX} backgroundColor={COLOR.BG_WARNING} size={'2/3' as ScreenSize}>
        <Text {...TEXT}>2/3</Text>
      </Box>

      <Text detail marginVertical="S">
        Align LEFT
      </Text>
      <Box {...BOX} backgroundColor={COLOR.BG_SUCCESS} align={ALIGN.LEFT} size={'1/4' as ScreenSize}>
        <Text {...TEXT}>1/4</Text>
      </Box>

      <Text detail marginVertical="S">
        Align CENTER
      </Text>
      <Box {...BOX} backgroundColor={COLOR.BG_SUCCESS} align={ALIGN.CENTER} size={'1/4' as ScreenSize}>
        <Text {...TEXT}>1/4</Text>
      </Box>

      <Text detail marginVertical="S">
        Align RIGHT
      </Text>
      <Box {...BOX} backgroundColor={COLOR.BG_SUCCESS} align={ALIGN.RIGHT} size={'1/4' as ScreenSize}>
        <Text {...TEXT}>1/4</Text>
      </Box>
    </Aurora>
  );
};

BoxChapter.displayName = '⚛️ Atoms/Box';

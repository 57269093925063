import React, { Children, cloneElement, useEffect, useState } from 'react';

import { View } from '@primitives';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { ViewProperties } from '../../primitives/View/View';
import type { FC } from 'react';

export interface AccordionContainerProps {
  active?: number;

  onPress?: (index: number) => void;
}

export type AccordionContainerProperties = AccordionContainerProps & StylerProperties & ViewProperties;

const AccordionContainer: FC<AccordionContainerProperties> = ({ children, onPress, ...others }) => {
  const [active, setActive] = useState(others.active);

  useEffect(() => {
    setActive(others.active);
  }, [others.active]);

  const handlePress = (index: number) => {
    setActive(() => {
      onPress && onPress(index);
      return active !== index ? index : undefined;
    });
  };

  return (
    <View {...others} wide>
      {Children.map(children, (child, index) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        cloneElement(child, {
          active: index === active,
          key: index,
          onPress: () => handlePress(index),
        }),
      )}
    </View>
  );
};

AccordionContainer.displayName = 'AccordionContainer';

export { AccordionContainer };

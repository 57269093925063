import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: '$space2',
    marginBottom: '$space6',
  },

  month: {
    paddingTop: '$space1',
    flex: 1,
  },

  icon: {
    marginLeft: '$space4',
  },

  daysContainer: {
    flexDirection: 'row',
    marginBottom: '$space2',
  },

  day: {
    flex: 1,
    paddingHorizontal: '$space1',
    paddingTop: '$space1',
    textAlign: 'center',
  },
});

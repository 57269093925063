const BANNED_PROPS = ['as', 'href', 'htmlFor', 'id', 'style'];

enum TEXT_STYLE {
  color = 'color',
  fontFamily = 'fontFamily',
  fontSize = 'fontSize',
  fontStyle = 'fontStyle',
  fontWeight = 'fontWeight',
  letterSpacing = 'letterSpacing',
  lineHeight = 'lineHeight',
  textAlign = 'textAlign',
  textDecorationLine = 'textDecorationLine',
  textDecorationStyle = 'textDecorationStyle',
  textDecorationColor = 'textDecorationColor',
  textShadowColor = 'textShadowColor',
  textShadowOffset = 'textShadowOffset',
  textShadowRadius = 'textShadowRadius',
  textTransform = 'textTransform',
}

enum TEXT_ROLES {
  a = 'a',
  abbr = 'abbr',
  address = 'address',
  blockquote = 'blockquote',
  caption = 'caption',
  cite = 'cite',
  bdo = 'bdo',
  del = 'del',
  em = 'em',
  figcaption = 'figcaption',
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  ins = 'ins',
  label = 'label',
  legend = 'legend',
  mark = 'mark',
  option = 'option',
  optgroup = 'optgroup',
  i = 'i',
  p = 'p',
  q = 'q',
  small = 'small',
  span = 'span',
  strong = 'strong',
  sub = 'sub',
  sup = 'sup',
  th = 'th',
  td = 'td',
}

export { BANNED_PROPS, TEXT_STYLE, TEXT_ROLES };

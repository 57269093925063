import { Platform } from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  container: {
    backgroundColor: '$colorBgBase',
    padding: '$space6',
    borderTopLeftRadius: '$borderRadius5',
    borderTopRightRadius: '$borderRadius5',
  },

  containerBorderBottomRadius: {
    ...Platform.select({
      web: {
        borderBottomLeftRadius: '$borderRadius5',
        borderBottomRightRadius: '$borderRadius5',
      },
    }),
  },

  buttonContainer: {
    marginBottom: '$space6',
  },

  buttonContainerFullWidth: {
    paddingLeft: '$space6',
  },

  fullHeight: {
    height: '94%',
    flex: 1,
    flexDirection: 'column',
  },

  fullWidth: {
    paddingHorizontal: 0,
  },

  modal: {
    bottom: 0,
    height: '100%',
    left: 0,
    top: 0,
    right: 0,
    overflow: 'hidden',
  },
});

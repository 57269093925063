import React from 'react';

import { COLOR, FLEX_DIRECTION, SIZE, SPACE } from '@hooks';
import { Touchable, View } from '@primitives';

import { style } from './Progress.style';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { ViewProperties } from '../../primitives/View/View';
import type { FC } from 'react';
export interface ProgressProps {
  active?: number;
  activeColor?: COLOR;
  inactiveColor?: COLOR;
  length?: number;

  onPress?: (index: number) => void;
}

export type ProgressProperties = ProgressProps & StylerProperties & ViewProperties;

const Progress: FC<ProgressProperties> = ({
  active = 0,
  activeColor = COLOR.BG_PRIMARY,
  inactiveColor = COLOR.BG_PRIMARY_MEDIUM,
  length = 1,
  onPress,
  ...others
}) => (
  <View {...others} flexDirection={FLEX_DIRECTION.ROW} wide>
    {Array.from(Array(length).keys()).map((step, index) => (
      <Touchable
        backgroundColor={index <= active ? activeColor : inactiveColor}
        flex={SIZE.XS}
        hitSlop={{ top: 16, bottom: 16 }}
        key={step}
        marginRight={index < length - 1 ? SPACE.SPACE_1 : undefined}
        style={style.item}
        onPress={onPress ? () => onPress(index) : undefined}
      />
    ))}
  </View>
);

Progress.displayName = 'Progress';

export { Progress };

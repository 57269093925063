import { useEffect } from 'react';
import { BackHandler } from 'react-native';

export const useBack = (callback: () => boolean | null | undefined) => {
  useEffect(() => {
    const subscription = BackHandler.addEventListener('hardwareBackPress', callback);

    return subscription.remove;
  }, [callback]);
};

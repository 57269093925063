import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { Aurora, InputFile, Text, useDevice } from '@package';

export const InputFileChapter = () => {
  const { boolean, event, object, select } = useStoryteller();
  const { platform } = useDevice();

  return (
    <Aurora>
      <InputFile
        accept={select('accept', 'image/*', {
          options: ['.jpg', '.pdf', '.doc, .docx', 'audio/*', 'video/*', 'image/*'],
        })}
        disabled={boolean('disabled', false)}
        hidden={boolean('hidden', false)}
        multiple={boolean('multiple', false)}
        style={object('style', { marginTop: platform.android ? 50 : 0 })}
        onChange={(e) => event('onChange', [{ e }])}
        onError={() => event('onError', [])}
        {...(platform.native && {
          sourcesMessages: {
            title: 'Add a photo',
            takePhoto: 'Take a picture',
            chooseFromLibrary: 'Choose a picture',
          },
        })}
      >
        <Text>Touch here..</Text>
      </InputFile>
    </Aurora>
  );
};

InputFileChapter.displayName = '🧬 Organisms/InputFile';

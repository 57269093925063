// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import {
  BUTTON_VARIANT,
  Button,
  COLOR,
  MOTION_TIMING,
  NOTIFICATION_VARIANT,
  Notification,
  SIZE,
  SPACE,
  View,
} from '@package';

export const ChildrenPromo = () => {
  return (
    // eslint-disable-next-line react-native/no-inline-styles
    <View style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}>
      <Button small variant={BUTTON_VARIANT.PRIMARY_LIGHT} wide={false}>
        {'Vamos de Rebajas'}
      </Button>
    </View>
  );
};

export const NotificationChapter = () => {
  const { boolean, event, number, select } = useStoryteller();

  const props = {
    isVisible: boolean('isVisible', true),
    motion: select('motion', 'top', { options: ['top', 'bottom', 'pop'] }),
    timeoutClose: number('timeoutClose', 0),
    timing: MOTION_TIMING[select('timing', undefined, { options: Object.keys(MOTION_TIMING) })],
    onClose: () => event(' onClose'),
  };

  return (
    <View backgroundColor={COLOR.BG_BASE} flex={SIZE.XS} paddingHorizontal={SPACE.SPACE_4}>
      <Notification
        {...props}
        text={'This is a success alert.'}
        variant={NOTIFICATION_VARIANT.SUCCESS}
        marginBottom={SPACE.SPACE_4}
      />
      <Notification
        {...props}
        text={'This is a success alert. This is a success alert. This is a success alert. This is a success alert.'}
        variant={NOTIFICATION_VARIANT.SUCCESS}
        marginBottom={SPACE.SPACE_4}
      />

      <Notification
        {...props}
        text={'This is a critical alert.'}
        marginBottom={SPACE.SPACE_4}
        variant={NOTIFICATION_VARIANT.CRITICAL}
      />

      <Notification
        {...props}
        text={'This is a warning alert.'}
        marginBottom={SPACE.SPACE_4}
        variant={NOTIFICATION_VARIANT.WARNING}
      />

      <Notification
        {...props}
        text={'This is a neutral alert.'}
        marginBottom={SPACE.SPACE_4}
        variant={NOTIFICATION_VARIANT.NEUTRAL}
      />

      <Notification
        {...props}
        text={'This is a promo.'}
        marginBottom={SPACE.SPACE_4}
        variant={NOTIFICATION_VARIANT.PROMO}
      />
      <Notification
        {...props}
        color={COLOR.BG_INFO_CARIBE}
        colorText={COLOR.TEXT_PROMOTION}
        text={'Las REBAJAS que mejor te quedan. Hasta un -50% en prendas 100% como tú.'}
        marginBottom={SPACE.SPACE_4}
        variant={NOTIFICATION_VARIANT.PROMO}
      >
        <ChildrenPromo />
      </Notification>
    </View>
  );
};

NotificationChapter.displayName = '✳️ Molecules/Notification';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useStoryteller } from '@lookiero/storyteller';
import React, { useState } from 'react';

import { Select } from '@package';

const options = [
  { name: 'Option:1', value: '1' },
  { name: 'Option:2', value: '2' },
  { name: 'Option:3', value: '3' },
  { name: 'Option:4', value: '4' },
  { name: 'Option:5', value: '5' },
  { name: 'Option:6', value: '6' },
];

const flatOptions = ['Flat:1', 'Flat:2', 'Flat:3', 'Flat:4', 'Flat:5', 'Flat:6'];

export const SelectChapter = () => {
  const { boolean, event, object, text } = useStoryteller();

  const [value, setValue] = useState();
  const [valueFlat, setValueFlat] = useState();

  return (
    <>
      <Select
        disabled={boolean('disabled', false)}
        id={text('id', 'id')}
        emptyOptionText={text('emptyOptionText', undefined)}
        options={options}
        style={object('style', {})}
        value={text('value', '3')}
        onChange={(nextValue) => event('onChange', nextValue)}
      />
      <Select
        disabled={boolean('disabled', false)}
        id={text('id', 'id')}
        emptyOptionText={text('flat.emptyOptionText', undefined)}
        options={flatOptions}
        style={object('style', {})}
        value={text('flat.value', flatOptions[2])}
        onChange={(nextValue) => event('onChange', nextValue)}
      />
      <Select
        disabled={boolean('disabled', false)}
        emptyOptionText={text('emptyOptionText', 'Select...')}
        options={options}
        style={object('style', {})}
        value={value}
        onChange={(nextValue) => {
          setValue(nextValue);
          event('onChange', nextValue);
        }}
      />
      <Select
        disabled={boolean('disabled', false)}
        emptyOptionText={text('flat.emptyOptionText', 'Select...')}
        options={flatOptions}
        style={object('style', {})}
        value={valueFlat}
        onChange={(nextValue) => {
          setValueFlat(nextValue);
          event('onChange', nextValue);
        }}
      />
    </>
  );
};

SelectChapter.displayName = '🐵 Primitives/Select';

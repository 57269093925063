import React from 'react';

import { ALIGN, COLOR, DISPLAY, FLEX_DIRECTION, POSITION, styles } from '@hooks';
import { TEXT_ROLES, Text, VIEW_ROLES, View } from '@primitives';

import { style as buildStyle } from './Step.style';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { ViewProperties } from '../../primitives/View/View';
import type { FC } from 'react';

export interface StepProps {
  active?: boolean;
  checked?: boolean;
  first?: boolean;
  forceActive?: boolean;
  horizontal?: boolean;
  last?: boolean;
  index?: number;
  text: string;
}

export type StepProperties = StepProps & StylerProperties & ViewProperties;

const Step: FC<StepProperties> = ({
  active,
  checked,
  first,
  forceActive,
  horizontal = true,
  last,
  index = 0,
  text,
  ...others
}) => {
  const style = buildStyle(horizontal);
  const indexNumber = (index + 1).toString();

  return (
    <View
      {...others}
      alignItems={ALIGN.CENTER}
      flexDirection={horizontal ? FLEX_DIRECTION.COLUMN : FLEX_DIRECTION.ROW}
      role={VIEW_ROLES.li}
      style={styles(style.step, horizontal ? style.horizontal : style.vertical, others.style)}
      wide={horizontal}
    >
      <View style={style.track} wide={horizontal}>
        <View
          borderColor={active ? COLOR.BG_PRIMARY : COLOR.BG_OPACITY_LIGHT}
          style={styles(style.line, style.start, first && style.lineTransparent)}
        />
        <View
          borderColor={active ? COLOR.BG_PRIMARY : COLOR.BG_OPACITY_LIGHT}
          display={DISPLAY.BLOCK}
          style={style.circle}
        >
          <Text
            align={ALIGN.CENTER}
            color={!(active || checked) ? COLOR.TEXT_OPACITY_LIGHT : COLOR.TEXT}
            detail
            style={style.textIndexNumber}
          >
            {indexNumber}
          </Text>
        </View>
        <View
          borderColor={checked || forceActive ? COLOR.BG_PRIMARY : COLOR.BG_OPACITY_LIGHT}
          style={styles(style.line, style.end, last && style.lineTransparent)}
        />
      </View>
      <Text
        align={horizontal ? ALIGN.CENTER : undefined}
        color={!(active || checked) ? COLOR.TEXT_OPACITY_LIGHT : COLOR.TEXT}
        detail={horizontal}
        display={DISPLAY.BLOCK}
        position={horizontal ? POSITION.ABSOLUTE : POSITION.RELATIVE}
        role={TEXT_ROLES.strong}
        style={style.text}
      >
        {text}
      </Text>
    </View>
  );
};

Step.displayName = 'Step';

export { Step };

import { useStoryteller } from '@lookiero/storyteller';
import React, { useState } from 'react';

import { ALIGN, COLOR, MOTION_TIMING, Motion, POSITION, SIZE, SPACE, ScrollView, Text, View, useSwipe } from '@package';

import { style } from './useSwipe.style';

import type { Gesture } from '../../src/hooks/useSwipe/helpers/calcGesture';

const infoProps = {
  backgroundColor: COLOR.BG_PRIMARY_LIGHT,
  padding: SPACE.SPACE_4,
  position: POSITION.ABSOLUTE,
};

export const useSwipeChapter = () => {
  const { viewport } = useStoryteller();

  const [gesture, setGesture] = useState<Gesture | undefined>(undefined);

  const handlerSwipe = useSwipe({
    onSwiping: (gesture) => setGesture(gesture),
    onSwiped: () => setGesture(undefined),
  });

  const { deltaY: top = 0, deltaX: left = 0 } = gesture || {};

  return (
    <ScrollView disableScrollViewPanResponder height={viewport.height}>
      <View
        alignItems={ALIGN.CENTER}
        style={[style.container, { height: viewport.height / 2 }]}
        justifyContent={ALIGN.CENTER}
        position={POSITION.FIXED}
      >
        <Motion
          {...handlerSwipe}
          style={style.motion}
          position={POSITION.RELATIVE}
          alignItems={ALIGN.CENTER}
          backgroundColor={gesture ? COLOR.BG_SUCCESS : COLOR.BG_PROMOTION}
          justifyContent={ALIGN.CENTER}
          layer={SIZE.XS}
          timing={MOTION_TIMING.SPRING}
          disabled={gesture !== undefined}
          value={{
            rotate: `${left >= 0 ? '' : '-'}${left === 0 ? 0 : Math.abs(left / 20)}deg`,
            top,
            left,
          }}
        >
          <Text selectable={false}>{gesture ? 'Swipping' : 'Swipe me!'}</Text>
        </Motion>
        {gesture && (
          <>
            <View {...infoProps} style={style.delta}>
              <Text
                detail
                level={1}
                selectable={false}
                // @ts-expect-error Just for better UX in Storyteller
              >{`{ x: ${parseInt(gesture.deltaX)}, y: ${parseInt(gesture.deltaY)}}`}</Text>
            </View>
            <View {...infoProps} style={style.direction}>
              <Text detail level={1} selectable={false}>
                {(gesture.left ? '⬅️' : '') +
                  (gesture.right ? '➡️' : '') +
                  (gesture.up ? '⬆️' : '') +
                  (gesture.down ? '⬇️' : '')}
              </Text>
            </View>
          </>
        )}
      </View>
    </ScrollView>
  );
};

useSwipeChapter.displayName = '🪝 Hooks/useSwipe';

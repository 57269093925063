import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { WheelPicker } from '@package';

const DAYS_OF_MONTH = [...Array(30).keys()].map((x) => ++x);
const DAYS_OF_MONTH_TEXT = DAYS_OF_MONTH.map((item) => `Day ${item} of every month`);

export const WheelPickerChapter = () => {
  const { boolean, event, object, number } = useStoryteller();

  return (
    <WheelPicker
      disabled={boolean('disabled', false)}
      options={DAYS_OF_MONTH_TEXT}
      selected={number('selected', 3)}
      selectOnScroll={boolean('selectOnScroll', false)}
      style={object('style', {})}
      onChange={(index) => event('onChange', [{ index }])}
    />
  );
};

WheelPickerChapter.displayName = '⚛️ Atoms/WheelPicker';

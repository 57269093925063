const { NODE_ENV } = process.env;

const BANNED_NATIVE_DRIVER_PROPS = [
  'borderWidth',
  'top',
  'left',
  'right',
  'bottom',
  'height',
  'maxHeight',
  'minHeight',
  'width',
  'maxWidth',
  'minWidth',
];

export const getNativeDriver = (props = [], testEnvironment = true) =>
  props.length === 0 || (testEnvironment && NODE_ENV === 'test')
    ? false
    : BANNED_NATIVE_DRIVER_PROPS.filter((key) => props.includes(key)).length === 0;

import type { ViewProps } from 'react-native';

enum DOMAINS {
  alignContent = 'alignContent',
  alignItems = 'alignItems',
  alignSelf = 'alignSelf',
  backgroundColor = 'backgroundColor',
  borderColor = 'borderColor',
  color = 'color',
  display = 'display',
  flex = 'flex',
  flexDirection = 'flexDirection',
  flexWrap = 'flexWrap',
  justifyContent = 'justifyContent',
  layer = 'layer',
  margin = 'margin',
  marginHorizontal = 'marginHorizontal',
  marginVertical = 'marginVertical',
  marginTop = 'marginTop',
  marginRight = 'marginRight',
  marginBottom = 'marginBottom',
  marginLeft = 'marginLeft',
  padding = 'padding',
  paddingHorizontal = 'paddingHorizontal',
  paddingVertical = 'paddingVertical',
  paddingTop = 'paddingTop',
  paddingRight = 'paddingRight',
  paddingBottom = 'paddingBottom',
  paddingLeft = 'paddingLeft',
  pointer = 'pointer',
  position = 'position',
  upperCase = 'upperCase',
  textAlign = 'textAlign',
  wide = 'wide',
}

enum DOMAIN_SHORTCUT {
  backgroundColor = 'bg',
  borderColor = 'bc',
  zIndex = 'z',
}

enum ALIGN {
  AROUND = 'around',
  AUTO = 'auto',
  BETWEEN = 'between',
  STRETCH = 'stretch',
  START = 'start',
  CENTER = 'center',
  END = 'end',
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
}

enum COLOR {
  // TODO: TO DEPRECATE
  BASE = 'base',
  CONTENT = 'content',
  ACCENT = 'accent',
  PRIMARY = 'primary',
  ALERT = 'alert',
  INFO = 'info',
  GRAYSCALE_XL = 'grayscaleXL',
  GRAYSCALE_L = 'grayscaleL',
  GRAYSCALE_M = 'grayscaleM',
  GRAYSCALE_S = 'grayscaleS',
  OVERLAY = 'overlay',
  TOUCHABLE = 'touchable',

  BG_ACTION_PRIMARY_ACTIVE = 'bgActionPrimaryActive',
  BG_ACTION_PRIMARY_DISABLED = 'bgActionPrimaryDisabled',
  BG_ACTION_PRIMARY_HOVER = 'bgActionPrimaryHover',
  BG_ACTION_PRIMARY_PRESSED = 'bgActionPrimaryPressed',
  BG_ACTION_SECONDARY_ACTIVE = 'bgActionSecondaryActive',
  BG_ACTION_SECONDARY_DISABLED = 'bgActionSecondaryDisabled',
  BG_ACTION_SECONDARY_HOVER = 'bgActionSecondaryHover',
  BG_ACTION_SECONDARY_PRESSED = 'bgActionSecondaryPressed',
  BG_BASE = 'bgBase',
  BG_BURNT = 'bgBurnt',
  BG_ERROR = 'bgError',
  BG_ERROR_LIGHT = 'bgErrorLight',
  BG_INTERACTIVE_ACTIVE = 'bgInteractiveActive',
  BG_INTERACTIVE_DISABLED = 'bgInteractiveDisabled',
  BG_INTERACTIVE_HOVER = 'bgInteractiveHover',
  BG_INTERACTIVE_SELECTED = 'bgInteractiveSelected',
  BG_OLIVE = 'bgOlive',
  BG_OPACITY = 'bgOpacity',
  BG_OPACITY_LIGHT = 'bgOpacityLight',
  BG_PRIMARY = 'bgPrimary',
  BG_PRIMARY_LIGHT = 'bgPrimaryLight',
  BG_PRIMARY_MEDIUM = 'bgPrimaryMedium',
  BG_PRIMARY_MEDIUM_LIGHT = 'bgPrimaryMediumLight',
  BG_PROMOTION = 'bgPromotion',
  BG_ROSE = 'bgRose',
  BG_SUCCESS = 'bgSuccess',
  BG_SURFACE = 'bgSurface',
  BG_SURFACE_LIGHT = 'bgSurfaceLight',
  BG_WARNING = 'bgWarning',
  BG_WARNING_LIGHT = 'bgWarningLight',

  BORDER_ACTION_SECONDARY = 'borderActionSecondary',
  BORDER_INPUT = 'borderInput',
  BORDER_INPUT_ERROR = 'borderInputError',
  BORDER_INPUT_FOCUS = 'borderInputFocus',
  BORDER_INTERACTIVE = 'borderInteractive',
  BORDER_INTERACTIVE_SELECTED = 'borderInteractiveSelected',

  ICON = 'icon',
  ICON_DISABLED = 'iconDisabled',
  ICON_ERROR = 'iconError',
  ICON_HIGHLIGHT = 'iconHighlight',
  ICON_LIGHT = 'iconLight',
  ICON_ON_PRIMARY = 'iconOnPrimary',
  ICON_OPACITY = 'iconOpacity',
  ICON_OPACITY_LIGHT = 'iconOpacityLight',
  ICON_PROMOTION = 'iconPromotion',
  ICON_SUCCESS = 'iconSuccess',
  ICON_WARNING = 'iconWarning',

  TEXT = 'text',
  TEXT_ACTION_DISABLED = 'textActionDisabled',
  TEXT_ACTION_PRIMARY = 'textActionPrimary',
  TEXT_ACTION_SECONDARY = 'textActionSecondary',
  TEXT_DISABLED = 'textDisabled',
  TEXT_ERROR = 'textError',
  TEXT_LIGHT = 'textLight',
  TEXT_MEDIUM = 'textMedium',
  TEXT_MEDIUM_LIGHT = 'textMediumLight',
  TEXT_OPACITY = 'textOpacity',
  TEXT_OPACITY_LIGHT = 'textOpacityLight',
  TEXT_PROMOTION = 'textPromotion',
}

enum DISPLAY {
  BLOCK = 'block',
  INLINE = 'inline',
  INLINE_BLOCK = 'inlineBlock',
  FLEX = 'flex',
  NONE = 'none',
}

enum COORD {
  TOP = 'Top',
  RIGHT = 'Right',
  BOTTOM = 'Bottom',
  LEFT = 'Left',
}

enum FLEX_DIRECTION {
  COLUMN = 'column',
  ROW = 'row',
}

enum POINTER {
  ALL = 'all',
  AUTO = 'auto',
  NONE = 'none',
}

enum SPACE {
  SPACE_025 = 'space025',
  SPACE_05 = 'space05',
  SPACE_1 = 'space1', // base
  SPACE_2 = 'space2',
  SPACE_3 = 'space3',
  SPACE_4 = 'space4',
  SPACE_5 = 'space5',
  SPACE_6 = 'space6',
  SPACE_8 = 'space8',
  SPACE_10 = 'space10',
  SPACE_12 = 'space12',
  SPACE_16 = 'space16',
  SPACE_20 = 'space20',
  SPACE_24 = 'space24',
  SPACE_28 = 'space28',
  SPACE_32 = 'space32',
}

enum SIZE {
  XXXS = 'XXXS',
  XXS = 'XXS',
  XS = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
  XXL = 'XXL',
  XXXL = 'XXXL',
  RESET = '0',
}

enum LAYOUT {
  XXXS = 'layoutXXXS',
  XXS = 'layoutXXS',
  XS = 'layoutXS',
  S = 'layoutS',
  M = 'layoutM',
  L = 'layoutL',
  XL = 'layoutXL',
  XXL = 'layoutXXL',
  XXXL = 'layoutXXXL',
}

enum POSITION {
  ABSOLUTE = 'absolute',
  FIXED = 'fixed',
  RELATIVE = 'relative',
  STICKY = 'sticky',
}

enum FLEX_WRAP {
  WRAP = 'wrap',
}

enum FONT {
  HEADING = 'heading',
  BODY = 'body',
  ACTION = 'action',
  DETAIL = 'detail',
  DETAIL_BOLD = 'detailBold',
}

export type Level = 1 | 2 | 3;

interface StylerProperties extends ViewProps {
  alignContent?: ALIGN;
  alignItems?: ALIGN;
  alignSelf?: ALIGN;
  backgroundColor?: COLOR;
  borderColor?: COLOR;
  color?: COLOR;
  display?: DISPLAY;
  flex?: SIZE | LAYOUT;
  flexDirection?: FLEX_DIRECTION;
  justifyContent?: ALIGN;
  layer?: SIZE;
  level?: Level;
  margin?: string | object | string[];
  marginHorizontal?: string | object;
  marginVertical?: string | object;
  marginTop?: string | object;
  marginBottom?: string | object;
  marginLeft?: string | object;
  marginRight?: string | object;
  padding?: string | object | string[];
  paddingHorizontal?: string | object;
  paddingVertical?: string | object;
  paddingTop?: string | object;
  paddingRight?: string | object;
  paddingBottom?: string | object;
  paddingLeft?: string | object;
  pointer?: POINTER;
  position?: POSITION;
  textAlign?: ALIGN;
  upperCase?: boolean;
  wide?: boolean;
}

export type { StylerProperties };
export {
  DOMAINS,
  DOMAIN_SHORTCUT,
  ALIGN,
  COLOR,
  COORD,
  DISPLAY,
  FLEX_DIRECTION,
  FLEX_WRAP,
  FONT,
  POINTER,
  POSITION,
  SIZE,
  SPACE,
  LAYOUT,
};

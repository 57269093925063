import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { BUTTON_VARIANT, Button, Theme } from '@package';

import type { Glyphs } from '@lookiero/aurora-iconfont';

export const ButtonChapter = () => {
  const { boolean, event, select, text, object } = useStoryteller();

  const { iconGlyphs } = Theme.get();

  return (
    <Button
      busy={boolean('busy', false)}
      disabled={boolean('disabled', false)}
      icon={select('icon', '', { options: Object.keys(iconGlyphs) }) as keyof Glyphs}
      // @ts-expect-error Just for better UX in Storyteller
      onPress={(e) => event('onPress')}
      style={object('style', {})}
      small={boolean('small', false)}
      variant={
        BUTTON_VARIANT[
          // @ts-expect-error Just for better UX in Storyteller
          select('variant', undefined, {
            emptyOptionText: 'Select variant...',
            options: Object.keys(BUTTON_VARIANT),
          }) as BUTTON_VARIANT
        ]
      }
      wide={boolean('wide', true)}
    >
      {text('text', 'Hello world')}
    </Button>
  );
};

ButtonChapter.displayName = '⚛️ Atoms/Button';

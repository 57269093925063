import React from 'react';

import { ALIGN, FLEX_DIRECTION, POSITION, SIZE, styles } from '@hooks';
import { MOTION_TYPES, Motion, Touchable } from '@primitives';

import { style } from './Overlay.style';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { MotionProperties } from '../../primitives/Motion/Motion';
import type { FC } from 'react';
export interface OverlayProps {
  isVisible?: boolean;
  onPress?: () => void;
}
export type OverlayProperties = OverlayProps & StylerProperties & MotionProperties;

const Overlay: FC<OverlayProperties> = ({ children, isVisible, nativeID, onPress, ...others }) => (
  <Motion
    {...others}
    delay={!isVisible}
    layer={SIZE.M}
    position={POSITION.FIXED}
    style={styles(style.overlay, others.style)}
    type={isVisible ? MOTION_TYPES.EXPAND : MOTION_TYPES.COLLAPSE}
    value={{ opacity: isVisible ? 1 : 0 }}
  >
    <Touchable
      alignItems={ALIGN.CENTER}
      flexDirection={FLEX_DIRECTION.COLUMN}
      justifyContent={ALIGN.CENTER}
      nativeID={nativeID}
      style={style.touchable}
      tabIndex={0}
      onPress={onPress}
    >
      {children}
    </Touchable>
  </Motion>
);

Overlay.displayName = 'Overlay';

export { Overlay };

import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { Step } from '@package';

export const StepChapter = () => {
  const { boolean, object, text, number } = useStoryteller();

  return (
    <Step
      active={boolean('active', false)}
      checked={boolean('checked', false)}
      first={boolean('first', false)}
      forceActive={boolean('forceActive', false)}
      horizontal={boolean('horizontal', false)}
      last={boolean('last', false)}
      index={number('index', 0)}
      text={text('text', 'label-lookiero')}
      style={object('style', {})}
    />
  );
};

StepChapter.displayName = '⚛️ Atoms/Step';

import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { INPUT_TYPES, InputField, LAYOUT, Theme } from '@package';

import type { Glyphs } from '@lookiero/aurora-iconfont';

export const InputFieldPhoneChapter = () => {
  const { boolean, event, object, text, select } = useStoryteller();

  const { iconGlyphs } = Theme.get();

  return (
    <InputField
      defaultValue={text('defaultValue', '5550123456')}
      disabled={boolean('disabled', false)}
      error={boolean('error', false)}
      hint={text('hint', 'hint-lookiero')}
      icon={select('name', 'profile', { options: Object.keys(iconGlyphs) }) as keyof Glyphs}
      id={text('id', 'id-lookiero')}
      label={text('label', 'label-lookiero')}
      margin={LAYOUT.XXS}
      name={text('name', 'name-lookiero')}
      // @ts-expect-error Just for better UX in Storyteller
      prefix={text('prefix', '0050')}
      style={object('style', {})}
      type={INPUT_TYPES.tel}
      // @ts-expect-error Just for better UX in Storyteller
      onBlur={() => event('onBlur')}
      onChange={(value) => event('onChange', [value])}
      onError={(error) => error && event('onError', [{ error }])}
      // @ts-expect-error Just for better UX in Storyteller
      onFocus={() => event('onFocus')}
    />
  );
};

InputFieldPhoneChapter.displayName = '⚛️ Atoms/InputField (Phone)';

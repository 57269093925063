import React, { Children, Fragment, isValidElement } from 'react';

import { COLOR, useDevice } from '@hooks';
import { VIEW_ROLES, View } from '@primitives';

import { style } from './Footer.style';
import { FooterItem } from './FooterItem';

import type { StylerProperties } from '@hooks';
import type { ViewProperties } from '@primitives';
import type { Component, FC } from 'react';

export interface FooterProps {
  color?: COLOR;
  container?: typeof Component;
  onChange?: (value: string) => void;
  value?: string;
}

export type FooterProperties = FooterProps & StylerProperties & ViewProperties;

export const Footer: FC<FooterProperties> = ({
  children,
  color = COLOR.BG_BASE,
  container: Container = Fragment,
  onChange,
  value,
  ...others
}) => {
  const { screen } = useDevice();
  return (
    <View {...others} backgroundColor={color} role={VIEW_ROLES.nav} wide>
      <Container>
        <View style={[style.footer, screen.L && style.footerDesktop]}>
          {Children.toArray(children).map((child) => {
            if (!isValidElement(child)) return null;
            return (
              <FooterItem
                key={child.props.value}
                {...child.props}
                active={value ? value.includes(child.props.value) : false}
                onPress={onChange ? () => onChange(child.props.value) : undefined}
              />
            );
          })}
        </View>
      </Container>
    </View>
  );
};
Footer.displayName = 'Footer';

import { LinearGradient } from 'expo-linear-gradient';
import React, { useCallback } from 'react';
import { type GestureResponderEvent } from 'react-native';

import {
  BUTTON_VARIANT,
  Button,
  COLOR,
  Image,
  Link,
  SIZE,
  SPACE,
  TEXT_ROLES,
  Text,
  Touchable,
  View,
  styles,
  useDevice,
} from '@package';
import { Theme } from '@theming';

import { BANNER_TYPE } from './Banner.definition';
import { borderRadiusVariantStyle, style } from './Banner.style';

import type { BannerImageProperties, BannerOnlyWithButtons, BannerOnlyWithLink } from './Banner.definition';
import type { StylerProperties } from '@package';
import type { FC } from 'react';

type BannerProps = {
  title: string;
  description?: string;
  pretitle?: string;
  image: BannerImageProperties;
  type?: BANNER_TYPE;
  scaled?: boolean;
  opacity?: number;
};

export type BannerProperties = (BannerOnlyWithButtons | BannerOnlyWithLink) & BannerProps & StylerProperties;

export const Banner: FC<BannerProperties> = ({
  title,
  pretitle,
  description,
  image,
  primaryButton,
  secondaryButton,
  link,
  opacity = 0.5,
  type = BANNER_TYPE.ROUNDED,
  scaled = true,
  ...others
}) => {
  const { screen } = useDevice();
  const { colorBgPrimary, colorBgBaseTransparent } = Theme.get();

  const Container = secondaryButton ? View : Touchable;

  const handleOnPressContainer = useCallback(
    (event: GestureResponderEvent) => {
      if (primaryButton) {
        primaryButton.onPress(event);
      } else if (link) {
        link.onPress({ href: link.href });
      }
    },
    [link, primaryButton],
  );

  return (
    <View {...others}>
      <Container
        onPress={handleOnPressContainer}
        style={styles(style.container, scaled ? style.scaled : style.notScaled, borderRadiusVariantStyle[type])}
      >
        <Image resizeMode="cover" src={image.src} srcSet={image.srcSet} style={style.backgroundImage}>
          <LinearGradient style={[style.gradient, { opacity }]} colors={[colorBgBaseTransparent, colorBgPrimary]} />
        </Image>
        <View layer={SIZE.XS} style={!scaled ? style.contentMaxWidth : undefined}>
          <Text hidden={!pretitle} action level={3} color={COLOR.TEXT_LIGHT} role={TEXT_ROLES.h1}>
            {pretitle}
          </Text>
          <Text heading level={screen.S ? 3 : 2} color={COLOR.TEXT_LIGHT} role={TEXT_ROLES.p}>
            {title}
          </Text>
          <Text
            hidden={!description}
            detail={screen.S}
            level={screen.S ? 1 : 3}
            color={COLOR.TEXT_LIGHT}
            role={TEXT_ROLES.p}
          >
            {description}
          </Text>

          <View marginTop={SPACE.SPACE_6}>
            {primaryButton && (
              <Button
                icon={primaryButton.icon}
                onPress={primaryButton.onPress}
                small
                style={style.alignStart}
                variant={BUTTON_VARIANT.PRIMARY_LIGHT}
                wide={Boolean(secondaryButton)}
              >
                {primaryButton.text}
              </Button>
            )}
            {secondaryButton && (
              <Button
                icon={secondaryButton.icon}
                marginTop={SPACE.SPACE_4}
                onPress={secondaryButton.onPress}
                small
                variant={BUTTON_VARIANT.PRIMARY_LIGHT}
                wide
              >
                {secondaryButton.text}
              </Button>
            )}
            {link && (
              <Link href={link.href} onPress={link.onPress} color={COLOR.TEXT_LIGHT} underlined action level={3}>
                {link.text}
              </Link>
            )}
          </View>
        </View>
      </Container>
    </View>
  );
};

Banner.displayName = 'Banner';

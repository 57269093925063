import React, { createElement } from 'react';

import { COLOR, FLEX_DIRECTION, POINTER, POSITION, useBanStylerProps } from '@hooks';
import { Icon, MOTION_TYPES, Motion, SWITCH_VARIANT, Switch, View } from '@primitives';

import { style } from './InputOption.style';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { SwitchProperties } from '../../primitives/Switch/Switch';
import type { ViewProperties } from '../../primitives/View/View';
import type { FC } from 'react';

export interface InputOptionProps {
  checked?: boolean;
  disabled?: boolean;
  id?: string;
  name: string;
  type?: SWITCH_VARIANT;
  value: string;
}

export type InputOptionProperties = InputOptionProps & StylerProperties & SwitchProperties & ViewProperties;

export const InputOption: FC<InputOptionProperties> = ({
  checked = false,
  children,
  disabled = false,
  id,
  name,
  onChange,
  type = SWITCH_VARIANT.RADIO,
  value,
  ...others
}) => {
  return (
    <View {...others} flexDirection={FLEX_DIRECTION.ROW}>
      <View style={style.input}>
        {createElement(Switch, {
          ...useBanStylerProps({ ...others, testID: undefined }),
          checked,
          disabled,
          id,
          name,
          value,
          variant: type,
          onChange,
        })}
        <Motion
          pointer={POINTER.NONE}
          position={POSITION.ABSOLUTE}
          style={style.icon}
          type={checked ? MOTION_TYPES.EXPAND : MOTION_TYPES.COLLAPSE}
          value={{ opacity: checked ? 1 : 0, scale: checked ? 1 : 0 }}
        >
          <Icon color={disabled ? COLOR.ICON_DISABLED : COLOR.ICON} name="tick" />
        </Motion>
      </View>
      {children && (
        <View style={style.childrenView} wide>
          {children}
        </View>
      )}
    </View>
  );
};

InputOption.displayName = 'InputOption';

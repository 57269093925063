import LottieView from 'lottie-react-native';
import React from 'react';

import { Text, VIEW_ROLES, View } from '@primitives';

import { spinner_neutral, spinner_shapes } from './lottie';
import { SPINNER_VARIANT } from './Spinner.definition';
import { style } from './Spinner.style';
import { ALIGN, type StylerProperties } from '../../../hooks/useStyler/styler.definition';
import { Box } from '../Box';

import type { FC, ReactNode } from 'react';

export interface SpinnerProps {
  text?: ReactNode;
  variant?: SPINNER_VARIANT;
}

export type SpinnerProperties = SpinnerProps & StylerProperties;

const Spinner: FC<SpinnerProperties> = ({ accessibilityLabel, testID, text, variant = SPINNER_VARIANT.NEUTRAL }) => {
  const neutral = variant === SPINNER_VARIANT.NEUTRAL;

  return (
    <View role={VIEW_ROLES.div} style={style.container} accessibilityLabel={accessibilityLabel} testID={testID}>
      <LottieView autoPlay source={neutral ? spinner_neutral : spinner_shapes} style={style.spinner} loop />
      {text && !neutral && (
        <Box align={ALIGN.CENTER} size={{ S: '2/3', M: '2/3', L: '2/3' }}>
          <Text align={ALIGN.CENTER} action level={2}>
            {text}
          </Text>
        </Box>
      )}
    </View>
  );
};

Spinner.displayName = 'Spinner';

export { Spinner };

import React from 'react';

import { ButtonIcon } from '@atoms';
import { ALIGN, COLOR, DISPLAY, useDevice } from '@hooks';
import { Text, Touchable } from '@primitives';

import { style } from './FooterItem.style';

import type { StylerProperties } from '@hooks';
import type { IconName, TouchableProperties } from '@primitives';
import type { FC } from 'react';

export interface FooterItemProps {
  active?: boolean;
  icon?: IconName;
  color?: string;
  colorActiveIcon?: COLOR;
  colorActiveText?: COLOR;
  notification?: boolean;
  text?: string;
  value: string;
}

export type FooterItemProperties = FooterItemProps & StylerProperties & TouchableProperties;

export const FooterItem: FC<FooterItemProperties> = ({
  active,
  color = COLOR.TEXT_MEDIUM,
  colorActiveIcon = COLOR.ICON,
  colorActiveText = COLOR.TEXT,
  icon,
  onPress,
  text,
  notification,
  ...others
}) => {
  const { screen } = useDevice();
  return (
    <Touchable {...others} style={[style.button, screen.L && style.buttonDesktop]} onPress={onPress}>
      {icon && (
        <ButtonIcon
          color={active ? colorActiveIcon : color}
          name={icon}
          notification={notification}
          style={[style.iconContainer, screen.L && style.iconDesktop]}
          small
        />
      )}
      {text && (
        <Text
          align={ALIGN.CENTER}
          color={active ? colorActiveText : color}
          detail={!screen.L}
          display={DISPLAY.BLOCK}
          ellipsizeMode
          action={screen.L}
          level={3}
          style={[style.text]}
        >
          {text}
        </Text>
      )}
    </Touchable>
  );
};

FooterItem.displayName = 'FooterItem';

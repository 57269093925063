import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { OPTION_VARIANT, Option, SWITCH_VARIANT, Theme } from '@package';

import type { Glyphs } from '@lookiero/aurora-iconfont';

const options = Object.keys(OPTION_VARIANT);

export const OptionChapter = () => {
  const { boolean, event, object, select, text } = useStoryteller();

  const { iconGlyphs } = Theme.get();

  return (
    <Option
      checked={boolean('checked', false)}
      disabled={boolean('disabled', false)}
      icon={
        // @ts-expect-error Just for better UX in Storyteller
        select('icon', undefined, {
          hint: 'Must be a valid icon from auroraicons, like `avoid`',
          options: Object.keys(iconGlyphs),
        }) as keyof Glyphs
      }
      name={text('name', 'name-lookiero')}
      small={boolean('small', false)}
      style={object('style', {})}
      title={text('title', 'Lorem ipsum dolor sit amet')}
      type={select('type', SWITCH_VARIANT.RADIO, { options: Object.values(SWITCH_VARIANT) }) as SWITCH_VARIANT}
      unselected={boolean('unselected', false)}
      value={text('value', 'value-lookiero')}
      // @ts-expect-error Just for better UX in Storyteller
      variant={OPTION_VARIANT[select('variant', options[0], { options })]}
      onChange={(e) => event('onChange', [{ e }])}
    />
  );
};

OptionChapter.displayName = '⚛️ Atoms/Option';

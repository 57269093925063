import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  toolTip: undefined,

  container: {
    borderRadius: '$borderRadius3',
    padding: '$space3',
  },

  containerShadow: {
    shadowColor: '$shadowColor',
    shadowOffset: '$shadowOffset',
    shadowOpacity: '$shadowOpacityM',
    shadowRadius: '$shadowRadius',
    elevation: '$shadowElevation',
  },

  buttonIcon: {
    padding: '$space1',
  },

  arrow: {
    left: '50%',
    transform: [{ rotateZ: '45deg' }],
    marginLeft: () => -StyleSheet.value('$space3 - $space05'),
    width: '$space5',
    height: '$space5',
  },

  arrowTop: {
    top: '100%',
    marginTop: () => -StyleSheet.value('$space3 - $space05'),
  },

  arrowBottom: {
    bottom: '100%',
    marginBottom: () => -StyleSheet.value('$space3 - $space05'),
  },

  // Tooltip align
  bottom: {
    top: '100%',
    bottom: 'auto',
  },

  top: {
    top: 'auto',
    bottom: '100%',
  },

  // ToolTip alignArrow
  arrowLeft: {
    left: '12%',
  },

  arrowRight: {
    left: '88%',
  },
});

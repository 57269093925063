import { DefaultTheme } from '@package';

export const AuroraDarkTheme = {
  ...DefaultTheme,
  $theme: 'aurora-dark',

  $colorBgError: '#A32D2D',
  $colorBgErrorLight: '#FFC0CB',

  $fontHeading: 'Courier New',
  $fontBody: 'Courier New',
  $fontAction: 'Courier New',
  $fontDetail: 'Courier New',
};

import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { Children } from '@mocks';
import { COLOR, PRESSABLE_OPACITY, SPACE, TOUCHABLE_ROLES, Touchable } from '@package';

export const TouchableChapter = () => {
  const { event, number, object, select } = useStoryteller();

  return (
    <Touchable
      tabIndex={number('tabIndex')}
      backgroundColor={COLOR.BG_PRIMARY_LIGHT}
      // @ts-expect-error Just for better UX in Storyteller
      onPress={() => event('onPress')}
      paddingHorizontal={SPACE.SPACE_4}
      paddingVertical={SPACE.SPACE_12}
      pressableOpacity={number('pressableOpacity', PRESSABLE_OPACITY.DEFAULT)}
      // @ts-expect-error Just for better UX in Storyteller
      role={select('role', undefined, { options: Object.values(TOUCHABLE_ROLES) })}
      style={object('style', {})}
    >
      <Children />
    </Touchable>
  );
};

TouchableChapter.displayName = '🐵 Primitives/Touchable';

import { useEffect, useState } from 'react';

import { hasValue } from './hasValue';

import type { InputValue } from '../../../primitives/Input/Input.definition';

interface UseFieldProps {
  defaultValue?: InputValue;
  value?: InputValue;
  onChange?: ((value: InputValue) => void) | undefined;
}

interface UseFieldResult {
  filled: boolean;
  handleChange: (value: InputValue) => void;
}

export const useField = ({ defaultValue, value, onChange }: UseFieldProps): UseFieldResult => {
  const [filled, setFilled] = useState<boolean>(() => hasValue(defaultValue));

  useEffect(() => {
    setFilled(hasValue(defaultValue));
  }, [defaultValue]);

  useEffect(() => {
    if (value || !defaultValue) setFilled(hasValue(value));
  }, [defaultValue, value]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleChange = (value: InputValue) => {
    setFilled(hasValue(value));
    if (onChange) onChange(value);
  };

  return {
    filled,
    handleChange,
  };
};

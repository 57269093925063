const REGEX_DELIMITER = /\s*,\s*/;

interface Source {
  pixelDensity: number;
  uri: string;
}

const getLargestUri = (sources: Source[] = []): { uri: string; sizeUri: string } => {
  const sizeUri = sources.find((source) => source.pixelDensity === 1)?.uri ?? '';

  const biggestSource = sources.sort((a, b) => {
    if (a.pixelDensity === b.pixelDensity) {
      return 0;
    }

    return a.pixelDensity < b.pixelDensity ? 1 : -1;
  })[0];

  return {
    uri: biggestSource?.uri || '',
    sizeUri,
  };
};

const toSources = (srcSet: string = '') =>
  srcSet.split(REGEX_DELIMITER).reduce((array: { pixelDensity: number; uri: string }[], src: string) => {
    const [uri, pixelDensityRaw] = src.split(' ');

    if (!pixelDensityRaw) return [...array, { pixelDensity: 1, uri }];

    const pixelDensity = parseFloat(pixelDensityRaw.replace('x', ''));
    return [...array, { pixelDensity, uri }];
  }, []);

export const getUri = (src?: string | number, srcSet?: string) =>
  srcSet ? getLargestUri(toSources(srcSet)) : { uri: src, sizeUri: src };

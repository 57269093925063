import { Dimensions } from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';

const X_AXYS_PROPS = ['translateX', 'left', 'right', 'width'];
const Y_AXYS_PROPS = ['translateY', 'top', 'bottom', 'height'];
const AXYS_PROPS = [...X_AXYS_PROPS, ...Y_AXYS_PROPS];

export const toAnimatedValue = (property, value, layout) => {
  if (typeof value !== 'string') return value;

  let animatedValue = value;
  if (value.includes('%')) {
    if (AXYS_PROPS.includes(property)) {
      const { height, width } = layout || Dimensions.get('window');
      animatedValue = Math.ceil(((X_AXYS_PROPS.includes(property) ? width : height) * parseFloat(value)) / 100);
    } else {
      animatedValue = parseFloat(value) / 100;
    }
  } else if (value.includes('rem')) {
    animatedValue = parseFloat(value) * StyleSheet.value('$space4');
  } else if (value.includes('deg')) {
    animatedValue = parseFloat(value) / 360;
  } else {
    animatedValue = parseFloat(animatedValue);
  }

  return animatedValue;
};

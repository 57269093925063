import { Platform } from 'react-native';

export const testID = (id?: string, childId?: string) => {
  if (!id) return {};

  const value = childId ? `${id}-${childId}` : id;

  return {
    testID: value,
    ...(Platform.OS === 'android' ? { accessible: true, accessibilityLabel: value } : undefined),
  };
};

import { createElement, useEffect, useRef } from 'react';
import { Animated, Pressable } from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';

import { testID } from '@helpers';
import { styles, useBanProps, useBanStylerProps, useDevice, useStyler } from '@hooks';

import { BANNED_PROPS, SWITCH_VARIANT } from './Switch.definition';
import { style } from './Switch.style';

import type { StylerProperties } from '@hooks';
import type { FC } from 'react';
import type { ViewProps } from 'react-native';

export interface SwitchProps {
  checked?: boolean;
  disabled?: boolean;
  id?: string;
  name?: string;
  variant?: SWITCH_VARIANT;
  value?: string;

  onChange?: (checked: boolean) => void;
}
export type SwitchProperties = SwitchProps & StylerProperties & Animated.AnimatedProps<ViewProps>;

const Switch: FC<SwitchProperties> = ({
  checked,
  disabled,
  variant = SWITCH_VARIANT.CHECKBOX,
  onChange,
  ...others
}) => {
  const bgColorAnimatedValue = useRef(new Animated.Value(disabled ? -100 : checked ? 100 : 0)).current;
  const borderColorAnimatedValue = useRef(new Animated.Value(disabled ? -100 : checked ? 100 : 0)).current;

  useEffect(() => {
    const animation = Animated.parallel([
      Animated.timing(bgColorAnimatedValue, {
        toValue: disabled ? -100 : checked ? 100 : 0,
        duration: StyleSheet.value('$motionExpand'),
        useNativeDriver: false,
      }),
      Animated.timing(borderColorAnimatedValue, {
        toValue: disabled ? -100 : checked ? 100 : 0,
        duration: StyleSheet.value('$motionExpand'),
        useNativeDriver: false,
      }),
    ]);
    animation.start();

    return animation.stop;
  }, [bgColorAnimatedValue, borderColorAnimatedValue, checked, disabled]);

  const handlePress = () => {
    if (onChange) onChange(!checked);
  };

  // @ts-expect-error We should review this
  return createElement(onChange ? Animated.createAnimatedComponent(Pressable) : Animated.View, {
    ...useBanProps(useBanStylerProps(others), BANNED_PROPS),
    ...useStyler(
      {
        style: styles(
          style.container,
          style[variant],
          {
            backgroundColor: bgColorAnimatedValue.interpolate({
              inputRange: [-100, 0, 100],
              outputRange: [
                StyleSheet.value('$colorBgBase'), // disabled -> -100
                StyleSheet.value('$colorBgBase'), // default -> 0
                StyleSheet.value('$colorBgInteractiveSelected'), // checked -> 100
              ],
            }),
            borderColor: borderColorAnimatedValue.interpolate({
              inputRange: [-100, 0, 100],
              outputRange: [
                StyleSheet.value('$colorBorderInteractive'), // disabled -> -100
                StyleSheet.value('$colorBorderInteractive'), // default -> 0
                StyleSheet.value('$colorBgInteractiveSelected'), // checked -> 100
              ],
            }),
          },
          others.style,
        ),
        ...others,
      },
      Switch.displayName,
      useDevice(),
    ),
    ...testID(others.testID),
    accessibilityRole: variant,
    disabled,
    onPress: onChange ? handlePress : undefined,
  });
};

// ! Will be deprecated in v4 --------------------------------------------------
const Checkbox: FC<SwitchProperties> = (props) => createElement(Switch, { ...props, variant: SWITCH_VARIANT.CHECKBOX });

const Radio: FC<SwitchProperties> = (props) => createElement(Switch, { ...props, variant: SWITCH_VARIANT.RADIO });
// ! ---------------------------------------------------------------------------

Switch.displayName = 'Switch';
Checkbox.displayName = 'Checkbox';
Radio.displayName = 'Radio';

export { Switch, Checkbox, Radio };

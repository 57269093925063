import React from 'react';

import { ALIGN, COLOR, FLEX_DIRECTION, SPACE, useDevice } from '@hooks';
import { Icon, Text, View } from '@primitives';
import { Theme } from '@theming';

import { style } from './TitleIcon.style';

import type { Glyphs } from '@lookiero/aurora-iconfont';
import type { IconProperties, ViewProperties } from '@primitives';
import type { FC } from 'react';

interface TitleIconProps {
  text: string;
  icon: IconProperties['name'];
  color?: COLOR;
}

export type TitleIconProperties = TitleIconProps & ViewProperties;
export const TitleIcon: FC<TitleIconProperties> = ({ text, icon, color = COLOR.TEXT, ...rest }) => {
  const { screen } = useDevice();
  const iconValue = (!icon.includes('small') && !screen.L ? `${icon}_small` : icon) as keyof Glyphs;
  const allIcons = Theme.get().iconGlyphs;

  return (
    <View {...rest}>
      <View flexDirection={FLEX_DIRECTION.ROW}>
        <Icon name={allIcons[iconValue] ? iconValue : icon} alignSelf={ALIGN.CENTER} color={color} />
        <Text action level={3} alignSelf={ALIGN.CENTER} marginLeft={SPACE.SPACE_2} color={color} style={style.text}>
          {text}
        </Text>
      </View>
    </View>
  );
};

TitleIcon.displayName = 'TitleIcon';

import React from 'react';

import { View } from '@primitives';

import { Thumbnail } from '../Thumbnail';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { ViewProperties } from '../../primitives/View/View';
import type { FC } from 'react';

export interface SlideProps {
  active?: boolean;
  height?: number;
  src?: string;
  width?: number;
}

export type SlideProperties = SlideProps & StylerProperties & ViewProperties;

const Slide: FC<SlideProperties> = ({ children, height = 320, src, width = 256, ...others }) => (
  <View {...others}>
    <Thumbnail src={src} height={height} width={width} />
    {children}
  </View>
);

Slide.displayName = 'Slide';

export { Slide };

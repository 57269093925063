import React from 'react';

import { ALIGN, COLOR, POINTER, SIZE, styles } from '@hooks';
import { TEXT_ROLES, Text, Touchable } from '@primitives';

import { style } from './WheelPickerItem.style';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { FC } from 'react';

export interface WheelPickerItemProps {
  behavior?: 'smooth' | 'auto';
  checked?: boolean;
  disabled?: boolean;
  onPress?: () => void;
  scrollable?: boolean;
  testID?: string;
  title?: string;
}

export type WheelPickerItemProperties = WheelPickerItemProps & StylerProperties;

export const WheelPickerItem: FC<WheelPickerItemProperties> = ({
  checked,
  disabled,
  onPress,
  scrollable,
  title,
  testID,
  style: externalStyle,
}) => (
  <Touchable
    role="spinbutton"
    backgroundColor={checked ? COLOR.BG_INTERACTIVE_SELECTED : disabled ? COLOR.BG_INTERACTIVE_DISABLED : undefined}
    layer={checked ? SIZE.XS : undefined}
    style={styles(style.item, externalStyle)}
    testID={testID}
    wide
    onPress={!disabled ? onPress : undefined}
  >
    {title && (
      <Text
        action
        align={ALIGN.CENTER}
        role={TEXT_ROLES.label}
        color={disabled ? COLOR.TEXT_DISABLED : COLOR.TEXT}
        detail={scrollable}
        accessibilityLabelledBy={title}
        pointer={POINTER.NONE}
        selectable={false}
      >
        {title}
      </Text>
    )}
  </Touchable>
);

WheelPickerItem.displayName = 'WheelPickerItem';

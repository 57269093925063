import React from 'react';

import { ALIGN } from '@hooks';
import { VIEW_ROLES, View } from '@primitives';

import { TableRow } from './TableRow';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { ViewProperties } from '../../primitives/View/View';
import type { FC } from 'react';

export interface TableProps {
  dataSource: Record<string, string>[];
  schema: Record<string, string>;
}

export type TableProperties = TableProps & StylerProperties & ViewProperties;

const Table: FC<TableProperties> = ({ dataSource = [], schema, ...others }) => (
  <View {...others} role={VIEW_ROLES.table} textAlign={ALIGN.LEFT} wide>
    <View role={VIEW_ROLES.thead}>
      <TableRow schema={schema} />
    </View>
    <View role={VIEW_ROLES.tbody}>
      {dataSource.map((row, index) => (
        <TableRow dataSource={row} key={index} schema={schema} />
      ))}
    </View>
  </View>
);

Table.displayName = 'Table';

export { Table };

import { Platform } from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  input: {
    color: '$colorText',
    flex: 0,
    height: '$inputSize',
    ...Platform.select({
      web: {
        outlineWidth: 0,
        height: undefined,
      },
    }),
  },

  disabled: {
    color: '$colorTextDisabled',
  },

  multiline: {
    height: 'auto',
    minHeight: '$space24',
    paddingTop: '$space4',
    paddingBottom: '$space4',
    textAlignVertical: 'top',
  },
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useStoryteller } from '@lookiero/storyteller';
import React, { useState } from 'react';

import { Button, Form, INPUT_TYPES, InputField, InputOption, SIZE, SPACE, ScrollView, Text } from '@package';

const options = [
  { name: 'Male', value: '1' },
  { name: 'Female', value: '2' },
];
const bio = '#fashion lover · personal shopper · follow us in instagram @lookiero';

const headingProps = { as: 'h2', heading: true, marginBottom: SPACE.SPACE_4 };

const ERRORS = {
  invalidDate: 'Invalid date format.',
  invalidEmail: 'Invalid email format.',
  required: 'Required field',
  unknown: 'Unknown error',
};

const SCHEMA = {
  user: {
    minLength: 10,
    required: true,
  },
  bio: {
    regexp: /quick\s(brown).+?(jumps)/gi,
  },
};

export const FormChapter = () => {
  const { boolean, event, number, object } = useStoryteller();

  const [error, setError] = useState({});
  const [gender, setGender] = useState(options[1].value);
  const [birth, setBirth] = useState('1980/04/10');
  const [checked, setChecked] = useState(true);

  const handleChange = (value, state) => {
    event('onChange', value, state);
    setGender(value.gender);
    setBirth(value.birth);
    setChecked(value.checkbox);
  };
  const handleError = (nextError = {}) => {
    if (Object.keys(nextError).length > 0) event('onError', nextError);
    setError(nextError);
  };

  return (
    <ScrollView>
      <Form
        debounce={number('debounce', 0, { hint: 'Debounces onChange() in miliseconds.' })}
        padding={SIZE.M}
        schema={SCHEMA}
        style={object('style', {})}
        validateOnMount={boolean('validateOnMount', false, {
          hint: 'Dispatch onError() if initial value contains errors.',
        })}
        onChange={handleChange}
        onSubmit={(value) => event('onSubmit', value)}
        onError={handleError}
      >
        <Text {...headingProps}>Credentials</Text>
        <InputField
          name="user"
          defaultValue="lookiero"
          label="username"
          marginBottom={SIZE.M}
          test={(value) => value.includes('lookiero')}
          hint={
            error.user
              ? error.user.required
                ? ERRORS.required
                : error.user.test
                  ? ERRORS.unknown
                  : undefined
              : undefined
          }
        />
        <InputField
          name="password"
          type="password"
          defaultValue="lookiero"
          label="password"
          marginBottom={SIZE.M}
          minLength={6}
        />
        <InputOption checked={checked} marginBottom={SIZE.M} name="checkbox" type="checkbox">
          <Text as="label" detail level={2} marginLeft={SIZE.XXS}>
            Do you want to receive commercial communications?
          </Text>
        </InputOption>
        <Text {...headingProps}>Personal Data</Text>
        <InputField
          name="email"
          type="email"
          defaultValue="hello@lookiero"
          label="email"
          marginBottom={SIZE.M}
          hint={error.email ? ERRORS.invalidEmail : undefined}
        />
        <InputField name="age" type="number" defaultValue="40" label="Age" marginBottom={SIZE.M} />
        {/* BIRTH */}
        <InputField
          name="birth"
          type="date"
          value={birth}
          label="Birth"
          icon="calendar"
          min="1960/01/19"
          marginBottom={SIZE.M}
          hint={error.birth ? ERRORS.invalidDate : undefined}
        />

        {/* GENDER */}
        <InputField
          //
          label="gender"
          marginBottom={SIZE.M}
          name="gender"
          options={options}
          value={gender}
        />

        <InputField
          name="size"
          fieldset={{ feet: 'number', inches: 'number' }}
          defaultValue={{ feet: 5.91, inches: 12.23 }}
          label="size"
          marginBottom={SIZE.M}
          required
          test={({ feet, inches } = {}) => {
            return parseInt(feet, 10) >= 5 && parseInt(inches, 10) <= 20;
          }}
        />
        <InputField
          name="phone"
          type={INPUT_TYPES.tel}
          prefix="+34"
          defaultValue="666111222"
          label="Phone"
          marginBottom={SIZE.M}
        />
        <InputField name="bio" defaultValue={bio} label="bio" multiLine marginBottom={SIZE.M} />
        <Button type="submit">Submit</Button>
      </Form>
    </ScrollView>
  );
};

FormChapter.displayName = '⚛️ Atoms/Form';

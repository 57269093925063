// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Children, cloneElement } from 'react';

import { FLEX_DIRECTION, SIZE, SPACE } from '@hooks';
import { View } from '@primitives';

import type { OptionProperties } from './Option';
import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { ViewProperties } from '../../primitives/View/View';
import type { FC } from 'react';

export interface OptionContainerProps {
  horizontal?: boolean;
  gutter?: SPACE;
  maxSelected?: number;
  multiSelection?: boolean;
  name?: string;
  option?: string | string[];

  onChange: (value: string | string[]) => void;
}
export type OptionContainerProperties = OptionContainerProps & StylerProperties & ViewProperties;

const OptionContainer: FC<OptionContainerProperties> = ({
  children,
  horizontal = true,
  gutter = SPACE.SPACE_2,
  maxSelected,
  multiSelection,
  name,
  option,
  onChange,
  ...others
}) => {
  const handleMultiSelectionChange = (value: string) => {
    let nextValue = typeof option === 'string' ? [option] : option;
    nextValue = nextValue?.includes(value) ? nextValue.filter((item: string) => item !== value) : [...nextValue, value];
    if (maxSelected && maxSelected > 0 && nextValue.length > maxSelected) {
      nextValue.shift();
    }

    onChange(nextValue);
  };

  return (
    <View {...others} flexDirection={horizontal ? FLEX_DIRECTION.ROW : FLEX_DIRECTION.COLUMN}>
      {Children.map(children, (child, index: number) =>
        cloneElement<OptionProperties>(child, {
          id: child.props.id,
          flex: horizontal ? SIZE.XS : undefined,
          name: child.props.name || name,
          checked: multiSelection ? option.includes(child.props.value) : child.props.value === option,
          ...(index < children.length - 1
            ? horizontal
              ? { marginRight: gutter }
              : { marginBottom: gutter }
            : undefined),
          onChange: () =>
            multiSelection ? handleMultiSelectionChange(child.props.value) : onChange(child.props.value),
        }),
      )}
    </View>
  );
};

OptionContainer.displayName = 'OptionContainer';

export { OptionContainer };

import React from 'react';

import { COLOR, DISPLAY, POINTER, styles } from '@hooks';
import { View } from '@primitives';

import { style } from './Divider.style';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { ViewProperties } from '../../primitives/View/View';
import type { FC } from 'react';

export interface DividerProps {
  color?: COLOR;
}

export type DividerProperties = DividerProps & StylerProperties & ViewProperties;

const Divider: FC<DividerProperties> = ({ color = COLOR.BG_PRIMARY, ...others }) => (
  <View
    {...others}
    accessibilityElementsHidden
    backgroundColor={color}
    display={DISPLAY.BLOCK}
    importantForAccessibility="no"
    pointer={POINTER.NONE}
    style={styles(style.divider, others.style)}
    wide
  />
);

Divider.displayName = 'Divider';

export { Divider };

import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  item: {
    alignItems: 'center',
    borderStyle: '$borderStyle',
    borderWidth: '$borderWidth2',
    borderRadius: '$borderRadius3',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: '$space4',
    paddingBottom: '$space4',
    paddingLeft: '$space4',
    paddingRight: '$space6',
  },

  itemWithoutBorder: {
    borderColor: 'transparent',
  },

  content: {
    minWidth: 0,
  },

  detail: {
    marginLeft: '$space4',
    maxWidth: '$space24',
    paddingTop: '$space05',
  },
});

import { IS_JEST } from './isJest';

const DEFAULT_LOCALE = IS_JEST ? 'en-EN' : undefined;

interface LocaleDateStringOptions {
  dateStyle?: 'full' | 'long' | 'medium' | 'short';
  timeStyle?: 'full' | 'long' | 'medium' | 'short';
  calendar?:
    | 'buddhist'
    | 'chinese'
    | 'coptic'
    | 'dangi'
    | 'ethioaa'
    | 'ethiopic'
    | 'gregory'
    | 'hebrew'
    | 'indian'
    | 'islamic'
    | 'islamic-umalqura'
    | 'islamic-tbla'
    | 'islamic-civil'
    | 'islamic-rgsa'
    | 'iso8601'
    | 'japanese'
    | 'persian'
    | 'roc'
    | 'islamicc';
  dayPeriod?: 'narrow' | 'short' | 'long';
  year?: 'numeric' | '2-digit';
  month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';
  day?: 'numeric' | '2-digit';
  weekday?: 'narrow' | 'short' | 'long';
  locale?: string;
}

export const toLocale = (date: Date, { locale = DEFAULT_LOCALE, ...options }: LocaleDateStringOptions = {}): string =>
  date.toLocaleDateString(locale, options);

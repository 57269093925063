import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { ALIGN, SPINNER_VARIANT, Spinner, View } from '@package';

export const SpinnerChapter = () => {
  const { select, text } = useStoryteller();

  return (
    // eslint-disable-next-line react-native/no-inline-styles
    <View style={{ flex: 1 }} alignItems={ALIGN.CENTER} alignContent={ALIGN.CENTER} justifyContent={ALIGN.CENTER}>
      <Spinner
        text={text('text', 'Estamos guardando tu selección')}
        variant={
          select('variant', SPINNER_VARIANT.NEUTRAL, {
            options: Object.values(SPINNER_VARIANT),
          }) as SPINNER_VARIANT
        }
      />
    </View>
  );
};

SpinnerChapter.displayName = '⚛️ Atoms/Spinner';

import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { Children, IMAGE_2x_URL, IMAGE_URL } from '@mocks';
import { OptionImage } from '@package';

export const OptionImageChapter = () => {
  const { boolean, event, number, object, text } = useStoryteller();

  const props = {
    checked: boolean('checked', false),
    id: text('id', 'id-lookiero'),
    name: text('name', 'name-lookiero'),
    src: text('src', IMAGE_URL),
    srcSet: text('srcSet', `${IMAGE_URL} 1x, ${IMAGE_2x_URL} 2x`, { multiLine: true }),
    style: object('style', {}),
    title: text('title', 'title-lookiero'),
    value: text('value', 'value-lookiero'),
    height: number('height', 250),
    width: number('width', 187),
    onChange: (e: string) => event('onChange', [{ e }]),
  };

  return (
    <>
      <OptionImage {...props} />

      <OptionImage {...props} checked={boolean('checked', true)}>
        <Children />
      </OptionImage>
    </>
  );
};

OptionImageChapter.displayName = '✳️ Molecules/OptionImage';

import React from 'react';
import { Text } from 'react-native';

import { stylesheetColor, testID } from '@helpers';
import { styles, useBanProps, useBanStylerProps, useDevice, useStyler } from '@hooks';
import { Theme } from '@theming';

import { BANNED_PROPS } from './Icon.definition';
import { style } from './Icon.style';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { TextProperties } from '../Text/Text';
import type { COLOR } from '@hooks';
import type { Glyphs } from '@lookiero/aurora-iconfont';
import type { FC } from 'react';

export type IconName = keyof Glyphs;

export interface IconProps {
  color?: COLOR;
  name: IconName;
}

export type IconProperties = IconProps & StylerProperties & TextProperties;

const Icon: FC<IconProperties> = ({ color, name = 'lookiero', ...others }) => {
  const { iconGlyphs } = Theme.get();

  return (
    <Text
      {...useBanProps(useBanStylerProps(others), BANNED_PROPS)}
      {...useStyler(
        {
          ...others,
          style: styles(
            others.style,
            stylesheetColor(color).color,
            style.icon,
            name.includes('small') ? style.small : name.includes('large') ? style.large : undefined,
          ),
        },
        Icon.displayName,
        useDevice(),
      )}
      {...testID(others.testID)}
      accessibilityElementsHidden={true}
      allowFontScaling={false}
      importantForAccessibility="no"
      selectable={false}
    >
      {String.fromCharCode(iconGlyphs[name])}
    </Text>
  );
};

Icon.displayName = 'Icon';

export { Icon };

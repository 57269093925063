import type { Component } from 'react';

const PORTAL_REDUCER_TYPE = {
  MOUNT: 'mount',
  UNMOUNT: 'unmount',
};

export type PortalAction = {
  type: string;
  id: string;
  component?: Component<{ isVisible: boolean }>;
};

export type PortalState = {
  id: string;
  component?: Component<{ isVisible: boolean }>;
};

const PortalReducer = (state: PortalState[] = [], action: PortalAction = {} as PortalAction): PortalState[] => {
  const { type, id, component } = action;

  switch (type) {
    case PORTAL_REDUCER_TYPE.MOUNT:
      return [...state, { id, component }];
    case PORTAL_REDUCER_TYPE.UNMOUNT:
      return state.filter((s) => s.id !== id);
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

export { PORTAL_REDUCER_TYPE, PortalReducer };

import React, { useEffect, useState } from 'react';

import { ButtonIcon } from '@atoms';
import { ALIGN, COLOR, DISPLAY, POINTER, SPACE, styles } from '@hooks';
import { Icon, MOTION_TYPES, Motion, Text, VIEW_ROLES, View } from '@primitives';

import { calcMotion } from './helpers';
import { NOTIFICATION_VARIANT } from './Notification.definition';
import { style } from './Notification.style';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { IconName } from '../../primitives/Icon/Icon';
import type { ViewProperties } from '../../primitives/View/View';
import type { MOTION_TIMING } from '@primitives';
import type { FC, ReactNode } from 'react';
import type { LayoutChangeEvent, LayoutRectangle } from 'react-native';

export interface NotificationProps {
  color?: COLOR;
  colorText?: COLOR;
  isVisible?: boolean;
  motion?: 'top' | 'bottom' | 'pop';
  text?: ReactNode;
  timeoutClose?: number;
  timing?: MOTION_TIMING;
  onClose?: () => void;
  variant?: NOTIFICATION_VARIANT;
}

export type NotificationProperties = NotificationProps & StylerProperties & ViewProperties;

const notificationIconName: Record<Exclude<NOTIFICATION_VARIANT, NOTIFICATION_VARIANT.PROMO>, IconName> = {
  success: 'tick_fill',
  critical: 'critical',
  warning: 'warning',
  neutral: 'info',
};

const notificationIconColor: Record<Exclude<NOTIFICATION_VARIANT, NOTIFICATION_VARIANT.PROMO>, COLOR> = {
  success: COLOR.ICON_SUCCESS,
  critical: COLOR.ICON_ERROR,
  warning: COLOR.ICON_WARNING,
  neutral: COLOR.ICON_ON_PRIMARY,
};

const Notification: FC<NotificationProperties> = ({
  children,
  color,
  colorText,
  isVisible = false,
  motion = 'top',
  nativeID,
  timeoutClose,
  text,
  timing,
  onClose,
  variant = NOTIFICATION_VARIANT.NEUTRAL,
  ...others
}) => {
  const [layout, setLayout] = useState<LayoutRectangle | undefined>();

  const handleLayout = (event: LayoutChangeEvent) => {
    if (event) setLayout(event.nativeEvent.layout);
  };

  useEffect(() => {
    if (!timeoutClose || !onClose) return;

    let idTimeOut;
    if (isVisible) idTimeOut = setTimeout(onClose, timeoutClose);
    else clearTimeout(idTimeOut);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const isVariantPromo = variant === NOTIFICATION_VARIANT.PROMO;

  const textColor = !isVariantPromo ? COLOR.TEXT_LIGHT : colorText ? colorText : COLOR.TEXT_PROMOTION;
  const backgroundColor = !isVariantPromo ? COLOR.BG_PRIMARY : color ? color : COLOR.BG_PROMOTION;

  return (
    <Motion
      layout={layout}
      pointer={!isVisible ? POINTER.NONE : undefined}
      timing={timing}
      type={isVisible ? MOTION_TYPES.EXPAND : MOTION_TYPES.COLLAPSE}
      value={motion ? calcMotion({ isVisible, motion }) : undefined}
      wide
      role={VIEW_ROLES.aside}
      onLayout={handleLayout}
    >
      <View
        {...others}
        style={styles(others.style, style.container)}
        backgroundColor={backgroundColor}
        nativeID={nativeID}
      >
        <View style={style.notificationContent} testID={nativeID ? `${nativeID}-container` : undefined}>
          {!isVariantPromo && (
            <Icon
              marginRight={SPACE.SPACE_2}
              name={notificationIconName[variant]}
              color={notificationIconColor[variant]}
            />
          )}

          <View style={style.textContainer}>
            {text && (
              <Text
                color={textColor}
                detail={!isVariantPromo}
                detailBold={isVariantPromo}
                display={DISPLAY.BLOCK}
                level={2}
                style={style.text}
              >
                {text}
              </Text>
            )}

            {children}
          </View>

          {onClose && (
            <View>
              <ButtonIcon
                alignSelf={ALIGN.CENTER}
                color={textColor}
                name="close_small"
                style={style.button}
                onPress={onClose}
                testID={nativeID ? `${nativeID}-close` : undefined}
                small
              />
            </View>
          )}
        </View>
      </View>
    </Motion>
  );
};

Notification.displayName = 'Notification';

export { Notification };

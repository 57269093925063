import * as DocumentPicker from 'expo-document-picker';

export type Document = {
  name: string;
  size?: number;
  uri: string;
  mimeType?: string;
  lastModified?: number;
  file?: File;
  output?: FileList | null;
};

export const pickDocument = async (accept?: string | string[], multiple?: boolean): Promise<Document | undefined> => {
  // https://docs.expo.io/versions/latest/sdk/document-picker

  const { type, ...rest } = await DocumentPicker.getDocumentAsync({
    copyToCacheDirectory: false,
    type: accept,
    multiple: multiple,
  });

  if (type !== 'cancel') {
    return { ...rest } as Document;
  }
};

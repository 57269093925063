import React from 'react';

import { COLOR, DISPLAY, FLEX_DIRECTION, SPACE, styles } from '@hooks';
import { Icon, TEXT_ROLES, Text, View } from '@primitives';

import { INFOBOX_TYPE, INFOBOX_VARIANT } from './InfoBox.definition';
import { borderRadiusVariantStyle, contentAlignStyle, style } from './InfoBox.style';
import { ALIGN } from '../../../hooks/useStyler/styler.definition';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { IconName } from '../../primitives/Icon/Icon';
import type { ViewProperties } from '../../primitives/View/View';
import type { FC, ReactNode } from 'react';

export interface InfoBoxProps {
  children?: ReactNode;
  contentAlign?: ALIGN.LEFT | ALIGN.CENTER;
  icon?: IconName;
  text?: ReactNode;
  title?: string;
  type?: INFOBOX_TYPE;
  variant?: INFOBOX_VARIANT;
}

export type InfoBoxProperties = InfoBoxProps & StylerProperties & ViewProperties;

const infoBoxBackgroundColor: Record<INFOBOX_VARIANT, COLOR> = {
  default: COLOR.BG_SURFACE,
  promo: COLOR.BG_PROMOTION,
  warning: COLOR.BG_WARNING,
};

const infoBoxColor: Record<INFOBOX_VARIANT, COLOR> = {
  default: COLOR.TEXT,
  promo: COLOR.TEXT_PROMOTION,
  warning: COLOR.TEXT,
};

export const InfoBox: FC<InfoBoxProperties> = ({
  children,
  contentAlign = ALIGN.LEFT,
  icon,
  title,
  text,
  type = INFOBOX_TYPE.ROUNDED,
  variant = INFOBOX_VARIANT.DEFAULT,
  ...others
}) => {
  return (
    <View {...others}>
      <View
        backgroundColor={infoBoxBackgroundColor[variant]}
        style={styles(style.container, borderRadiusVariantStyle[type])}
        wide
      >
        <View style={contentAlignStyle[contentAlign]} flexDirection={FLEX_DIRECTION.ROW}>
          {icon && <Icon name={icon} color={infoBoxColor[variant]} marginRight={SPACE.SPACE_2} />}
          <View style={style.textContainer}>
            {title && (
              <Text
                role={TEXT_ROLES.strong}
                color={infoBoxColor[variant]}
                detailBold
                level={1}
                marginBottom={text ? SPACE.SPACE_05 : undefined}
                style={style.paddingTop}
              >
                {title}
              </Text>
            )}

            {text && (
              <Text
                color={infoBoxColor[variant]}
                detail
                display={DISPLAY.BLOCK}
                level={2}
                style={[!title && style.paddingTop]}
              >
                {text}
              </Text>
            )}

            {children && <View marginTop={SPACE.SPACE_1}>{children}</View>}
          </View>
        </View>
      </View>
    </View>
  );
};

InfoBox.displayName = 'InfoBox';

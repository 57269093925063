import { useStoryteller } from '@lookiero/storyteller';
import React, { useState } from 'react';
import { StyleSheet } from 'react-native';

import { ButtonIcon, COLOR, FLEX_DIRECTION, Icon, InputField, SPACE, ScrollView, Text, Theme, View } from '@package';

import type { IconName, InputValue } from '@package';

const styles = StyleSheet.create({
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: 12,
  },
  input: {
    flex: 1,
  },
  clearButton: {
    paddingVertical: 17,
  },
});

type ColorKey = keyof typeof COLOR;

export const IconShowRoomChapter = () => {
  const { select } = useStoryteller();

  const allIcons = Object.keys(Theme.get().iconGlyphs) as IconName[];
  const colors: ColorKey = Object.keys(COLOR) as unknown as ColorKey;
  const [filter, setFilter] = useState<IconName[]>(allIcons);
  const [filterValue, setFilterValue] = useState<string>('');

  return (
    <View padding={SPACE.SPACE_2}>
      <View flexDirection={FLEX_DIRECTION.ROW} paddingBottom={SPACE.SPACE_6}>
        <InputField
          label={'Search by icon name'}
          name={'search'}
          onChange={(v: InputValue) => {
            setFilter(allIcons.filter((icon) => icon.includes(v as string)));
            setFilterValue(v as string);
          }}
          style={styles.input}
          value={filterValue}
        />
        <ButtonIcon
          name={'trash'}
          onPress={() => {
            setFilter(allIcons);
            setFilterValue('');
          }}
          style={styles.clearButton}
        />
      </View>

      <ScrollView scrollIndicator>
        {filter.map((name) => (
          <View style={styles.rowContainer}>
            <Icon
              color={COLOR[select('color', COLOR.ICON, { options: colors }) as keyof typeof COLOR]}
              name={name}
              key={'name'}
            />
            <Text detail level={3} marginLeft={SPACE.SPACE_4}>
              {name}
            </Text>
          </View>
        ))}
      </ScrollView>
    </View>
  );
};

IconShowRoomChapter.displayName = '🐵 Primitives/IconShowRoom';

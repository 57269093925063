import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { IMAGE_URL } from '@mocks';
import { Aurora, InputImage, Theme, useDevice } from '@package';

import type { Glyphs } from '@lookiero/aurora-iconfont';

export const InputImageChapter = () => {
  const { event, number, object, text, select } = useStoryteller();
  const { platform } = useDevice();

  const { iconGlyphs } = Theme.get();

  return (
    <Aurora>
      <InputImage
        height={number('height', 250)}
        icon={select('name', 'expand', { options: Object.keys(iconGlyphs) }) as keyof Glyphs}
        id={text('id', 'A')}
        label={text('label', 'Your picture 1 of 4')}
        src={text('src', IMAGE_URL)}
        style={object('style', {})}
        width={number('width', 187)}
        onChange={(value) => event('onChange', [value])}
        {...(platform.native && {
          sourcesMessages: {
            title: 'Add a photo',
            takePhoto: 'Take a picture',
            chooseFromLibrary: 'Choose a picture',
          },
        })}
      />
    </Aurora>
  );
};

InputImageChapter.displayName = '🧬 Organisms/InputImage';

import StyleSheet from 'react-native-extended-stylesheet';

import { capitalize } from './capitalize';

export const stylesheetColor = (color = 'text') =>
  StyleSheet.create({
    color: {
      color: `$color${capitalize(color)}`,
    },
  });

import { Animated } from 'react-native';

import { toAnimatedValue } from './toAnimatedValue';

export const calcState = ({ layout, state = {}, value = {} } = {}) => {
  const next = {};

  Object.keys(value).forEach((key) => {
    next[key] = state[key] || new Animated.Value(toAnimatedValue(key, value[key], layout));
  });

  return next;
};

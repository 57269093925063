import type { ButtonProperties, ImageProperties, LinkProperties } from '@package';
import type { Neverify } from '@types';

type WithText<T> = T & { text: string };
type WithSrc = Pick<ImageProperties, 'src'>;
type WithSrcSet = Pick<ImageProperties, 'srcSet'>;

type BannerImageProperties = (Required<WithSrc> & WithSrcSet) | (Required<WithSrcSet> & WithSrc);

type BannerButtonProperties = WithText<Required<Pick<ButtonProperties, 'onPress'>>> & Pick<ButtonProperties, 'icon'>;
type BannerWithButtons = {
  primaryButton: BannerButtonProperties;
  secondaryButton?: BannerButtonProperties;
};

type BannerLinkProperties = WithText<Required<Pick<LinkProperties, 'href' | 'onPress'>>>;
type BannerWithLink = {
  link: BannerLinkProperties;
};

type BannerOnlyWithButtons = BannerWithButtons & Neverify<BannerWithLink>;
type BannerOnlyWithLink = BannerWithLink & Neverify<BannerWithButtons>;

enum BANNER_TYPE {
  ROUNDED = 'rounded',
  SQUARED = 'squared',
}

export type {
  BannerOnlyWithButtons,
  BannerOnlyWithLink,
  BannerImageProperties,
  BannerButtonProperties,
  BannerLinkProperties,
};
export { BANNER_TYPE };

import type { NotificationProperties } from '../../components/molecules/Notification/Notification';
import type { Notification } from '@molecules';
import type { ComponentType } from 'react';

const STACK_REDUCER = {
  MOUNT: 'mount',
  SHOW: 'show',
  HIDE: 'hide',
  UNMOUNT: 'unmount',
  UNMOUNT_ALL: 'unmount_all',
} as const;

type Component = ComponentType<typeof Notification>;

type StackState = Record<
  string,
  {
    component: Component;
    props: NotificationProperties;
  }
>;

type MountAction = {
  type: typeof STACK_REDUCER.MOUNT;
  id: string;
  component: Component;
  props: NotificationProperties;
};

type ShowAction = {
  type: typeof STACK_REDUCER.SHOW;
  id: string;
};

type HideAction = {
  type: typeof STACK_REDUCER.HIDE;
  id: string;
};

type UnmountAction = {
  type: typeof STACK_REDUCER.UNMOUNT;
  id: string;
};

type UnmountAllAction = {
  type: typeof STACK_REDUCER.UNMOUNT_ALL;
};

type StackAction = MountAction | ShowAction | HideAction | UnmountAction | UnmountAllAction;

const StackReducer = (state: StackState, action: StackAction) => {
  const { type } = action;

  switch (type) {
    case STACK_REDUCER.MOUNT:
      return {
        ...state,
        [action?.id]: { component: action?.component, props: { ...action?.props, isVisible: false } },
      };
    case STACK_REDUCER.SHOW:
      return {
        ...state,
        [action?.id]: { ...state[action?.id], props: { ...state[action?.id].props, isVisible: true } },
      };
    case STACK_REDUCER.HIDE:
      return state[action?.id]
        ? { ...state, [action?.id]: { ...state[action?.id], props: { ...state[action?.id].props, isVisible: false } } }
        : state;
    case STACK_REDUCER.UNMOUNT:
      delete state[action?.id];
      return { ...state };
    case STACK_REDUCER.UNMOUNT_ALL:
      return {};
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

export { STACK_REDUCER, StackReducer };

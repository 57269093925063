import { Platform } from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginBottom: '$space2',
  },

  day: {
    borderRadius: '$borderRadiusFull',
    overflow: Platform.OS === 'android' ? 'hidden' : undefined,
    width: '$space8',
    height: '$space8',
  },

  dayText: {
    paddingTop: '$space1',
  },

  focus: undefined,

  today: {
    borderWidth: '$borderWidth2',
    borderColor: '$colorBorderInteractiveSelected',
    backgroundColor: '$colorBgInteractiveActive',
  },

  todayDisabled: {
    borderWidth: '$borderWidth2',
    borderColor: '$colorBorderInteractive',
    backgroundColor: '$colorBgInteractiveDisabled',
  },
});

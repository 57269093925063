const BANNED_PROPS = [
  'aria-controls',
  'aria-disabled',
  'aria-expanded',
  'aria-label',
  'aria-live',
  'id',
  'pointer',
  'pointerEvents',
  'tabIndex',
];

enum MOTION_TIMING {
  LINEAR = 'linear',
  SPRING = 'spring',
  QUAD = 'quad',
}

enum MOTION_TYPES {
  EXPAND = 'expand',
  COLLAPSE = 'collapse',
  SKELETON = 'skeleton',
  SLOW = 'slow',
}

export { BANNED_PROPS, MOTION_TIMING, MOTION_TYPES };

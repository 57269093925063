import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { COLOR, Progress, SPACE, View } from '@package';

export const ProgressChapter = () => {
  const { event, number, object, select } = useStoryteller();

  return (
    <View padding={SPACE.SPACE_4}>
      <Progress
        active={number('active', 3)}
        activeColor={select('activeColor', COLOR.BG_PRIMARY, { options: Object.values(COLOR) }) as COLOR}
        inactiveColor={select('inactiveColor', COLOR.BG_PRIMARY_MEDIUM, { options: Object.values(COLOR) }) as COLOR}
        length={number('length', 8)}
        style={object('style', {})}
        onPress={(index) => event('onPress', [{ index }])}
      />
    </View>
  );
};
ProgressChapter.displayName = '⚛️ Atoms/Progress';

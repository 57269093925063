interface Styles {
  [key: string]: any;
}

export const styles = (...stylesheets: Array<Styles | false | undefined | null>) =>
  stylesheets
    .flat(Infinity)
    .filter(
      (value): value is Styles => value !== false && value !== undefined && value !== null && typeof value === 'object',
    );

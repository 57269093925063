import { getCoords } from './getCoords';

import type { GestureEvent } from './getCoords';

export type GestureParam = {
  startX: number;
  startY: number;
};

export type Gesture = {
  startX: number;
  startY: number;
  up: boolean;
  down: boolean;
  left: boolean;
  right: boolean;
  deltaX: number;
  deltaY: number;
  endX: number;
  endY: number;
};

export const calcGesture = (gesture: GestureParam, event: GestureEvent): Gesture => {
  const { startX, startY } = gesture;
  const { X: endX, Y: endY } = getCoords(event);

  return {
    ...gesture,
    up: startY > endY,
    down: startY < endY,
    left: startX > endX,
    right: startX < endX,
    deltaX: endX - Number(startX),
    deltaY: endY - Number(startY),
    endX: endX,
    endY: endY,
  };
};

import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { Step, StepContainer } from '@package';

export const StepContainerChapter = () => {
  const { boolean, number, object, text } = useStoryteller();

  return (
    <StepContainer
      active={number('active', 2)}
      forceActive={boolean('forceActive', false)}
      horizontal={boolean('horizontal', false)}
      style={object('style', {})}
    >
      <Step text={text('Step 1', 'We receive your order')} />
      <Step text={text('Step 2', 'Your Personal Shopper styles your Lookiero')} />
      <Step text={text('Step 3', 'Receive your Lookiero')} />
      <Step text={text('Step 4', 'Confirm your selection')} />
    </StepContainer>
  );
};

StepContainerChapter.displayName = '⚛️ Atoms/StepContainer';

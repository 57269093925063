import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { Children, IMAGE_2x_URL, IMAGE_URL } from '@mocks';
import { Theme, Thumbnail } from '@package';

import type { Glyphs } from '@lookiero/aurora-iconfont';

export const ThumbnailChapter = () => {
  const { number, object, text, select } = useStoryteller();

  const { iconGlyphs } = Theme.get();

  return (
    <Thumbnail
      alt={text('alt', 'Image')}
      height={number('height', 250)}
      icon={
        select('icon', 'expand', {
          hint: 'Icon will only be shown if src is not set',
          options: Object.keys(iconGlyphs),
        }) as keyof Glyphs
      }
      src={text('src', 'https://images.pexels.com/photos/2820587/pexels-photo-2820587.jpeg')}
      srcSet={text('srcSet', `${IMAGE_URL} 1x, ${IMAGE_2x_URL} 2x`, { multiLine: true })}
      style={object('style', {})}
      width={number('width', 187)}
    >
      <Children />
    </Thumbnail>
  );
};

ThumbnailChapter.displayName = '⚛️ Atoms/Thumbnail';

import { IS_JEST } from '@helpers';

const emptyFn = () => {
  if (!IS_JEST) console.warn("WARN: Can't use the hook useStack without a instance of <StackProvider>");
};

const DEFAULT_STACK = {
  show: emptyFn,
  success: emptyFn,
  critical: emptyFn,
  warning: emptyFn,
  neutral: emptyFn,
  promo: emptyFn,
  hide: emptyFn,
  wipe: emptyFn,
};

export { DEFAULT_STACK };

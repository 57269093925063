import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import {
  ALIGN,
  COLOR,
  DISPLAY,
  FLEX_DIRECTION,
  POINTER,
  POSITION,
  SIZE,
  SPACE,
  SafeAreaView,
  Text,
  View,
} from '@package';

const child = {
  backgroundColor: COLOR.BG_SUCCESS,
  margin: 'XXS',
  padding: SPACE.SPACE_2,
};

export const useStylerChapter = () => {
  const { object, select } = useStoryteller();

  return (
    <SafeAreaView>
      <View
        alignContent={select('flex.alignContent', ALIGN.START, { options: Object.values(ALIGN) }) as ALIGN}
        alignItems={select('flex.alignItems', ALIGN.START, { options: Object.values(ALIGN) }) as ALIGN}
        alignSelf={select('flex.alignSelf', ALIGN.START, { options: Object.values(ALIGN) }) as ALIGN}
        backgroundColor={select('backgroundColor', COLOR.BG_PROMOTION, { options: Object.values(COLOR) }) as COLOR}
        borderColor={select('borderColor', COLOR.BG_SUCCESS, { options: Object.values(COLOR) }) as COLOR}
        display={select('flex.display', DISPLAY.FLEX, { options: Object.values(DISPLAY) }) as DISPLAY}
        flex={select('flex.flex', SIZE.XS, { options: Object.values(SIZE) }) as SIZE}
        flexDirection={
          select('flex.flexDirection', FLEX_DIRECTION.ROW, { options: Object.values(FLEX_DIRECTION) }) as FLEX_DIRECTION
        }
        justifyContent={select('flex.justifyContent', ALIGN.START, { options: Object.values(ALIGN) }) as ALIGN}
        layer={select('layer', SIZE.XS, { options: Object.values(SIZE) }) as SIZE}
        marginTop={select('margins.marginTop', SIZE.RESET, { options: Object.values(SIZE) })}
        marginRight={select('margins.marginRight', SIZE.RESET, { options: Object.values(SIZE) })}
        marginBottom={select('margins.marginBottom', SIZE.RESET, { options: Object.values(SIZE) })}
        marginLeft={select('margins.marginLeft', SIZE.RESET, { options: Object.values(SIZE) })}
        marginHorizontal={select('margins.marginHorizontal', SIZE.RESET, { options: Object.values(SIZE) })}
        marginVertical={select('margins.marginVertical', SIZE.RESET, { options: Object.values(SIZE) })}
        paddingTop={select('paddings.paddingTop', SIZE.RESET, { options: Object.values(SIZE) })}
        paddingRight={select('paddings.paddingRight', SIZE.RESET, { options: Object.values(SIZE) })}
        paddingBottom={select('paddings.paddingBottom', SIZE.RESET, { options: Object.values(SIZE) })}
        paddingLeft={select('paddings.paddingLeft', SIZE.RESET, { options: Object.values(SIZE) })}
        paddingHorizontal={select('paddings.paddingHorizontal', SIZE.RESET, { options: Object.values(SIZE) })}
        paddingVertical={select('paddings.paddingVertical', SIZE.RESET, { options: Object.values(SIZE) })}
        pointer={select('pointer', POINTER.NONE, { options: Object.values(POINTER) }) as POINTER}
        position={select('position', POSITION.ABSOLUTE, { options: Object.values(POSITION) }) as POSITION}
        textAlign={select('textAlign', ALIGN.LEFT, { options: Object.values(ALIGN) }) as ALIGN}
        style={object('style', { opacity: 0.8 })}
        wide
      >
        <View {...child} color={select('color', COLOR.TEXT, { options: Object.values(COLOR) }) as COLOR}>
          <Text>1</Text>
        </View>

        <View {...child}>
          <Text>2</Text>
        </View>

        <View {...child}>
          <Text>3</Text>
        </View>
      </View>
    </SafeAreaView>
  );
};

useStylerChapter.displayName = '🪝 Hooks/useStyler';

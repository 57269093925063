import { useStoryteller } from '@lookiero/storyteller';
import React, { useState } from 'react';

import { InputField, LAYOUT, Theme } from '@package';

import type { Glyphs } from '@lookiero/aurora-iconfont';

const options = [
  { name: 'Option:1', value: '1' },
  { name: 'Option:2', value: '2' },
  { name: 'Option:3', value: '3' },
  { name: 'Option:4', value: '4' },
  { name: 'Option:5', value: '5' },
  { name: 'Option:6', value: '6' },
];

export const InputFieldSelectChapter = () => {
  const { boolean, event, object, text, select } = useStoryteller();
  const [value, setValue] = useState(undefined);

  const { iconGlyphs } = Theme.get();

  return (
    <InputField
      defaultValue={text('defaultValue', options[2].value)}
      disabled={boolean('disabled', false)}
      error={boolean('error', false)}
      icon={select('name', 'arrow_down', { options: Object.keys(iconGlyphs) }) as keyof Glyphs}
      emptyOptionText={text('emptyOptionText', 'empty-option-text-lookiero')}
      hint={text('hint', 'hint-lookiero')}
      id={text('id', 'id-lookiero')}
      label={text('label', 'label-lookiero')}
      margin={LAYOUT.XXS}
      name={text('name', 'name-lookiero')}
      options={options}
      style={object('style', {})}
      value={value}
      // @ts-expect-error Just for better UX in Storyteller
      onBlur={() => event('onBlur')}
      onChange={(value) => {
        // @ts-expect-error Just for better UX in Storyteller
        setValue(value);
        // @ts-expect-error Just for better UX in Storyteller
        event('onChange', value);
      }}
      onError={(error) => error && event('onError', [{ error }])}
      // @ts-expect-error Just for better UX in Storyteller
      onFocus={() => event('onFocus')}
    />
  );
};

InputFieldSelectChapter.displayName = '⚛️ Atoms/InputField (Select)';

import React from 'react';

import { COLOR, SPACE, styles } from '@hooks';
import { Text, VIEW_ROLES, View } from '@primitives';

import { style } from './TableRow.style';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { ViewProperties } from '../../primitives/View/View';
import type { FC } from 'react';

export interface TableRowProps {
  dataSource?: Record<string, string>;
  schema: Record<string, string>;
}

export type TableRowProperties = TableRowProps & StylerProperties & ViewProperties;

const TableRow: FC<TableRowProperties> = ({ dataSource, schema, ...others }) => {
  const isHead = dataSource === undefined;
  const as = isHead ? 'th' : 'td';

  const fields = Object.keys(schema);

  return (
    <View {...others} role={VIEW_ROLES.tr} style={styles(style.tableRow, others.style)}>
      {fields.map((field, index) => (
        <View
          as={as}
          key={field}
          paddingRight={index < fields.length - 1 ? SPACE.SPACE_4 : undefined}
          style={[style.row, style[as]]}
        >
          <Text color={isHead ? COLOR.TEXT_MEDIUM : COLOR.TEXT} detail>
            {isHead ? schema[field] : dataSource[field]}
          </Text>
        </View>
      ))}
    </View>
  );
};

TableRow.displayName = 'TableRow';

export { TableRow };

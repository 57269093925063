import React from 'react';

import { InputOption, Thumbnail } from '@atoms';
import { ALIGN, FLEX_DIRECTION, POSITION, SIZE, styles } from '@hooks';
import { MOTION_TIMING, MOTION_TYPES, Motion, PRESSABLE_OPACITY, Text, Touchable } from '@primitives';

import { style } from './OptionImage.style';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { TouchableProperties } from '../../primitives/Touchable/Touchable';
import type { FC } from 'react';
import type { ImageResizeMode } from 'react-native';

export interface OptionImageProps {
  checked?: boolean;
  disabled?: boolean;
  height?: number;
  id?: string;
  imageResizeMode?: ImageResizeMode; // 'center' | 'cover' | 'contain' | 'none' | 'stretch';
  name?: string;
  src: string;
  srcSet?: string;
  title: string;
  value: string;
  width?: number;

  onChange?: (value: string) => void;
}

export type OptionImageProperties = OptionImageProps & StylerProperties & TouchableProperties;

const OptionImage: FC<OptionImageProperties> = ({
  checked,
  children,
  disabled,
  height,
  id,
  imageResizeMode,
  name,
  src,
  srcSet,
  title,
  value,
  width,
  onChange,
  ...others
}) => {
  return (
    <Touchable
      {...others}
      onPress={!disabled && onChange ? () => onChange(value) : undefined}
      pressableOpacity={PRESSABLE_OPACITY.OPTION_IMAGE}
      style={styles(style.containerBase, { height, width }, others.style)}
    >
      <Thumbnail
        {...{
          height,
          src,
          srcSet,
          resizeMode: imageResizeMode,
          style: styles(others.style),
          width,
        }}
      />
      <Text hidden>{title}</Text>
      <Motion
        alignItems={ALIGN.CENTER}
        flexDirection={FLEX_DIRECTION.COLUMN}
        justifyContent={ALIGN.CENTER}
        layer={SIZE.XS}
        position={POSITION.ABSOLUTE}
        style={style.overlay}
        timing={MOTION_TIMING.SPRING}
        type={checked ? MOTION_TYPES.EXPAND : MOTION_TYPES.COLLAPSE}
        value={{ opacity: checked ? 1 : 0 }}
      >
        <InputOption checked id={id} name={name as string} style={style.inputOption} value={value} />
      </Motion>
      {children}
    </Touchable>
  );
};

OptionImage.displayName = 'OptionImage';

export { OptionImage };

import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { ButtonIcon, COLOR, Theme } from '@package';

import type { Glyphs } from '@lookiero/aurora-iconfont';

export const ButtonIconChapter = () => {
  const { boolean, event, select, object } = useStoryteller();

  const { iconGlyphs } = Theme.get();

  return (
    <ButtonIcon
      // @ts-expect-error Just for better UX in Storyteller
      color={COLOR[select('color', undefined, { options: Object.keys(COLOR) })]}
      // @ts-expect-error Just for better UX in Storyteller
      colorNotification={COLOR[select('colorNotification', undefined, { options: Object.keys(COLOR) })]}
      name={select('name', 'menu', { options: Object.keys(iconGlyphs) }) as keyof Glyphs}
      notification={boolean('notification', false)}
      small={boolean('small', false)}
      style={object('style', {})}
      // @ts-expect-error Just for better UX in Storyteller
      onPress={() => event('onPress')}
    />
  );
};

ButtonIconChapter.displayName = '⚛️ Atoms/ButtonIcon';

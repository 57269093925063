import { LinearGradient } from 'expo-linear-gradient';
import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';

import { COLOR, FLEX_DIRECTION, POINTER, POSITION, SIZE, styles, useStyler } from '@hooks';
import { ScrollView, VIEW_ROLES, View } from '@primitives';
import { Theme } from '@theming';

import { ITEM_HEIGHT, VISIBLE_ITEMS } from './WheelPicker.definition';
import { style } from './WheelPicker.style';
import { WheelPickerItem } from './WheelPickerItem';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { ScrollPayload } from '../../primitives/ScrollView/ScrollView';
import type { ViewProperties } from '../../primitives/View/View';
import type { FC } from 'react';

export interface WheelPickerProps {
  disabled?: boolean;
  options: string[];
  selected?: number;
  selectOnScroll?: boolean;
  testID?: string;

  onChange: (index: number) => void;
}

export type WheelPickerProperties = WheelPickerProps & StylerProperties & ViewProperties;

const WheelPicker: FC<WheelPickerProperties> = ({
  disabled = false,
  onChange,
  options = [],
  selected,
  selectOnScroll = true,
  testID,
  ...others
}) => {
  const [current, setCurrent] = useState<number | undefined>();

  const { colorBgBase, colorBgBaseTransparent } = Theme.get();

  useEffect(() => {
    if (selected !== current) setCurrent(selected);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handlePressItem = (nextOption: number) => {
    handleChange(nextOption);
  };

  const handleScroll = ({ Y }: ScrollPayload) => {
    handleChange(Y < ITEM_HEIGHT ? 0 : Math.round(Y / ITEM_HEIGHT));
  };

  const handleChange = (nextOption: number) => {
    if (nextOption !== current) {
      setCurrent(nextOption);
      if (onChange) onChange(nextOption);
    }
  };

  const scrollable = options.length > VISIBLE_ITEMS;
  const gradientProps = useStyler({ wide: true, style: style.gradient });
  const scrollMargin = ITEM_HEIGHT * 2;

  return (
    <View {...others} position={POSITION.RELATIVE} wide>
      <ScrollView
        animated={!disabled && Platform.OS === 'web'}
        height={(scrollable ? VISIBLE_ITEMS : options.length) * ITEM_HEIGHT}
        nestedScrollEnabled
        role={VIEW_ROLES.ul}
        scrollTo={(current || 0) * ITEM_HEIGHT}
        snapInterval={ITEM_HEIGHT}
        style={styles(others.style)}
        onScroll={!disabled && selectOnScroll && scrollable ? handleScroll : undefined}
        testID={testID}
      >
        {options.map((option, index) => {
          const first = index === 0;
          const last = index === options.length - 1;

          const marginTop = first ? scrollMargin : 0;
          const marginBottom = last ? scrollMargin : 0;

          return (
            <WheelPickerItem
              checked={index === current}
              disabled={disabled}
              key={option}
              scrollable={scrollable}
              testID={`${testID}-${index}`}
              title={option}
              onPress={() => handlePressItem(index)}
              style={scrollable && { marginTop, marginBottom }}
            />
          );
        })}
      </ScrollView>

      {scrollable && (
        <View
          flexDirection={FLEX_DIRECTION.COLUMN}
          layer={SIZE.XS}
          pointerEvents={POINTER.NONE}
          position={POSITION.ABSOLUTE}
          style={style.dartBoard}
          wide
        >
          <LinearGradient {...gradientProps} colors={[colorBgBase, colorBgBaseTransparent]} />
          <View
            borderColor={disabled ? COLOR.BORDER_INTERACTIVE : undefined}
            style={[style.selector, disabled && style.selectorDisabled]}
            wide
          />
          <LinearGradient {...gradientProps} colors={[colorBgBaseTransparent, colorBgBase]} />
        </View>
      )}
    </View>
  );
};

WheelPicker.displayName = 'WheelPicker';

export { WheelPicker };

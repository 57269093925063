// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { ALIGN, COLOR, TEXT_ROLES, Text } from '@package';

export const TextChapter = () => {
  const { boolean, object, select, text } = useStoryteller();

  return (
    <Text
      align={ALIGN[select('align', undefined, { options: Object.keys(ALIGN) })]}
      color={COLOR[select('color', COLOR.TEXT, { options: Object.keys(COLOR) })]}
      heading={boolean('heading', false)}
      action={boolean('action', false)}
      detail={boolean('detail', false)}
      detailBold={boolean('detailBold', false)}
      level={select('level', '2', { options: ['1', '2', '3'] })}
      lineThrough={boolean('lineThrough', false)}
      ellipsizeMode={boolean('ellipsizeMode', false)}
      hidden={boolean('hidden', false)}
      role={select('role', undefined, { options: Object.values(TEXT_ROLES) })}
      selectable={boolean('selectable', true)}
      style={object('style', {})}
      underlined={boolean('underlined', false)}
      upperCase={boolean('upperCase', false)}
    >
      {text('text', 'Lorem Ipsum is simply dummy text...', { multiLine: true })}
    </Text>
  );
};

TextChapter.displayName = '🐵 Primitives/Text';

export const FLEXBOX_STYLESHEETS = {
  alignContentStart: {
    alignContent: 'flex-start',
  },

  alignContentCenter: {
    alignContent: 'center',
  },

  alignContentEnd: {
    alignContent: 'flex-end',
  },

  alignItemsStart: {
    alignItems: 'flex-start',
  },

  alignItemsCenter: {
    alignItems: 'center',
  },

  alignItemsEnd: {
    alignItems: 'flex-end',
  },

  alignSelfAuto: {
    alignSelf: 'auto',
  },

  alignSelfStretch: {
    alignSelf: 'stretch',
  },

  alignSelfStart: {
    alignSelf: 'flex-start',
  },

  alignSelfCenter: {
    alignSelf: 'center',
  },

  alignSelfEnd: {
    alignSelf: 'flex-end',
  },

  flexXS: {
    flex: 1,
  },

  flexS: {
    flex: 2,
  },

  flexM: {
    flex: 3,
  },

  flexL: {
    flex: 4,
  },

  flexXL: {
    flex: 5,
  },

  flexDirectionColumn: {
    flexDirection: 'column',
  },

  flexDirectionRow: {
    flexDirection: 'row',
  },

  flexWrapWrap: {
    flexWrap: 'wrap',
  },

  justifyContentStart: {
    justifyContent: 'flex-start',
  },

  justifyContentCenter: {
    justifyContent: 'center',
  },

  justifyContentEnd: {
    justifyContent: 'flex-end',
  },

  justifyContentAround: {
    justifyContent: 'space-around',
  },

  justifyContentBetween: {
    justifyContent: 'space-between',
  },

  wide: {
    width: '100%',
  },
};

import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { COLOR, Icon, Theme } from '@package';

export const IconChapter = () => {
  const { select } = useStoryteller();

  const { iconGlyphs } = Theme.get();

  return (
    <Icon
      // @ts-expect-error Just for better UX in Storyteller
      color={COLOR[select('color', COLOR.ICON, { options: Object.keys(COLOR) })]}
      // @ts-expect-error Just for better UX in Storyteller
      name={select('name', 'lookiero', { options: Object.keys(iconGlyphs) })}
      // style={object('style', {})}
    />
  );
};

IconChapter.displayName = '🐵 Primitives/Icon';

import { useStoryteller } from '@lookiero/storyteller';
import React, { useState } from 'react';

import {
  ALIGN,
  BUTTON_VARIANT,
  Button,
  COLOR,
  FLEX_DIRECTION,
  SPACE,
  ScrollView,
  Text,
  VIEW_ROLES,
  View,
} from '@package';

import type { Level } from '@package';

const CHILDS = 16;
const CHILD_SIZE = 192;
const CHILD_MARGIN = 8;

const buttonProps = { margin: SPACE.SPACE_4, small: true, variant: BUTTON_VARIANT.SECONDARY, wide: false };
const textProps = { margin: SPACE.SPACE_2, _detail: true, level: 2 as Level };

const Child = ({ index }: { index: number }) => (
  <View
    backgroundColor={COLOR.ACCENT}
    justifyContent={ALIGN.CENTER}
    margin={SPACE.SPACE_2}
    style={{ height: CHILD_SIZE, width: CHILD_SIZE }}
  >
    <Text align={ALIGN.CENTER}>{index}</Text>
    <Text align={ALIGN.CENTER}>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</Text>
  </View>
);
export const ScrollViewChapter = () => {
  const { boolean, event, number, object, select } = useStoryteller();

  const [scrollTo, setScrollTo] = useState(0);

  const props = {
    animated: boolean('animated', true),
    contentContainerStyle: object('contentContainerStyle', {}),
    height: number('height', CHILD_SIZE + CHILD_MARGIN * 2),
    horizontal: boolean('horizontal', false),
    role: select('role', VIEW_ROLES.article, { options: Object.values(VIEW_ROLES) }) as VIEW_ROLES,
    scrollEventThrottle: number('scrollEventThrottle', 0, { hint: 'Only available in iOS.' }),
    scrollIndicator: boolean('scrollIndicator', false),
    scrollTo: number('scrollTo'),
    scrollToEnd: boolean('scrollToEnd', false),
    snapInterval: number('snapInterval', CHILD_SIZE, { hint: 'Only available in iOS.' }),
    style: object('style', {
      backgroundColor: '#e7e7e7',
    }),
    width: number('width', CHILD_SIZE + CHILD_MARGIN * 2),
    // @ts-expect-error Just for better UX in Storyteller
    onScroll: (e) => event('onScroll', e),
  };

  return (
    <View alignItems={ALIGN.CENTER} margin={SPACE.SPACE_2}>
      <Text {...textProps}>Basic example</Text>
      <ScrollView {...props} marginBottom={SPACE.SPACE_4}>
        {[...Array(CHILDS).keys()].map((index) => (
          <Child key={index} index={index} />
        ))}
      </ScrollView>

      <Text {...textProps}>Scrolling Programmatically</Text>
      <ScrollView {...props} scrollTo={scrollTo}>
        {[...Array(CHILDS).keys()].map((index) => (
          <Child key={index} index={index} />
        ))}
      </ScrollView>

      <View flexDirection={FLEX_DIRECTION.ROW}>
        <Button {...buttonProps} disabled={scrollTo < CHILD_SIZE} onPress={() => setScrollTo(scrollTo - CHILD_SIZE)}>
          Backwards
        </Button>
        <Button
          {...buttonProps}
          disabled={scrollTo > CHILD_SIZE * (CHILDS - 1)}
          onPress={() => setScrollTo(scrollTo + (CHILD_SIZE + CHILD_MARGIN * 2))}
        >
          Forwards
        </Button>
      </View>
    </View>
  );
};

ScrollViewChapter.displayName = '🐵 Primitives/ScrollView';

import React, { useEffect, useState } from 'react';

import { ButtonIcon } from '@atoms';
import { UTC, getToday } from '@helpers';
import { COLOR } from '@hooks';
import { Text, View } from '@primitives';

import { DAYS, VISIBLE_WEEKS } from './Calendar.definition';
import { style } from './Calendar.style';
import { getFirstDateOfMonth, getHeader, getWeekDays, getWeekNumber } from './helpers';
import { Week } from './Week';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { ViewProperties } from '../../primitives/View/View';
import type { FC } from 'react';

export interface CalendarProps extends StylerProperties {
  disabledDates?: string[];
  disabledPast?: boolean;
  disabledToday?: boolean;
  formatValue?: string;
  from?: string;
  locale?: string;
  to?: string;

  onChange?: (date: Date) => void;
  value?: string;
}

export type CalendarProperties = CalendarProps & StylerProperties & ViewProperties;

export const Calendar: FC<CalendarProperties> = ({
  disabledDates = [],
  disabledPast = false,
  disabledToday = false,
  from,
  locale,
  to,
  value,
  onChange,
  ...others
}) => {
  const [instance, setInstance] = useState(getFirstDateOfMonth(getToday()));
  const [selected, setSelected] = useState<Date | undefined>(undefined);

  useEffect(() => {
    if (!value) return;
    setInstance(getFirstDateOfMonth(new Date(value)));
    setSelected(UTC(new Date(value)));
  }, [value]);

  const handleChange = (date: Date) => {
    setSelected(date);
    if (onChange) onChange(date);
  };

  const handleMonth = (month: number) =>
    setInstance(getFirstDateOfMonth(new Date(instance.getFullYear(), instance.getMonth() + month)));

  const instanceTS = instance.getTime();
  const todayMonthTS = getFirstDateOfMonth(getToday()).getTime();

  const disabledPrevious =
    (disabledPast && instanceTS <= todayMonthTS) ||
    (from ? instanceTS <= getFirstDateOfMonth(new Date(from)).getTime() : false);
  const disabledNext = to ? instanceTS >= getFirstDateOfMonth(new Date(to)).getTime() : false;

  const weekNumber = getWeekNumber(instance);
  const weekdays = getWeekDays(locale);

  return (
    <View {...others}>
      <View style={style.container}>
        <Text action style={style.month}>
          {getHeader(instance, locale)}
        </Text>
        <ButtonIcon
          color={disabledPrevious ? COLOR.ICON_DISABLED : undefined}
          disabled={disabledPrevious}
          name="arrow_left"
          testID="previous-month"
          small
          onPress={() => handleMonth(-1)}
          style={style.icon}
        />
        <ButtonIcon
          color={disabledNext ? COLOR.ICON_DISABLED : undefined}
          disabled={disabledNext}
          name="arrow_right"
          testID="next-month"
          small
          onPress={() => handleMonth(1)}
          style={style.icon}
        />
      </View>

      <View style={style.daysContainer}>
        {DAYS.map((day) => (
          <Text color={COLOR.TEXT_MEDIUM} level={2} key={day} upperCase style={style.day}>
            {weekdays[day]}
          </Text>
        ))}
      </View>

      {VISIBLE_WEEKS.map((week) => (
        <Week
          {...others}
          disabledDates={disabledDates}
          disabledPast={disabledPast}
          disabledToday={disabledToday}
          from={from}
          to={to}
          selected={selected}
          key={week}
          month={instance.getMonth()}
          number={weekNumber + week}
          year={instance.getFullYear()}
          onPress={handleChange}
        />
      ))}
    </View>
  );
};

Calendar.displayName = 'Calendar';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Children, cloneElement, useEffect, useMemo, useState } from 'react';

import { INPUT_TYPES, VIEW_ROLES, View } from '@primitives';

import { BOOLEAN_TYPES } from './Form.definition';
import { getChildrenErrors, getChildrenValues, getField, groupState } from './helpers';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { ViewProperties } from '../../primitives/View/View';
import type { FC, ReactNode, SyntheticEvent } from 'react';

export interface FormProps {
  children?: ReactNode;
  debounce?: number;
  schema?: unknown;
  validateOnMount?: boolean;
  onBlur?: () => void;

  onChange?: (value: Record<string, unknown>) => void;

  onError?: (nextError: Record<string, string>, hasError: boolean) => void;

  onFocus?: (field: string, event: SyntheticEvent) => void;

  onSubmit?: (values: unknown, state: unknown, event: SyntheticEvent) => void;
}

export type FormProperties = FormProps & StylerProperties & ViewProperties;

const Form: FC<FormProperties> = ({
  children,
  debounce = 0,
  schema = {},
  validateOnMount = false,
  onBlur,
  onChange,
  onError,
  onFocus,
  onSubmit,
  ...others
}) => {
  const [error, setError] = useState({});
  const [initialValue, setInitialValue] = useState({});
  const [touched, setTouched] = useState({});
  const [values, setValues] = useState({});

  useEffect(() => {
    const nextValues = getChildrenValues(children);
    const nextChildrenKeys = Object.keys(nextValues).sort();

    if (JSON.stringify(nextChildrenKeys) !== JSON.stringify(Object.keys(initialValue).sort())) {
      setInitialValue(nextValues);
      setValues(nextValues);

      if (validateOnMount) {
        const nextError = getChildrenErrors({ children, schema, values: nextValues, touched, forceCheck: true });
        setError(nextError);
        onError && onError(nextError);
      } else {
        setError({});
      }
      setTouched({});
    }
    // ! TODO: Probably handleChange() sets the value correctly, this condition is not necessary.
    // else {
    //   const collision = nextChildrenKeys.some((key) => JSON.stringify(values[key]) !== JSON.stringify(nextValues[key]));
    //   if (collision) setValues(nextValues);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  useEffect(() => {
    if (!onChange || values === initialValue) return;

    const timer = setTimeout(() => onChange(values, groupState({ initialValue, value: values, touched })), debounce);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleChange = (field, fieldValue) => {
    setValues(() => {
      const nextValues = { ...values, [field]: fieldValue };

      handleError(nextValues, false);

      return nextValues;
    });
  };

  const handleError = (currentValues, forceCheck = false) => {
    const nextError = getChildrenErrors({ children, schema, values: currentValues, touched, forceCheck });
    const hasError = Object.keys(nextError).length > 0;
    const changed = JSON.stringify(error) !== JSON.stringify(nextError);

    if (changed) {
      setError(nextError);
      onError && onError(nextError, hasError);
    }

    return { changed, errors: nextError, hasError };
  };

  const handleFocus = (field, event) => {
    setTouched({ ...touched, [field]: true });
    if (onFocus) onFocus(field, event);
  };

  const handleSubmit = (event) => {
    const { errors, hasError } = handleError(values, true);

    if (hasError && onError) onError(errors, hasError);
    else if (onSubmit) onSubmit(values, groupState({ initialValue, value: values, touched }), event);
  };

  return useMemo(
    () => (
      <View {...others} role={VIEW_ROLES.form}>
        {Children.map(children, (child, index) => {
          if (!child) return;

          const { props = {} } = child || {};
          const { type } = props;
          const field = getField(props);

          return cloneElement(child, {
            key: index,
            ...(field
              ? {
                  ...props,
                  ...schema[field],
                  error: error[field] !== undefined,
                  onBlur: onBlur ? (event) => onBlur(field, event) : undefined,
                  onChange: (value) => handleChange(field, value),
                  onFocus: !BOOLEAN_TYPES.includes(type) ? (event) => handleFocus(field, event) : undefined,
                  ...(Object.keys(INPUT_TYPES).includes(type) || !props.options
                    ? { onSubmitEditing: handleSubmit }
                    : undefined),
                }
              : type === 'submit'
                ? { ...props, onPress: handleSubmit }
                : undefined),
          });
        })}
      </View>
    ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [children, error, others, schema],
  );
};

Form.displayName = 'Form';

export { Form };

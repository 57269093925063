// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { createRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { getInputErrors } from '@helpers';
import { ALIGN, COLOR, FLEX_DIRECTION, SIZE, styles } from '@hooks';
import { Input, KEYBOARD_TYPES, Text, View } from '@primitives';

import { style } from './Date.style';

import type { StylerProperties } from '../../../../../hooks/useStyler/styler.definition';
import type { InputProperties } from '../../../../primitives/Input/Input';
import type { FC } from 'react';
import type { KeyboardTypeOptions, NativeSyntheticEvent, TextInput, TextInputFocusEventData } from 'react-native';

type FormatPart = 'DD' | 'MM' | 'YYYY';
type FormatType = 'D' | 'M' | 'Y';
interface FormatEntry {
  label: FormatPart;
  type: FormatType;
  length: number;
}
type DateFormat = 'DD/MM/YYYY' | 'MM/DD/YYYY' | 'YYYY/MM/DD' | 'YYYY/DD/MM';

const DELIMITER = '/';
const createMap = (value: DateFormat): FormatEntry[] =>
  value
    .split(DELIMITER)
    .map((part) => ({ label: part as FormatPart, type: part[0] as FormatType, length: part.length || 0 }));

export interface DateProps {
  defaultValue?: string;
  format?: DateFormat;
  formatValue?: DateFormat;
  max?: string;
  min?: string;
  value?: string;
}

export type DateProperties = DateProps & StylerProperties & InputProperties;

const Date: FC<DateProperties> = forwardRef(
  (
    {
      accessibilityLabel,
      defaultValue,
      format = 'DD/MM/YYYY',
      formatValue = 'YYYY/MM/DD',
      max,
      min,
      value,
      onBlur,
      onChange,
      onError,
      onFocus,
      ...others
    },
    ref,
  ) => {
    const [values, setValues] = useState(['', '', '']);
    const [showPlaceholders, setShowPlaceholders] = useState([true, true, true]);
    const [focus, setFocus] = useState(false);

    const formatMap = createMap(format);
    const formatValueMap = createMap(formatValue);
    const dayRef = createRef<TextInput>();
    const monthRef = createRef<TextInput>();
    const yearRef = createRef<TextInput>();

    useEffect(() => {
      setValues((prevValues) => {
        const newValues = [...prevValues];
        (value || defaultValue || '').split(DELIMITER).forEach((part: FormatPart, index: number) => {
          newValues[index] = part;
        });
        return newValues;
      });
    }, [value, defaultValue]);

    const getRef = (type: FormatType) => (type === 'D' ? dayRef : type === 'M' ? monthRef : yearRef);

    useImperativeHandle(ref, () => getRef(formatMap[0].type).current);

    const handleBlur = (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
      const empty = values.filter((part = '') => part.length !== 0).length === 0;
      empty && onBlur && onBlur(event);
    };

    const handleChange = (index: number, value = '') => {
      const { length, type } = formatValueMap[index];

      // State
      const nextValue = value.toString().substring(0, length).trim();
      const nextValues = JSON.parse(JSON.stringify(values));
      nextValues[index] = nextValue;
      setValues(nextValues);

      // Track placeholder visibility
      const nextShowPlaceholders = [...showPlaceholders];
      nextShowPlaceholders[index] = nextValue.length === 0;
      setShowPlaceholders(nextShowPlaceholders);

      // Events
      const errors = getInputErrors({ ...others, value: nextValues.join('/'), min, max });
      onError && onError(errors);

      if (!errors) {
        const value = parseValue(nextValues);
        onChange && onChange(value);
        onBlur && onBlur({ customEvent: 'blurEvent' });
      }

      // Tab index
      if (nextValue.length === length) {
        const nextInputIndex = formatMap.findIndex((part) => part.type === type) + 1;
        if (formatMap[nextInputIndex]) getRef(formatMap[nextInputIndex].type).current?.focus();
      }
    };

    const handleFocus = (index: number, event: NativeSyntheticEvent<TextInputFocusEventData>) => {
      if (!focus) {
        setFocus(true);
        getRef(formatMap[0].type).current?.focus();
      }
      onFocus && onFocus(event);
    };

    const parseValue = (values: string[]): string =>
      values
        .map((part, index) => {
          const { length } = formatValueMap[index];
          return part.padStart(length, '0');
        })
        .join(DELIMITER);

    return (
      <View alignItems={ALIGN.CENTER} flexDirection={FLEX_DIRECTION.ROW}>
        {formatMap.map(({ type, label, length }, index) => {
          const firstItem = index === 0;
          const lastItem = index === formatMap.length - 1;
          const mapIndex = formatValueMap.findIndex((item) => item.type === type);
          const fieldAccessibilityLabel = `${accessibilityLabel ? accessibilityLabel + '-' : ''}${label}`;
          return (
            <View
              key={type}
              alignItems={ALIGN.CENTER}
              flex={lastItem ? SIZE.XS : undefined}
              flexDirection={FLEX_DIRECTION.ROW}
            >
              <Input
                {...others}
                accessibilityLabel={fieldAccessibilityLabel}
                id={`${others.id}-${type}`}
                placeholder={label}
                ref={getRef(type)}
                style={styles(
                  style.input,
                  !lastItem && style[`length${length}`],
                  firstItem && style.firstInput,
                  lastItem && style.lastInput,
                  others.style,
                )}
                textAlign={ALIGN.LEFT}
                testID={others.testID ? `${others.testID}-${type}` : undefined}
                keyboardType={KEYBOARD_TYPES.number as KeyboardTypeOptions}
                value={values[mapIndex] || ''}
                onBlur={(event) => (onBlur ? handleBlur(event) : undefined)}
                onChange={(value) => handleChange(mapIndex, value)}
                onFocus={(event) => handleFocus(index, event)}
              />
              {!lastItem && (
                <Text
                  color={others.disabled ? COLOR.TEXT_MEDIUM_LIGHT : COLOR.TEXT_MEDIUM}
                  style={style.inputSeparator}
                >
                  /
                </Text>
              )}
            </View>
          );
        })}
      </View>
    );
  },
);

Date.displayName = 'InputFieldDate';

export { Date };

import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { COLOR, View } from '@package';

export const ViewChapter = () => {
  const { object } = useStoryteller();

  return <View backgroundColor={COLOR.BG_SUCCESS} role="header" style={object('style', { height: 128, width: 128 })} />;
};

ViewChapter.displayName = '🐵 Primitives/View';

import React from 'react';

import { COLOR, DISPLAY, SIZE, styles, useId } from '@hooks';
import { Icon, TEXT_ROLES, Text, Touchable } from '@primitives';

import { getVariant } from './helpers';
import { OPTION_VARIANT } from './Option.definition';
import { style } from './Option.style';
import { InputOption } from '../InputOption';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { IconName } from '../../primitives/Icon/Icon';
import type { TouchableProperties } from '../../primitives/Touchable/Touchable';
import type { SWITCH_VARIANT } from '@primitives';
import type { FC } from 'react';

export interface OptionProps {
  checked?: boolean;
  disabled?: boolean;
  icon?: IconName;
  name: string;
  small?: boolean;
  title: string;
  type?: SWITCH_VARIANT;
  unselected?: boolean;
  value: string;
  variant?: OPTION_VARIANT;

  onChange?: (value: string) => void;
}

export type OptionProperties = OptionProps & StylerProperties & TouchableProperties;

const Option: FC<OptionProperties> = ({
  checked,
  disabled,
  icon,
  name,
  small,
  title,
  type,
  unselected,
  value,
  variant = OPTION_VARIANT.CARD,
  onChange,
  ...others
}) => {
  const uid = useId();

  const handleChange = () => {
    if (onChange) {
      onChange(value);
    }
  };

  const {
    backgroundColor,
    borderColor,
    label = {},
    style: variantStyle = [],
    styleInput: variantStyleInput = [],
    visibleSwitch,
  } = getVariant(variant, {
    checked,
    disabled,
    small,
    unselected,
  });

  return (
    <Touchable
      {...others}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      style={styles(style.container, ...variantStyle, !visibleSwitch && style.noVisibleSwitch, others.style)}
      onPress={!disabled && onChange ? handleChange : undefined}
      wide={!small}
    >
      <InputOption
        checked={!unselected ? checked : undefined}
        disabled={disabled}
        display={visibleSwitch ? undefined : DISPLAY.NONE}
        id={uid}
        name={name}
        type={type}
        value={value}
        style={[style.input, variantStyleInput]}
      />
      {!!icon && <Icon color={label.color || COLOR.ICON_LIGHT} name={icon} marginRight={SIZE.M} />}
      <Text {...label} ellipsizeMode={small} flex={others.flex} role={TEXT_ROLES.label}>
        {title}
      </Text>
    </Touchable>
  );
};

// ! Will be deprecated in v4 ------------------------------------------------
const OptionButton: FC<OptionProperties> = (others) => <Option {...others} variant={OPTION_VARIANT.BUTTON} />;

const OptionCard: FC<OptionProperties> = (others) => <Option {...others} variant={OPTION_VARIANT.CARD} />;

const OptionPill: FC<OptionProperties> = (others) => <Option {...others} variant={OPTION_VARIANT.PILL} />;
// !--------------------------------------------------------------------------

Option.displayName = 'Option';
OptionButton.displayName = 'OptionButton';
OptionCard.displayName = 'OptionCard';
OptionPill.displayName = 'OptionPill';

export { Option, OptionButton, OptionCard, OptionPill };

import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { COLOR, Link } from '@package';

import type { Level } from '@package';

export const LinkChapter = () => {
  const { boolean, event, number, object, select, text } = useStoryteller();

  return (
    <Link
      heading={boolean('heading', false)}
      action={boolean('action', false)}
      detail={boolean('detail', true)}
      level={number('level', 1) as Level}
      color={select('color', COLOR.CONTENT, { options: Object.values(COLOR) }) as COLOR}
      href={text('href', 'https://lookiero.es')}
      // @ts-expect-error Just for better UX in Storyteller
      target={select('target', '_blank', {
        options: ['_self', '_blank', '_parent', '_top'],
        hint: 'Use it together with href',
      })}
      style={object('style', {})}
      underlined={boolean('underlined', true)}
      onPress={({ href }) => event('onPress', [{ href }])}
    >
      {text('children', 'lookiero.es')}
    </Link>
  );
};

LinkChapter.displayName = '⚛️ Atoms/Link';

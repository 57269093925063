import StyleSheet from 'react-native-extended-stylesheet';

import {
  COLOR_STYLESHEETS,
  DISPLAY_STYLESHEETS,
  FLEXBOX_STYLESHEETS,
  LAYER_STYLESHEETS,
  LAYOUT_STYLESHEETS,
  POSITION_STYLESHEETS,
  SPACING_STYLESHEETS,
  TEXT_STYLESHEETS,
} from './stylesheets/index';

export default StyleSheet.create({
  ...COLOR_STYLESHEETS,
  ...DISPLAY_STYLESHEETS,
  ...FLEXBOX_STYLESHEETS,
  ...LAYER_STYLESHEETS,
  ...LAYOUT_STYLESHEETS,
  ...POSITION_STYLESHEETS,
  ...SPACING_STYLESHEETS,
  ...TEXT_STYLESHEETS,
});

import React, { forwardRef, useCallback, useEffect, useState } from 'react';

import { Overlay } from '@atoms';
import { ALIGN, COLOR, POINTER, POSITION, SIZE, styles, useBack, useBanStylerProps, useDevice, useSwipe } from '@hooks';
import { MOTION_TIMING, MOTION_TYPES, Motion, SafeAreaView, VIEW_ROLES, View } from '@primitives';

import { style } from './Panel.style';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { MotionProperties } from '../../primitives/Motion/Motion';
import type { FC } from 'react';

const DELAY_MS = 200;
export interface PanelProps {
  align?: ALIGN;
  color?: COLOR;
  hasOverlay?: boolean;
  isVisible?: boolean;
  onClose: () => void;
  onHardwareBackPress?: () => void;
}
export type PanelProperties = PanelProps & StylerProperties & MotionProperties;

export const Panel: FC<PanelProperties> = forwardRef(
  (
    {
      children,
      color = COLOR.BG_BASE,
      hasOverlay = false,
      isVisible = false,
      align = ALIGN.LEFT,
      onClose,
      onHardwareBackPress,
      ...others
    },
    ref,
  ) => {
    const { screen } = useDevice();
    const handleSwipe = useSwipe({
      // @ts-expect-error We should review this
      onSwiping: ({ deltaX, left, right }: { deltaX: string; left: boolean; right: boolean }) => {
        if ((!left && align === ALIGN.LEFT) || (!right && align === ALIGN.RIGHT)) return;
        setLeft(parseInt(deltaX, 10));
        setSwiping(true);
      },
      // @ts-expect-error We should review this
      onSwiped: ({ deltaX, left, right }: { deltaX: number; left: boolean; right: boolean }) => {
        if ((!left && align === ALIGN.LEFT) || (!right && align === ALIGN.RIGHT)) return;

        setLeft(0);
        if (onClose && Math.abs(deltaX) >= screen.width / 3) onClose();
        else setTimeout(() => setSwiping(false), DELAY_MS);
      },
    });

    useBack(
      useCallback(() => {
        if (isVisible) {
          if (onHardwareBackPress) onHardwareBackPress();
          else if (onClose) onClose();
        }

        return isVisible;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isVisible, onClose]),
    );

    const [left, setLeft] = useState(0);
    const [swiping, setSwiping] = useState(false);

    useEffect(() => {
      setSwiping(false);
      if (isVisible) setLeft(0);
    }, [isVisible]);

    return (
      <View
        {...(!isVisible
          ? {
              accessibilityElementsHidden: true,
              pointer: POINTER.NONE,
              importantForAccessibility: 'no-hide-descendants',
            }
          : undefined)}
        layer={SIZE.L}
        position={POSITION.FIXED}
        style={styles(
          style.panel,
          align === ALIGN.RIGHT && style.panelRight,
          hasOverlay ? (align === ALIGN.RIGHT ? style.overlayRight : style.overlay) : undefined,
        )}
        role={VIEW_ROLES.aside}
        wide
      >
        {hasOverlay && <Overlay isVisible={isVisible} onPress={onClose} />}

        <Motion
          {...useBanStylerProps(others)}
          backgroundColor={color}
          disabled={left !== 0}
          layer={SIZE.L}
          position={POSITION.RELATIVE}
          // @ts-expect-error We should review this
          ref={ref}
          role={VIEW_ROLES.section}
          style={[style.motion, others.style]}
          timing={screen.S && swiping && isVisible ? MOTION_TIMING.SPRING : undefined}
          type={isVisible ? MOTION_TYPES.EXPAND : MOTION_TYPES.COLLAPSE}
          value={{ translateX: isVisible ? left || '0%' : `${align === ALIGN.LEFT ? '-' : ''}100%` }}
        >
          {screen.S && (
            <View
              {...(isVisible ? handleSwipe : undefined)}
              layer={SIZE.XS}
              position={POSITION.ABSOLUTE}
              style={styles(style.swiper, align === ALIGN.RIGHT && style.swiperRight)}
            />
          )}
          <SafeAreaView style={style.safeAreaView}>{children}</SafeAreaView>
        </Motion>
      </View>
    );
  },
);

Panel.displayName = 'Panel';

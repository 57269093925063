// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useLayoutEffect, useMemo, useState } from 'react';
import { Image as BaseImage, ImageBackground } from 'react-native';

import { testID } from '@helpers';
import { styles, useBanProps, useBanStylerProps, useDevice, useStyler } from '@hooks';

import { getUri } from './helpers';
import { BANNED_PROPS, IMAGE_RESIZE_MODES } from './Image.definition';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { FC } from 'react';
import type { ImageProps as RNImageProps } from 'react-native';

export interface ImageProps {
  alt?: string;
  autoSize?: boolean;
  src?: string | number;
  srcSet?: string;
}

export type ImageProperties = ImageProps & RNImageProps & StylerProperties;

const Image: FC<ImageProperties> = ({
  alt,
  autoSize = false,
  resizeMode = IMAGE_RESIZE_MODES.contain,
  src,
  srcSet,
  children,
  ...others
}) => {
  const Container = children ? ImageBackground : BaseImage;
  const { uri, sizeUri } = useMemo(() => getUri(src, srcSet), [src, srcSet]);

  const [size, setSize] = useState({ height: 0, width: 0 });

  useLayoutEffect(() => {
    if (autoSize) {
      BaseImage.getSize(sizeUri, (width, height) => height && width && setSize({ height, width }));
    }
  }, [autoSize, others.style, sizeUri]);

  const { height, width } = size;

  return (
    <Container
      {...useBanProps(useBanStylerProps(others), BANNED_PROPS)}
      {...useStyler(
        {
          ...others,
          style: styles(
            autoSize && height && width ? { height, width, aspectRatio: width / height } : undefined,
            others.style,
          ),
        },
        Image.displayName,
        useDevice(),
      )}
      {...testID(others.testID)}
      resizeMode={resizeMode}
      accessibilityLabel={alt || others.accessibilityLabel}
      source={uri ? { uri } : src}
    >
      {children}
    </Container>
  );
};

Image.displayName = 'Image';

export { Image };

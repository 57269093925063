import React, { forwardRef, useState } from 'react';

import { getInputErrors } from '@helpers';
import { ALIGN, COLOR, FLEX_DIRECTION, styles } from '@hooks';
import { View } from '@primitives';

import { Field } from './Field';
import { style } from './Fieldset.style';

import type { InputError } from '../../../../../helpers/getInputErrors';
import type { StylerProperties } from '../../../../../hooks/useStyler/styler.definition';
import type { InputValue } from '../../../../primitives/Input/Input.definition';
import type { INPUT_TYPES } from '@primitives';
import type { FC } from 'react';

export type Fields = Record<string, INPUT_TYPES>;

export interface FieldSetProps {
  fields: Fields;
  defaultValue?: Record<keyof Fields, InputValue>;
  disabled?: boolean;
  error?: boolean;
  focus?: boolean;
  id?: string;
  value?: Record<keyof Fields, InputValue>;

  onChange?: (value: Record<keyof Fields, InputValue>) => void;

  onError?: (error?: InputError) => void;
}

export type FieldSetProperties = FieldSetProps & StylerProperties;

const FieldSet: FC<FieldSetProperties> = forwardRef(
  ({ defaultValue = {}, fields, id, onChange, onError, value = {}, error, disabled, focus, ...others }, ref) => {
    const [currentValue, setCurrentValue] = useState({ ...defaultValue, ...value });

    const handleChange = (field: keyof Fields, newValue: InputValue): void => {
      setCurrentValue(() => {
        const nextValue: Record<keyof Fields, InputValue> = { ...currentValue, [field]: newValue };
        if (onError) {
          const errors = getInputErrors({ ...others, value: nextValue });
          onError(errors);
        }

        if (onChange) onChange(nextValue);

        return nextValue;
      });
    };

    const fieldKeys = Object.keys(fields);

    return (
      <View alignItems={ALIGN.CENTER} flexDirection={FLEX_DIRECTION.ROW} style={styles(others.style)}>
        {fieldKeys.map((field, index) => (
          <>
            <Field
              {...others}
              accessibilityLabel={field}
              key={field}
              defaultValue={defaultValue[field] ? `${defaultValue[field]}` : undefined}
              id={`${id}-${field}`}
              label={field}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ref={index === 0 ? ref : undefined}
              role="spinbutton"
              style={style.field}
              testID={others.testID ? `${others.testID}-${field}` : undefined}
              type={fields[field]}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              value={value[field]}
              onChange={(newValue: InputValue): void => handleChange(field, newValue)}
            />
            {index !== fieldKeys.length - 1 && (
              <View
                backgroundColor={
                  error
                    ? COLOR.BORDER_INPUT_ERROR
                    : disabled
                      ? COLOR.BORDER_INPUT
                      : focus
                        ? COLOR.BORDER_INPUT_FOCUS
                        : COLOR.BORDER_INPUT
                }
                style={style.separator}
              />
            )}
          </>
        ))}
      </View>
    );
  },
);

FieldSet.displayName = 'InputFieldFieldSet';

export { FieldSet };

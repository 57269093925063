import React from 'react';

import { testID as getTestId } from '@helpers';
import { ALIGN, COLOR, FLEX_DIRECTION } from '@hooks';
import { Text, Touchable, View } from '@primitives';

import { getCaption } from './helpers';
import { style } from './Rating.style';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { ViewProperties } from '../../primitives/View/View';
import type { FC } from 'react';

export interface RatingProps {
  max?: number;
  maxCaption?: string;
  min?: number;
  minCaption?: string;

  onChange?: (value: number) => void;
  title?: string;
  testID?: string;
  value?: number;
}

export type RatingProperties = RatingProps & StylerProperties & ViewProperties;

const Rating: FC<RatingProperties> = ({
  max = 5,
  maxCaption,
  min = 1,
  minCaption,
  title,
  value,
  onChange,
  testID,
  ...others
}) => {
  const ratingValues = Array.from(Array(max), (item, index) => index + min);
  const small = max > 5;

  return (
    <View {...others} testID={testID}>
      {title && <Text style={style.title}>{title}</Text>}

      <View flexDirection={FLEX_DIRECTION.ROW} justifyContent={ALIGN.BETWEEN}>
        {ratingValues.map((ratingValue) => {
          const active = value === ratingValue;

          return (
            <View
              flexDirection={FLEX_DIRECTION.COLUMN}
              justifyContent={ALIGN.CENTER}
              alignItems={ALIGN.CENTER}
              key={ratingValue}
            >
              {small && (
                <Text align={ALIGN.CENTER} color={COLOR.CONTENT} detail style={[style.value, style.valueSmall]} wide>
                  {ratingValue}
                </Text>
              )}
              <Touchable
                alignItems={ALIGN.CENTER}
                backgroundColor={active ? COLOR.BG_INTERACTIVE_SELECTED : COLOR.BG_INTERACTIVE_ACTIVE}
                justifyContent={ALIGN.CENTER}
                key={ratingValue}
                style={[small ? style.itemSmall : style.item]}
                onPress={onChange ? () => onChange(ratingValue) : undefined}
                {...getTestId(testID, ratingValue.toString())}
              >
                {!small && (
                  <Text align={ALIGN.CENTER} color={COLOR.TEXT} detail level={1} style={style.value} wide>
                    {ratingValue}
                  </Text>
                )}
              </Touchable>
            </View>
          );
        })}
      </View>

      {minCaption && maxCaption && (
        <View flexDirection={FLEX_DIRECTION.ROW} justifyContent={ALIGN.BETWEEN} style={style.captionContainer}>
          <Text detail color={COLOR.TEXT_MEDIUM}>
            {getCaption({ num: min, text: minCaption })}
          </Text>
          <Text detail color={COLOR.TEXT_MEDIUM}>
            {getCaption({ num: max, text: maxCaption })}
          </Text>
        </View>
      )}
    </View>
  );
};

Rating.displayName = 'Rating';

export { Rating };

import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { COLOR, SPACE, Theme, TitleIcon } from '@package';

import type { Glyphs } from '@lookiero/aurora-iconfont';

export const TitleIconChapter = () => {
  const { select, text } = useStoryteller();
  const { iconGlyphs } = Theme.get();

  return (
    <TitleIcon
      color={select('color', COLOR.TEXT, { options: Object.values(COLOR) }) as COLOR}
      text={text('text', 'this is a title')}
      icon={select('name', 'info', { options: Object.keys(iconGlyphs) }) as keyof Glyphs}
      padding={SPACE.SPACE_2}
    />
  );
};

TitleIconChapter.displayName = '⚛️ Atoms/TitleIcon';

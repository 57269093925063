import { useStoryteller } from '@lookiero/storyteller';
import React from 'react';

import { COLOR, Divider } from '@package';

export const DividerChapter = () => {
  const { object, select } = useStoryteller();

  return (
    <Divider
      // @ts-expect-error Just for better UX in Storyteller
      color={select('color', COLOR.CONTENT, { options: Object.values(COLOR) })}
      style={object('style', {})}
    />
  );
};

DividerChapter.displayName = '⚛️ Atoms/Divider';

import React, { forwardRef, useState } from 'react';

import { POSITION } from '@hooks';
import { INPUT_TYPES, Input } from '@primitives';

import { ButtonIcon } from '../../../ButtonIcon/ButtonIcon';
import { style } from '../../InputField.style';

import type { COLOR, StylerProperties } from '../../../../../hooks/useStyler/styler.definition';
import type { InputProperties } from '../../../../primitives/Input/Input';
import type { FC, ForwardedRef } from 'react';
import type { TextInput } from 'react-native';

export interface PasswordProps {
  color?: COLOR;
  disabled?: boolean;
  prefix?: string;
}

export type PasswordProperties = PasswordProps & StylerProperties & InputProperties;

const Password: FC<PasswordProperties> = forwardRef(({ color, ...others }, ref: ForwardedRef<TextInput>) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Input {...others} autoComplete="off" type={!visible ? INPUT_TYPES.password : INPUT_TYPES.text} ref={ref} />
      <ButtonIcon
        aria-hidden="true"
        tabIndex={-1}
        color={color}
        disabled={others.disabled}
        name={visible ? 'eye_open' : 'eye_close'}
        position={POSITION.ABSOLUTE}
        small
        style={style.icon}
        onPress={() => setVisible(!visible)}
      />
    </>
  );
});

Password.displayName = 'InputFieldPassword';

export { Password };

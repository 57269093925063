export const SPACING_STYLESHEETS = {
  // New tokens Spacing

  // padding variations
  paddingSpace025: {
    padding: '$space025',
  },

  paddingSpace05: {
    padding: '$space05',
  },

  paddingSpace1: {
    padding: '$space1',
  },

  paddingSpace2: {
    padding: '$space2',
  },

  paddingSpace3: {
    padding: '$space3',
  },

  paddingSpace4: {
    padding: '$space4',
  },

  paddingSpace5: {
    padding: '$space5',
  },

  paddingSpace6: {
    padding: '$space6',
  },

  paddingSpace8: {
    padding: '$space8',
  },

  paddingSpace10: {
    padding: '$space10',
  },

  paddingSpace12: {
    padding: '$space12',
  },

  paddingSpace16: {
    padding: '$space16',
  },

  paddingSpace20: {
    padding: '$space20',
  },

  paddingSpace24: {
    padding: '$space24',
  },

  paddingSpace28: {
    padding: '$space28',
  },

  paddingSpace32: {
    padding: '$space32',
  },

  // paddingTop variations
  paddingTopSpace025: {
    paddingTop: '$space025',
  },

  paddingTopSpace05: {
    paddingTop: '$space05',
  },

  paddingTopSpace1: {
    paddingTop: '$space1',
  },

  paddingTopSpace2: {
    paddingTop: '$space2',
  },

  paddingTopSpace3: {
    paddingTop: '$space3',
  },

  paddingTopSpace4: {
    paddingTop: '$space4',
  },

  paddingTopSpace5: {
    paddingTop: '$space5',
  },

  paddingTopSpace6: {
    paddingTop: '$space6',
  },

  paddingTopSpace8: {
    paddingTop: '$space8',
  },

  paddingTopSpace10: {
    paddingTop: '$space10',
  },

  paddingTopSpace12: {
    paddingTop: '$space12',
  },

  paddingTopSpace16: {
    paddingTop: '$space16',
  },

  paddingTopSpace20: {
    paddingTop: '$space20',
  },

  paddingTopSpace24: {
    paddingTop: '$space24',
  },

  paddingTopSpace28: {
    paddingTop: '$space28',
  },

  paddingTopSpace32: {
    paddingTop: '$space32',
  },

  // paddingBottom variations
  paddingBottomSpace025: {
    paddingBottom: '$space025',
  },

  paddingBottomSpace05: {
    paddingBottom: '$space05',
  },

  paddingBottomSpace1: {
    paddingBottom: '$space1',
  },

  paddingBottomSpace2: {
    paddingBottom: '$space2',
  },

  paddingBottomSpace3: {
    paddingBottom: '$space3',
  },

  paddingBottomSpace4: {
    paddingBottom: '$space4',
  },

  paddingBottomSpace5: {
    paddingBottom: '$space5',
  },

  paddingBottomSpace6: {
    paddingBottom: '$space6',
  },

  paddingBottomSpace8: {
    paddingBottom: '$space8',
  },

  paddingBottomSpace10: {
    paddingBottom: '$space10',
  },

  paddingBottomSpace12: {
    paddingBottom: '$space12',
  },

  paddingBottomSpace16: {
    paddingBottom: '$space16',
  },

  paddingBottomSpace20: {
    paddingBottom: '$space20',
  },

  paddingBottomSpace24: {
    paddingBottom: '$space24',
  },

  paddingBottomSpace28: {
    paddingBottom: '$space28',
  },

  paddingBottomSpace32: {
    paddingBottom: '$space32',
  },

  // paddingLeft variations
  paddingLeftSpace025: {
    paddingLeft: '$space025',
  },

  paddingLeftSpace05: {
    paddingLeft: '$space05',
  },

  paddingLeftSpace1: {
    paddingLeft: '$space1',
  },

  paddingLeftSpace2: {
    paddingLeft: '$space2',
  },

  paddingLeftSpace3: {
    paddingLeft: '$space3',
  },

  paddingLeftSpace4: {
    paddingLeft: '$space4',
  },

  paddingLeftSpace5: {
    paddingLeft: '$space5',
  },

  paddingLeftSpace6: {
    paddingLeft: '$space6',
  },

  paddingLeftSpace8: {
    paddingLeft: '$space8',
  },

  paddingLeftSpace10: {
    paddingLeft: '$space10',
  },

  paddingLeftSpace12: {
    paddingLeft: '$space12',
  },

  paddingLeftSpace16: {
    paddingLeft: '$space16',
  },

  paddingLeftSpace20: {
    paddingLeft: '$space20',
  },

  paddingLeftSpace24: {
    paddingLeft: '$space24',
  },

  paddingLeftSpace28: {
    paddingLeft: '$space28',
  },

  paddingLeftSpace32: {
    paddingLeft: '$space32',
  },

  // paddingRight variations
  paddingRightSpace025: {
    paddingRight: '$space025',
  },

  paddingRightSpace05: {
    paddingRight: '$space05',
  },

  paddingRightSpace1: {
    paddingRight: '$space1',
  },

  paddingRightSpace2: {
    paddingRight: '$space2',
  },

  paddingRightSpace3: {
    paddingRight: '$space3',
  },

  paddingRightSpace4: {
    paddingRight: '$space4',
  },

  paddingRightSpace5: {
    paddingRight: '$space5',
  },

  paddingRightSpace6: {
    paddingRight: '$space6',
  },

  paddingRightSpace8: {
    paddingRight: '$space8',
  },

  paddingRightSpace10: {
    paddingRight: '$space10',
  },

  paddingRightSpace12: {
    paddingRight: '$space12',
  },

  paddingRightSpace16: {
    paddingRight: '$space16',
  },

  paddingRightSpace20: {
    paddingRight: '$space20',
  },

  paddingRightSpace24: {
    paddingRight: '$space24',
  },

  paddingRightSpace28: {
    paddingRight: '$space28',
  },

  paddingRightSpace32: {
    paddingRight: '$space32',
  },

  // margin variations
  marginSpace025: {
    margin: '$space025',
  },

  marginSpace05: {
    margin: '$space05',
  },

  marginSpace1: {
    margin: '$space1',
  },

  marginSpace2: {
    margin: '$space2',
  },

  marginSpace3: {
    margin: '$space3',
  },

  marginSpace4: {
    margin: '$space4',
  },

  marginSpace5: {
    margin: '$space5',
  },

  marginSpace6: {
    margin: '$space6',
  },

  marginSpace8: {
    margin: '$space8',
  },

  marginSpace10: {
    margin: '$space10',
  },

  marginSpace12: {
    margin: '$space12',
  },

  marginSpace16: {
    margin: '$space16',
  },

  marginSpace20: {
    margin: '$space20',
  },

  marginSpace24: {
    margin: '$space24',
  },

  marginSpace28: {
    margin: '$space28',
  },

  marginSpace32: {
    margin: '$space32',
  },

  // marginTop variations
  marginTopSpace025: {
    marginTop: '$space025',
  },

  marginTopSpace05: {
    marginTop: '$space05',
  },

  marginTopSpace1: {
    marginTop: '$space1',
  },

  marginTopSpace2: {
    marginTop: '$space2',
  },

  marginTopSpace3: {
    marginTop: '$space3',
  },

  marginTopSpace4: {
    marginTop: '$space4',
  },

  marginTopSpace5: {
    marginTop: '$space5',
  },

  marginTopSpace6: {
    marginTop: '$space6',
  },

  marginTopSpace8: {
    marginTop: '$space8',
  },

  marginTopSpace10: {
    marginTop: '$space10',
  },

  marginTopSpace12: {
    marginTop: '$space12',
  },

  marginTopSpace16: {
    marginTop: '$space16',
  },

  marginTopSpace20: {
    marginTop: '$space20',
  },

  marginTopSpace24: {
    marginTop: '$space24',
  },

  marginTopSpace28: {
    marginTop: '$space28',
  },

  marginTopSpace32: {
    marginTop: '$space32',
  },

  // marginBottom variations
  marginBottomSpace025: {
    marginBottom: '$space025',
  },

  marginBottomSpace05: {
    marginBottom: '$space05',
  },

  marginBottomSpace1: {
    marginBottom: '$space1',
  },

  marginBottomSpace2: {
    marginBottom: '$space2',
  },

  marginBottomSpace3: {
    marginBottom: '$space3',
  },

  marginBottomSpace4: {
    marginBottom: '$space4',
  },

  marginBottomSpace5: {
    marginBottom: '$space5',
  },

  marginBottomSpace6: {
    marginBottom: '$space6',
  },

  marginBottomSpace8: {
    marginBottom: '$space8',
  },

  marginBottomSpace10: {
    marginBottom: '$space10',
  },

  marginBottomSpace12: {
    marginBottom: '$space12',
  },

  marginBottomSpace16: {
    marginBottom: '$space16',
  },

  marginBottomSpace20: {
    marginBottom: '$space20',
  },

  marginBottomSpace24: {
    marginBottom: '$space24',
  },

  marginBottomSpace28: {
    marginBottom: '$space28',
  },

  marginBottomSpace32: {
    marginBottom: '$space32',
  },

  // marginLeft variations
  marginLeftSpace025: {
    marginLeft: '$space025',
  },

  marginLeftSpace05: {
    marginLeft: '$space05',
  },

  marginLeftSpace1: {
    marginLeft: '$space1',
  },

  marginLeftSpace2: {
    marginLeft: '$space2',
  },

  marginLeftSpace3: {
    marginLeft: '$space3',
  },

  marginLeftSpace4: {
    marginLeft: '$space4',
  },

  marginLeftSpace5: {
    marginLeft: '$space5',
  },

  marginLeftSpace6: {
    marginLeft: '$space6',
  },

  marginLeftSpace8: {
    marginLeft: '$space8',
  },

  marginLeftSpace10: {
    marginLeft: '$space10',
  },

  marginLeftSpace12: {
    marginLeft: '$space12',
  },

  marginLeftSpace16: {
    marginLeft: '$space16',
  },

  marginLeftSpace20: {
    marginLeft: '$space20',
  },

  marginLeftSpace24: {
    marginLeft: '$space24',
  },

  marginLeftSpace28: {
    marginLeft: '$space28',
  },

  marginLeftSpace32: {
    marginLeft: '$space32',
  },

  // marginRight variations
  marginRightSpace025: {
    marginRight: '$space025',
  },

  marginRightSpace05: {
    marginRight: '$space05',
  },

  marginRightSpace1: {
    marginRight: '$space1',
  },

  marginRightSpace2: {
    marginRight: '$space2',
  },

  marginRightSpace3: {
    marginRight: '$space3',
  },

  marginRightSpace4: {
    marginRight: '$space4',
  },

  marginRightSpace5: {
    marginRight: '$space5',
  },

  marginRightSpace6: {
    marginRight: '$space6',
  },

  marginRightSpace8: {
    marginRight: '$space8',
  },

  marginRightSpace10: {
    marginRight: '$space10',
  },

  marginRightSpace12: {
    marginRight: '$space12',
  },

  marginRightSpace16: {
    marginRight: '$space16',
  },

  marginRightSpace20: {
    marginRight: '$space20',
  },

  marginRightSpace24: {
    marginRight: '$space24',
  },

  marginRightSpace28: {
    marginRight: '$space28',
  },

  marginRightSpace32: {
    marginRight: '$space32',
  },

  // TODO: DEPRECATE
  marginXXS: {
    margin: '$spaceXXS',
  },

  marginXS: {
    margin: '$spaceXS',
  },

  marginS: {
    margin: '$spaceS',
  },

  marginM: {
    margin: '$spaceM',
  },

  marginL: {
    margin: '$spaceL',
  },

  marginXL: {
    margin: '$spaceXL',
  },

  marginXXL: {
    margin: '$spaceXXL',
  },

  marginXXXL: {
    margin: '$spaceXXXL',
  },

  margin0: {
    margin: 0,
  },

  marginTop0: {
    marginTop: 0,
  },

  marginRight0: {
    marginRight: 0,
  },

  marginBottom0: {
    marginBottom: 0,
  },

  marginLeft0: {
    marginLeft: 0,
  },

  marginTopXXS: {
    marginTop: '$spaceXXS',
  },

  marginRightXXS: {
    marginRight: '$spaceXXS',
  },

  marginBottomXXS: {
    marginBottom: '$spaceXXS',
  },

  marginLeftXXS: {
    marginLeft: '$spaceXXS',
  },

  marginTopXS: {
    marginTop: '$spaceXS',
  },

  marginRightXS: {
    marginRight: '$spaceXS',
  },

  marginBottomXS: {
    marginBottom: '$spaceXS',
  },

  marginLeftXS: {
    marginLeft: '$spaceXS',
  },

  marginTopS: {
    marginTop: '$spaceS',
  },

  marginRightS: {
    marginRight: '$spaceS',
  },

  marginBottomS: {
    marginBottom: '$spaceS',
  },

  marginLeftS: {
    marginLeft: '$spaceS',
  },

  marginTopM: {
    marginTop: '$spaceM',
  },

  marginRightM: {
    marginRight: '$spaceM',
  },

  marginBottomM: {
    marginBottom: '$spaceM',
  },

  marginLeftM: {
    marginLeft: '$spaceM',
  },

  marginTopL: {
    marginTop: '$spaceL',
  },

  marginRightL: {
    marginRight: '$spaceL',
  },

  marginBottomL: {
    marginBottom: '$spaceL',
  },

  marginLeftL: {
    marginLeft: '$spaceL',
  },

  marginTopXL: {
    marginTop: '$spaceXL',
  },

  marginRightXL: {
    marginRight: '$spaceXL',
  },

  marginBottomXL: {
    marginBottom: '$spaceXL',
  },

  marginLeftXL: {
    marginLeft: '$spaceXL',
  },

  marginTopXXL: {
    marginTop: '$spaceXXL',
  },

  marginRightXXL: {
    marginRight: '$spaceXXL',
  },

  marginBottomXXL: {
    marginBottom: '$spaceXXL',
  },

  marginLeftXXL: {
    marginLeft: '$spaceXXL',
  },

  marginTopXXXL: {
    marginTop: '$spaceXXXL',
  },

  marginRightXXXL: {
    marginRight: '$spaceXXXL',
  },

  marginBottomXXXL: {
    marginBottom: '$spaceXXXL',
  },

  marginLeftXXXL: {
    marginLeft: '$spaceXXXL',
  },

  paddingXXS: {
    padding: '$spaceXXS',
  },

  paddingXS: {
    padding: '$spaceXS',
  },

  paddingS: {
    padding: '$spaceS',
  },

  paddingM: {
    padding: '$spaceM',
  },

  paddingL: {
    padding: '$spaceL',
  },

  paddingXL: {
    padding: '$spaceXL',
  },

  paddingXXL: {
    padding: '$spaceXXL',
  },

  paddingXXXL: {
    padding: '$spaceXXXL',
  },

  padding0: {
    padding: 0,
  },

  paddingTop0: {
    paddingTop: 0,
  },

  paddingRight0: {
    paddingRight: 0,
  },

  paddingBottom0: {
    paddingBottom: 0,
  },

  paddingLeft0: {
    paddingLeft: 0,
  },

  paddingTopXXS: {
    paddingTop: '$spaceXXS',
  },

  paddingRightXXS: {
    paddingRight: '$spaceXXS',
  },

  paddingBottomXXS: {
    paddingBottom: '$spaceXXS',
  },

  paddingLeftXXS: {
    paddingLeft: '$spaceXXS',
  },

  paddingTopXS: {
    paddingTop: '$spaceXS',
  },

  paddingRightXS: {
    paddingRight: '$spaceXS',
  },

  paddingBottomXS: {
    paddingBottom: '$spaceXS',
  },

  paddingLeftXS: {
    paddingLeft: '$spaceXS',
  },

  paddingTopS: {
    paddingTop: '$spaceS',
  },

  paddingRightS: {
    paddingRight: '$spaceS',
  },

  paddingBottomS: {
    paddingBottom: '$spaceS',
  },

  paddingLeftS: {
    paddingLeft: '$spaceS',
  },

  paddingTopM: {
    paddingTop: '$spaceM',
  },

  paddingRightM: {
    paddingRight: '$spaceM',
  },

  paddingBottomM: {
    paddingBottom: '$spaceM',
  },

  paddingLeftM: {
    paddingLeft: '$spaceM',
  },

  paddingTopL: {
    paddingTop: '$spaceL',
  },

  paddingRightL: {
    paddingRight: '$spaceL',
  },

  paddingBottomL: {
    paddingBottom: '$spaceL',
  },

  paddingLeftL: {
    paddingLeft: '$spaceL',
  },

  paddingTopXL: {
    paddingTop: '$spaceXL',
  },

  paddingRightXL: {
    paddingRight: '$spaceXL',
  },

  paddingBottomXL: {
    paddingBottom: '$spaceXL',
  },

  paddingLeftXL: {
    paddingLeft: '$spaceXL',
  },

  paddingTopXXL: {
    paddingTop: '$spaceXXL',
  },

  paddingRightXXL: {
    paddingRight: '$spaceXXL',
  },

  paddingBottomXXL: {
    paddingBottom: '$spaceXXL',
  },

  paddingLeftXXL: {
    paddingLeft: '$spaceXXL',
  },

  paddingTopXXXL: {
    paddingTop: '$spaceXXXL',
  },

  paddingRightXXXL: {
    paddingRight: '$spaceXXXL',
  },

  paddingBottomXXXL: {
    paddingBottom: '$spaceXXXL',
  },

  paddingLeftXXXL: {
    paddingLeft: '$spaceXXXL',
  },
};

import type { InputValue } from '../../../primitives/Input/Input.definition';

export const hasValue = (value?: InputValue): boolean => {
  if (!value) return false;

  let filled = false;
  if (typeof value === 'string') filled = value !== '';
  else if (typeof value === 'number') filled = true;
  else if (typeof value === 'object') {
    filled = Object.values(value).filter((value) => value && value !== '').length > 0;
  }

  return filled;
};

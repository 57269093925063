import React from 'react';

import { COLOR, SPACE } from '@hooks';
import { Text, View } from '@primitives';

const Children = ({ ...inherit }) => (
  <View backgroundColor={COLOR.BG_SUCCESS} padding={SPACE.SPACE_4} {...inherit}>
    <Text detail>CHILDREN</Text>
  </View>
);

Children.displayName = 'Children';

export { Children };

import React, { Children, cloneElement } from 'react';

import { FLEX_DIRECTION } from '@hooks';
import { ScrollView } from '@primitives';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { ScrollPayload, ScrollViewProperties } from '../../primitives/ScrollView/ScrollView';
import type { FC, ReactElement } from 'react';

export interface SliderProps {
  selected?: number;
  snapInterval: number;
  onChange?: (horizontalScrollPosition: number) => void;
}

export type SliderProperties = SliderProps & StylerProperties & ScrollViewProperties;

const Slider: FC<SliderProperties> = ({ children, selected = 0, snapInterval, onChange, ...others }) => {
  const handleScroll = ({ X }: ScrollPayload) => {
    const next = typeof X !== 'undefined' && X < snapInterval ? 0 : Math.round(X / snapInterval);

    if (onChange && next !== selected) onChange(next);
  };

  return (
    <ScrollView
      {...others}
      animated
      flexDirection={FLEX_DIRECTION.ROW}
      horizontal
      scrollTo={selected * snapInterval}
      snapInterval={snapInterval}
      onScroll={handleScroll}
    >
      {Children.map(children, (child, index) => {
        return cloneElement(child as ReactElement, { key: index });
      })}
    </ScrollView>
  );
};

Slider.displayName = 'Slider';

export { Slider };

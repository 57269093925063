// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import { Pressable } from 'react-native';

import { testID } from '@helpers';
import { DISPLAY, useBanProps, useBanStylerProps, useDevice, useStyler } from '@hooks';

import { pickDocument } from './helpers';
import { BANNED_PROPS } from './InputFile.definition';
import { InputFileModal } from './InputFileModal';

import type { PhotoResult } from './helpers/pickImage';
import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { FC, RefObject } from 'react';
import type { PressableProps } from 'react-native';

export interface RefProperties {
  value: string;
  press: () => void;
}

export interface InputFileProps {
  accept?: string;
  forwardedRef?: RefObject<RefProperties>;
  hidden?: boolean;
  multiple?: boolean;

  onChange?: (file: File) => void;
  onError?: () => void;
  sourcesMessages?: {
    chooseFromLibrary: string;
    takePhoto: string;
    title: string;
  };
}

export type InputFileProperties = InputFileProps & StylerProperties & PressableProps;

const InputFile: FC<InputFileProperties> = ({
  accept,
  children,
  disabled,
  forwardedRef,
  hidden = false,
  multiple = false,
  onChange,
  sourcesMessages,
  ...others
}) => {
  const { platform } = useDevice();
  const [modal, setModal] = useState(false);

  const handlePress = useCallback(async () => {
    if (platform.web) {
      const doc = await pickDocument(accept, multiple);

      if (doc?.file && onChange) onChange(doc.file);
    } else {
      setModal(true);
    }
  }, [accept, multiple, onChange, platform.web]);

  const onFileSelect = (file: PhotoResult) => {
    setModal(false);
    if (onChange) onChange(file);
  };

  useEffect(() => {
    if (forwardedRef) {
      forwardedRef.current = { ...forwardedRef.current, press: handlePress };
    }
  }, [forwardedRef, handlePress]);

  return (
    <>
      <Pressable
        {...useBanProps(useBanStylerProps(others), BANNED_PROPS)}
        {...useStyler(
          { ...others, display: hidden ? DISPLAY.NONE : others.display },
          InputFile.displayName,
          useDevice(),
        )}
        {...testID(others.testID)}
        onPress={!disabled && onChange ? handlePress : undefined}
      >
        {children}
      </Pressable>
      {modal ? (
        <InputFileModal onFileSelect={onFileSelect} onClose={() => setModal(false)} sourcesMessages={sourcesMessages} />
      ) : (
        <></>
      )}
    </>
  );
};

InputFile.displayName = 'InputFile';

export { InputFile };

import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  container: {
    left: 0,
    top: 0,
    width: '100%',
  },

  delta: {
    top: '$space6',
  },

  direction: {
    bottom: '$space6',
  },

  motion: {
    height: 128,
    width: 128,
  },
});

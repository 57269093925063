import React from 'react';
import { Platform, Pressable } from 'react-native';

import { testID } from '@helpers';
import {
  // Helpers
  ALIGN,
  POINTER,
  // Hooks
  styles,
  useBanStylerProps,
  useDevice,
  useKeyboard,
  useStyler,
} from '@hooks';

import { PRESSABLE_OPACITY, ROLES } from './Touchable.definition';
import { style } from './Touchable.style';

import type { StylerProperties } from '../../../hooks/useStyler/styler.definition';
import type { FC } from 'react';
import type { AccessibilityRole, GestureResponderEvent, PressableProps } from 'react-native';

export type Target = '_self' | '_blank' | '_parent' | '_top';
export interface HrefAttributes {
  target?: Target;
}

export interface TouchableProps {
  href?: string;
  pressableOpacity?: number;
  hrefAttrs?: HrefAttributes;
  role?: ROLES | AccessibilityRole;
  tabIndex?: number;
}
export type TouchableProperties = TouchableProps & StylerProperties & PressableProps;

const Touchable: FC<TouchableProperties> = ({
  children,
  role = ROLES.button,
  onPress,
  pressableOpacity = PRESSABLE_OPACITY.DEFAULT,
  ...others
}) => {
  const { isKeyboardOpen, closeKeyboard } = useKeyboard();
  const handlePress = (event: GestureResponderEvent) => {
    Platform.OS !== 'web' && isKeyboardOpen && closeKeyboard();
    onPress && onPress(event);
  };

  const { style: styler } = {
    ...useStyler(
      {
        ...others,
        alignSelf: others.alignSelf || ALIGN.START,
        style: styles(style, style.container, others.style),
      },
      Touchable.displayName,
      useDevice(),
    ),
  };

  return (
    <Pressable
      {...useBanStylerProps(others)}
      {...testID(others.testID)}
      accessibilityRole={role}
      onPress={handlePress}
      pointerEvents={onPress ? others.pointerEvents : POINTER.NONE}
      style={({ pressed }) => [styler, { opacity: pressed ? pressableOpacity : 1 }]}
    >
      {children}
    </Pressable>
  );
};

Touchable.displayName = 'Touchable';

export { Touchable };

import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  layout: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal: 'auto',
    maxWidth: '$layoutMaxWidth',
  },

  column: {
    flexDirection: 'column',
  },

  row: {
    flexDirection: 'row',
  },

  fullWidth: {
    maxWidth: '100%',
  },

  reverse: {
    flexDirection: 'row-reverse',
  },

  reverseColumn: {
    flexDirection: 'column-reverse',
  },
});

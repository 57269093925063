import { useState } from 'react';

import { getTime } from '../../helpers';

const { NODE_ENV } = process.env;
const UUID_SCAFFOLD = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

const UUID = (): string => {
  let timestamp = getTime();

  return NODE_ENV !== 'test'
    ? UUID_SCAFFOLD.replace(/[xy]/g, (char) => {
        const random = (timestamp + Math.random() * 16) % 16 | 0;
        timestamp = Math.floor(timestamp / 16);

        return (char === 'x' ? random : (random & 0x3) | 0x8).toString(16);
      })
    : UUID_SCAFFOLD;
};

const useId = (id?: string): string => {
  const [uuid] = useState(id || UUID());

  return uuid;
};

export { useId };

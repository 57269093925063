import React, { useEffect, useState } from 'react';

import { ALIGN, Aurora, Box, Button, COLOR, DefaultTheme, SPACE, Text, Theme, View } from '@package';

import { AuroraDarkTheme } from './theme.AuroraDark';
import { style } from './Theme.chapter.style';
import { getTime } from '../../src/helpers';

import type { ThemeDefinition } from '../../src/theming/Theme.definition';

export const ThemeChapter = () => {
  const [theme, setTheme] = useState<ThemeDefinition>();
  const [timestamp, setTimestamp] = useState<number[]>([]);

  useEffect(() => {
    setTimestamp([...timestamp, getTime()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  const handleToggle = () => {
    setTheme(Theme.get().theme === 'aurora' ? AuroraDarkTheme : DefaultTheme);
  };

  return (
    <Aurora theme={theme}>
      <View padding={SPACE.SPACE_4}>
        <Text heading>Styler properties</Text>
        <Box padding={SPACE.SPACE_4} backgroundColor={COLOR.BG_ERROR}>
          <Text>BG_ERROR</Text>
        </Box>

        <Box padding={SPACE.SPACE_4} backgroundColor={COLOR.BG_ERROR_LIGHT}>
          <Text>BG_ERROR_LIGHT</Text>
        </Box>

        <Text color={COLOR.BG_ERROR}>BG_ERROR</Text>
        <Text color={COLOR.BG_ERROR_LIGHT}>BG_ERROR_LIGHT</Text>
      </View>

      <View padding={SPACE.SPACE_4}>
        <Text heading>Stylesheets</Text>
        <Box padding={SPACE.SPACE_4} style={style.backgroundError}>
          <Text>BG_ERROR</Text>
        </Box>
        <Box padding={SPACE.SPACE_4} style={style.backgroundErrorLight}>
          <Text>BG_ERROR_LIGHT</Text>
        </Box>
        <Text style={style.error}>BG_ERROR</Text>

        <Text style={style.errorLight}>BG_ERROR_LIGHT</Text>
      </View>

      <View padding={SPACE.SPACE_4}>
        <Button onPress={handleToggle}>Toggle Theme</Button>
        <Text align={ALIGN.CENTER} detail marginTop={SPACE.SPACE_4}>{`Renders ${timestamp.length} times.`}</Text>
      </View>
    </Aurora>
  );
};

ThemeChapter.displayName = '🪝 Theming/Theme';
